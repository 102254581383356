import React, { useState } from 'react'
import Header from '../../../components/header';
import PayrollHistoryTable from '../../../Tables/PayrollTables/payrollhistory';
import ApprovedPayslipsTable from '../../../Tables/PayrollTables/approvedpayslips';
import {useNavigate} from 'react-router-dom'
import ApprovedAllowanceTable from '../../../Tables/PayrollTables/approvedallowance';

const PayrollPage = () => {
    const [tab, setTab] = useState('payslip_history')
    //navigate to individual employees
    const navigate = useNavigate()
    
    const handleNavigateToPayslipCalculator = (id) => {
        navigate(`/payroll/payslip-calculator`)
    }
    return ( 
        <>
        <Header 
        text='Payroll'
        tabs={
            <div className="flex gap-[16px] mt-[16px]">
                <p
                    className={
                        tab === 'payslip_history'
                            ? 'font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer'
                            : 'font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer'
                    }
                    onClick={() => setTab('payslip_history')}
                >
                    Payroll History
                </p>
                <p
                    className={
                        tab === 'approved_payslips'
                            ? 'font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer'
                            : 'font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer'
                    }
                    onClick={() => setTab('approved_payslips')}
                >
                    Approved Payslips
                </p>
                <p
                    className={
                        tab === 'approved_allowance'
                            ? 'font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer'
                            : 'font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer'
                    }
                    onClick={() => setTab('approved_allowance')}
                >
                    Approved Allowance
                </p>
            </div>
        }
        > 
        <div className="">
            <button
                type="submit"
                disabled="" //hover color is #24752B
                className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                onClick={handleNavigateToPayslipCalculator}
                >
                   Create New Payslip                 
            </button>
        </div>

        </Header>
        { tab === 'payslip_history' && <PayrollHistoryTable/>}
        {tab === 'approved_payslips' && <ApprovedPayslipsTable/>}
        {tab === 'approved_allowance' && <ApprovedAllowanceTable/>}
        
        </>
     );
}
 
export default PayrollPage;