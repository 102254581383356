import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import { KeyboardArrowDown} from "@mui/icons-material";
import ChecklistIcon from "../../../assets/checklist_icon.svg";
import ColouredCheckListIcon from "../../../assets/Vector.svg"
import ModalComponent from "../../../components/Modals/modal";
import {useSelector, useDispatch} from 'react-redux'
import { editPayslip, createPayslip } from "../../../redux/Calculator/calculatorAction";
import cogoToast from "cogo-toast"
import moment from "moment"
import GoBack from "../../../components/goBack";

const EditAllowancePage = () => {
   //redux state management
   const dispatch = useDispatch()
   const { allowanceEdit, edit_allowance_success } = useSelector(state => state.payslipCalculator)
    // console.log(allowanceEdit)
    const [payrollDate, setPayrollDate] = useState(allowanceEdit.date_of_entry? moment(allowanceEdit.date_of_entry).format('YYYY-MM-DD'): new Date())
    const [selectEmployee, setSelectedEmployee] = useState([`${allowanceEdit.employee.first_name} ${allowanceEdit.employee.last_name}`])
    const [allowance, setAllowance] = useState(allowanceEdit.allowance_payable
        ? allowanceEdit.allowance_payable
        : 0); 
    const [employee] = useState([allowanceEdit.employee.id])

    const ref = allowanceEdit.payroll_reference
    // console.log(ref)

    //modal to generate allowance
    const [generateAllowance, setGenerateAllowance] = useState(false)

    const toggleGenerateAllowanceModal = () => {
        setGenerateAllowance(!generateAllowance)
    }

    const handleCreateAllowance  = () => {
        if(allowance > 0 ) {
            let response = {
                generated: true,
                employees: employee,
                date_of_entry: payrollDate,
                allowance: {
                  allowance_payable: Number(parseFloat(allowance).toFixed(2)),
                },
              }
              // console.log(response)
              dispatch(editPayslip({response, ref}));
        } else {
            cogoToast.error(
              `Cannot generate payslip, please confirm that the date of payroll, allowance and employees field are filled!`
            );
          }
          setGenerateAllowance(false)
    }

    //reset all the values after successfully editing an allowance
    useEffect(() => {
        if(edit_allowance_success) {
            setPayrollDate(new Date())
            setSelectedEmployee("")
            setAllowance(0)
        }
    }, [edit_allowance_success])

    //functionality to save allowance
    const [saveAllowance, setSaveAllowance] = useState(false)

   const toggleSaveAllowanceModal = () => {
    setSaveAllowance(!saveAllowance)
   }

   const handleSaveAllowance  = () => {
    if(allowance > 0 ) {
        let response = {
            generated: false,
            employees: employee,
            date_of_entry: payrollDate,
            allowance: {
              allowance_payable: Number(parseFloat(allowance).toFixed(2)),
            },
          }
          // console.log(response)
          dispatch(createPayslip(response));
    } else {
        cogoToast.error(
          `Cannot generate allowance, please confirm that the date of payroll, allowance and employees field are filled!`
        );
      }
      setGenerateAllowance(false)
}

//toggle section of the calculator
const [allow, setAllow] = useState(false)


   return (
    <>
      <ModalComponent
        title={`Generate Allowance`}
        show={generateAllowance}
        showModal={() => setGenerateAllowance(false)}
        subtitle={`Are you sure you want to generate an allowance for ${selectEmployee}`}
      >
        <div className="">
          <p className="text-[#96A397] text-xs font-medium mt">Please review the details below and click on proceed</p>
          <div classsName="mt-1 flex-col justify-start items-start gap-2 inline-flex">
            <div className="mt-2 items-center justify-between flex">
              <p
                className={
                  payrollDate === ""
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Payroll Date
              </p>
              <img
                src={
                  payrollDate !== ""
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  selectEmployee.length === 1
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Employee Name
              </p>
              <img
                src={
                  selectEmployee.length === 1
                    ? ChecklistIcon
                    : ColouredCheckListIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Allowance
              </p>
              <img
                src={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
          </div>
        </div>
        {/* Generate Allowance Modal */}
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <button
            onClick={() => setGenerateAllowance(false)}
            className="text-sm text-medium leading-5 text-[#5C715E]"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateAllowance}
            className="outline-0 bg-primary px-5 py-2.5 rounded-[8px] h-[40px] text-sm text-medium leading-5 text-white"
          >
            Proceed
          </button>
        </div>
      </ModalComponent>
              {/* Modal to confirm creation of draft allowance */}
              <ModalComponent
        title={`Save Allowance`}
        show={saveAllowance}
        showModal={() => setSaveAllowance(false)}
        subtitle={`Are you sure you sure you want to create a draft allowance for ${selectEmployee}?`
        }
      >
         <div className="">
          <p className="text-[#96A397] text-xs font-medium mt">Please review the details below and click on save for later</p>
          <div classsName="mt-1 flex-col justify-start items-start gap-2 inline-flex">
            <div className="mt-2 items-center justify-between flex">
              <p
                className={
                  payrollDate === ""
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Payroll Date
              </p>
              <img
                src={
                  payrollDate !== ""
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  selectEmployee.length === 1
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Employee Name
              </p>
              <img
                src={
                  selectEmployee.length === 1
                    ? ChecklistIcon
                    : ColouredCheckListIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Allowance
              </p>
              <img
                src={
                  allowance > 0 && allowance !== 0 && allowance !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
          </div>
        </div>
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <button
            className="text-sm text-medium leading-5 text-[#5C715E]"
            onClick={() => setSaveAllowance(false)}
          >
            Cancel
          </button>
          <button
            onClick={handleSaveAllowance}
            disabled={
              selectEmployee.length === 1 &&
                allowance === 0 &&
                payrollDate === ""
                ? true
                : false
            }
            className="outline-0 bg-primary rounded-[8px] px-5 py-2.5 h-[40px] text-sm text-medium leading-5 text-white"
          >
            Save for later
          </button>
        </div>
      </ModalComponent>
      <GoBack/>
      <Header 
      text= "Edit Allowance"
      >
      </Header>

      <div className="rounded-lg mt-4">
        <div className="flex gap-6 w-full">
            <div className="w-[68%]">
            <div className="bg-white rounded-lg px-5 py-[27px]">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Payroll Period
                    </p>
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <p>Payroll Date</p>
                    <input
                      type="date"
                      required
                      disabled={true}
                      name="payrollDate"
                      placeholder="mm/dd/yyyy - mm/dd/yyyy"
                      value={payrollDate}
                      onChange={(e) => setPayrollDate(e.target.value)}
                      className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    />
                  </div>
                </div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Employee Name
                    </p>
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Employee
                    </label>
                    <input
                      type="select"
                      required
                      disabled={true}
                      value={selectEmployee} 
                      name="selectEmployee"
                      placeholder="Select Employee"
                      className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    /> 
                  </div>
                </div>
                <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Allowance
                    </p>
                    <KeyboardArrowDown
                      onClick={()=> setAllow(!allow)}
                      style={{ color: "#5C715E", fontSize: "14px" }}
                    />
                  </div>
                </div>
                {!allow && <div className="bg-white rounded-lg px-5 py-[27px] mt-4">
                  <div className="flex justify-between">
                    <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                      Allowance
                    </p>
                  </div>
                  <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                    <p>Allowance Payable</p>
                    <input
                      type="text"
                      required={true}
                      name="allowancePayable"
                      value={allowance}
                      onChange={(e) => setAllowance(e.target.value)}
                      placeholder="Allowance"
                      className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                    />
                  </div>
                </div> }

                <div className="flex justify-end mt-6">             
                  <div className="flex gap-6">
                  <button
                      onClick={toggleSaveAllowanceModal}
                      className="outline-none bg-transparent text-secondary1 text-sm font-medium py-[10px] px-5"
                    >
                      Save for later
                    </button>
                    <button
                      className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-[10px] px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
                      onClick={toggleGenerateAllowanceModal}
                    >
                      Generate Allowance
                    </button>
                  </div>
                </div>
            </div>
            {/* the checklist side menu */}

            <div className="w-[32%] rounded-lg">
                <div className="bg-white rounded-lg py-4 px-4">
                  <h6 className="text-tertiary text-sm font-semibold">
                    Current Setting
                  </h6>
                  <p className="text-[#5C715E] text-xs">
                    To see your payslip estimates, enter the following settings:
                  </p>
                  <div className="mt-5 flex gap-2 items-center">
                    <p
                      className={
                        payrollDate === ""
                          ? "text-[#ABB6AC] text-sm font-medium"
                          : "text-secondary text-sm font-medium"
                      }
                    >
                      Payroll Date
                    </p>
                    <img
                      src={
                        payrollDate !== ""
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        selectEmployee
                          ? "text-[#ABB6AC] text-sm font-medium"
                          : "text-secondary text-sm font-medium"
                      }
                    >
                      Employee Name
                    </p>
                    <img
                      src={
                        selectEmployee
                          ? ChecklistIcon
                          : ColouredCheckListIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                  <div className="mt-2 flex gap-2 items-center">
                    <p
                      className={
                        allowance > 0 && allowance !== 0 && allowance !== isNaN
                          ? "text-secondary text-sm font-medium"
                          : "text-[#ABB6AC] text-sm font-medium"
                      }
                    >
                      Allowance
                    </p>
                    <img
                      src={
                       allowance > 0 && allowance !== 0 && allowance !== isNaN
                          ? ColouredCheckListIcon
                          : ChecklistIcon
                      }
                      alt="check_icon"
                    />
                  </div>
                </div>
              </div>
        </div>
      </div>

    </>
   )
}

export default EditAllowancePage;