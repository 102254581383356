import React, { useState } from "react";
import { Form, Formik } from "formik";
import {
  setUpAccountValidator
} from "../../validationSchema/validator";
import { 
    //Link, 
    useNavigate } from "react-router-dom";
import logo from "../../assets/c2c-logo.svg";
import eye_off from "../../assets/eye-off.svg";
import eye from "../../assets/eye.svg";
import Select from "react-select";
import warning from "../../assets/warning.svg";
import { useDispatch, useSelector } from "react-redux";
import { userRegister,  getBanks, verifyAccountNumber} from "../../redux/Auth/authAction";
import { useEffect } from "react";
import spinningLoader from "../../assets/loader.svg"
import { clearSignUpSuccess } from "../../redux/Auth/auth";

const SetUpAccountPage = () => {

//declaration of variables
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false);
  const {loading, verifyLoader, signup_success, pension_id, banks, account_name,} = useSelector(state => state.auth)

  
  //let isFullTime = "First City Monument Bank"

//   {
//     "id": 42,
//     "date_created": "2023-08-20T10:53:43.057632+01:00",
//     "date_updated": "2023-08-20T10:53:43.057635+01:00",
//     "bank_name": "First City Monument Bank",
//     "bank_code": "214",
//     "bank_slug": "first-city-monument-bank"
// },

 // let fcmb = "first-city-monument-bank|214"
  //use state to get form input from user- CONTROLLED FROM APPROACH
 
  //bank
  const [bank, setBank] = useState("");
  const [bankError, setBankError] = useState(false);
  const [step1Values, setStep1Values] = useState({});
  const [bankCode, setBankCode] = useState('')


  const handleBank = (val) => {
    let bankArray = [];
    let bankString = '';
    let code= '';
    if (typeof val === 'object' && val !== null) {
        bankArray.push(val.id);
        const { bank_slug, bank_code } = val;
        bankString = `${bank_slug}`;
        code  = `${bank_code}`;
      }
    setBank(bankString)
    setBankCode(code)
  
  };

  //handle form values inputted before continue
  const handleSignUp = (values) => {
    // Read the form data
    if(bank === ""){
        setBankError(true)
    }
    else{
        setBankError(false)
        let res = {
            ...step1Values,
            ...values,
            bank: bank,
            bank_code: bankCode,
            account_name: account_name
        };
        dispatch(userRegister(res))
        
    }
  };

//   const handleChangePage = (values) => {
//     //console.log(values)
//     setStep1Values(values)
//     setPage(2);
//     // setText('Sign Up')
//   };

//   const handleGoBack = () => {
//     setPage(1);
//   };

//   const handleVerifyEmail = (val) =>{
//     //console.log(val)

//     const regex = /(@crop2cash\.com\.ng$|^oluwakemiakinola00@gmail\.com$|abosoonline@gmail\.com$)/

//     const isValidEmail = regex.test(val);

//     if(isValidEmail){
//         dispatch(verifyEmail({
//             email: val,
//         }), )
//     }
//   }

  const handleVerifyAccountNumber = (val) => {
    const regex = /^\d{10}$/

    const isValidAccountNumber = regex.test(val);

    if(isValidAccountNumber) {
        dispatch(verifyAccountNumber({
            account_number: val,
            bank: `${bank}|${bankCode}`
        }),)
  }
}

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setPasswordConfirmShown(passwordConfirmShown ? false : true);
  };
 

  useEffect(()=>{
    if(signup_success){
        navigate('/')
        setStep1Values({})
        setTimeout(()=>{
            dispatch(clearSignUpSuccess())
        },500)
    }
  },[signup_success, dispatch, navigate])

  useEffect(()=>{
    dispatch(getBanks())
  }, [dispatch])


  return (
    <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>
        <div className="flex justify-between">
          <div>
            <img src={logo} alt="logo" className="md:w-[129px] md:h-[40px]" />
          </div>
          <div className="z-10"></div>
        </div>
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-16 py-20">
          {/* <div className="flex items-center flex-row justify-between">
            {page === 1 ? null : (
              <div>
                <img
                  src={arrow_prev}
                  alt="link to previous page"
                  onClick={(event) => handleGoBack(event)}
                />
              </div>
            )}

            <h6 className="text-sm font-semibold">Sign up</h6>
            {page === 1 ? (
              <p className="text-right text-sm">{page}/2</p>
            ) : (
              <p className="">2/2</p>
            )}
          </div>
          <div className="flex gap-[2px]">
            <div className="w-1/2 bg-[#2B8C34] rounded-[2px] h-1 mt-[27px]"></div>
            <div
              className={`w-1/2 ${
                page === 1 ? " bg-[#ABB6AC]" : " bg-[#2B8C34]"
              } rounded-[2px] h-1 mt-[27px]`}
            ></div>
          </div> */}

          <div className="mt-7">
            <h6 className="text-primary font-semibold text-xl">
              Set Up Account
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
              Enter your credentials below to complete your account setup{" "}
            </p>
          </div>
            
          <Formik
            onSubmit={(values, { setSubmitting }) =>
            handleSignUp(values, setSubmitting)
            }
            validationSchema={setUpAccountValidator}
            initialValues={{
             // phone_number: step1Values ? step1Values.phone_number : "",
              password: step1Values ? step1Values.password : "",
              confirm_password: step1Values ? step1Values.confirm_password : "",
              pension_id: pension_id? pension_id : '',
              account_number: '',
              account_name: account_name? account_name : ''
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <>
                {/* <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone_number"
                    placeholder="Phone Number"
                    value={values.phone_number}
                    onChange={handleChange}
                    className={
                        touched.phone_number && errors.phone_number
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      onBlur={handleBlur}
                  />
                  {touched.phone_number && errors.phone_number ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.phone_number && errors.phone_number}
                        </small>
                      </div>
                    ) : null}
                </div> */}
                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      type={passwordShown ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      className={
                        touched.password && errors.password
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      onBlur={handleBlur}
                    />
                    <img
                      onClick={togglePasswordVisiblity}
                      src={passwordShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                    {touched.password && errors.password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.password && errors.password}
                        </small>
                      </div>
                    ) : null}
                </div>
                <div className="mt-5">
                  <label className="block mb-2 text-secondary text-sm font-medium">
                    Confirm Password
                  </label>
                  <div className="relative">
                    <input
                      type={passwordConfirmShown ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      className={
                        touched.confirm_password && errors.confirm_password
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335] text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      onBlur={handleBlur}
                    />
                    <img
                      onClick={toggleConfirmPasswordVisiblity}
                      src={passwordConfirmShown ? eye : eye_off}
                      alt="eye"
                      className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer"
                    />
                  </div>
                  {touched.confirm_password && errors.confirm_password ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.confirm_password && errors.confirm_password}
                        </small>
                      </div>
                    ) : null}
                </div>
                <div className="mt-5">
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Pension ID
                    </label>
                    <input
                      type="text"
                      name="pension_id"
                      placeholder="PEN0123456789"
                      disabled= {pension_id? true : false}
                      value={values.pension_id}
                      onChange={handleChange}
                      className={
                        touched.pension_id && errors.password
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      onBlur={handleBlur} />
                      {touched.pension_id && errors.pension_id ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.pension_id && errors.pension_id}
                        </small>
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-5">
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Bank
                    </label>
                    <Select
                      onChange={handleBank}
                      options={banks}
                      name="bank"
                      placeholder="Select Bank"
                      className="select_style"
                      classNamePrefix="select_control"
                      getOptionLabel={options => options.bank_name}
                      getOptionValue={options => options.id}
                    ></Select>
                      {bankError ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          Bank is required
                        </small>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-5">
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Account Number
                    </label>
                    <div className="relative">
                    <input
                      type="tel"
                      name="account_number"
                      placeholder="Account Number"
                      value={values.account_number}
                      onChange={(e)=>{
                        handleChange(e);
                         handleVerifyAccountNumber(e.currentTarget.value)
                      }}
                      className={
                        touched.account_number && errors.account_number
                          ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#344335]text-base focus:border-[#B92043] focus:outline-none rounded-lg border border-[#B92043] bg-white py-3 px-4'
                          : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                      }
                      onBlur={handleBlur}
                    />

                     {verifyLoader &&  
                        <div className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer">
                        <img
                            src={spinningLoader}
                            alt="loading"
                            className="animate-spin"
                            />
                            </div>
                            }
                    </div>
                    {/* <div className='absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer nimate-spin'>
                  <AutorenewIcon style={{width: 20, height: 20, color: "#2B8C34"}} />
                  </div> */}
                    {touched.account_number && errors.account_number ? (
                      <div className="flex">
                        <img src={warning} className="" alt="warning" />
                        <small className="text-[#B92043] font-medium text-xs pl-[5.65px] pt-[4px]">
                          {touched.account_number && errors.account_number}
                        </small>
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-5">
                    <label className="block mb-2 text-secondary text-sm font-medium">
                      Account Name
                    </label>
                    <input
                      type="text"
                      name="account_name"
                      placeholder="Account Name"
                      disabled
                      value={account_name? account_name : ""}
                      className=
                          'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4'
                    />
                  </div>
                  <div className="mt-6">
                  <button
                    type="submit"
                    disabled={loading || account_name === ""} //hover color is #24752B
                    className="bg-primary disabled:bg-[#ABB6AC] rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all text-sm"
                  >
                    SignUp
                  </button>
                </div>
                </>
              </Form>
            )}
          </Formik>

            
            {/* <div className='text-center mt-3'>
             <p className='text-[#344335] text-sm font-semibold'>Already have an account? <Link to="/" className='text-primary font-semibold text-sm'>Log in</Link> </p>
            </div> */}
        </div>
      </div>
    </>
  );
}

export default SetUpAccountPage 
