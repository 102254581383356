import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  }


//get records table
// export const recordsTable = createAsyncThunk(
//     'recordsTable',
//     async({month, year, page}, {rejectWithValue, dispatch}) => {
//       // console.log({month, year})
//      try {
//         const res = await axios.get(`${apiUrl}payslip_records?month=${month}&year=${year}&page_size=10&page=${page}`,
//         {
//             headers:{
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${getToken()}` 
//             }
//         }
//         )
//     if(res.status === 200){
//         return res.data
//     }
    
//   } catch (error) {
//     if(error.response.status === 401){
//       dispatch({type: 'auth/Logout'})
//     }
//     else{
//       return rejectWithValue(error.response)
//     }
//   }
//     }
// )
export const recordsTable = createAsyncThunk(
  'recordsTable',
  async ({ month, year, page }, { rejectWithValue, dispatch }) => {
    try {
      let apiUrlWithParams = `${apiUrl}payslip_records?page_size=10&page=${page}`;

      if (month !== "" && year === "") {
        apiUrlWithParams += `&month=${month}`;
      } else if (year !== "" && month === "") {
        apiUrlWithParams += `&year=${year}`;
      } else if (month !== "" && year !== "") {
        apiUrlWithParams += `&month=${month}&year=${year}`;
      }

      const res = await axios.get(apiUrlWithParams, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        },
      });

      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);


//download records  as csv
// export const downloadRecords = createAsyncThunk(
//   'downloadRecords',
//   async({month, year}, {rejectWithValue, dispatch}) => {
//    try { 
//       let url = `${apiUrl}download_payslip_record?month=${month}&year=${year}`
//       const res = await axios.get(`${apiUrl}download_payslip_record?month=${month}&year=${year}`,
//       {
//           headers:{
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${getToken()}` 
//           },
//           responseType: 'blob'
//       }
//       )
//       .then((response) => {
//          url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', `Records for ${month} ${year}.csv`); // Replace with the desired filename to save the PDF
//         document.body.appendChild(link);
//         link.click();
//         link.remove();
//         cogoToast.success(`Record download successfull!`);
//       })
//   if(res.status === 200){
//       cogoToast.success(`Record download successfull!`);
//       return res.data
//   }
  
// } catch (error) {
//   if(error.response.status === 401){
//     dispatch({type: 'auth/Logout'})
//   }
//   else{
//       cogoToast.error(`Error while downloading record!`);
//     return rejectWithValue(error.response)
//   }
//   }
// }
// ) 
export const downloadRecords = createAsyncThunk(
  'downloadRecords',
  async ({ month, year }, { rejectWithValue, dispatch }) => {
    try {
      let url = `${apiUrl}download_payslip_record`;

      if (month !== "") {
        url += `?month=${month}`;
      }

      if (year !== "") {
        url += `${url.includes('?') ? '&' : '?'}year=${year}`;
      }

      const res = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`,
        },
        responseType: 'blob',
      });

      const blob = new Blob([res.data], { type: 'application/csv' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `Records for ${month} ${year}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      cogoToast.success(`Record download successful!`);

      if (res.status === 200) {
        cogoToast.success(`Record download successful!`);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' });
      } else {
        cogoToast.error(`Error while downloading record!`);
        return rejectWithValue(error.response);
      }
    }
  }
);

