import React, { useState } from "react";
import { MoreVert, ContentCopy } from "@mui/icons-material";
import Header from "../../../components/header";
import cogoToast from "cogo-toast";
import PayslipHistoryTable from "../../../Tables/EmployeeTables/paysliphistory";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AllowanceHistoryTable from "../../../Tables/EmployeeTables/allowancehistory";
import GoBack from "../../../components/goBack";
import ModalComponent from "../../../components/Modals/modal";
import { updateEmployeeStatus } from "../../../redux/Employee/employeeAction";

const EmployeeProfilePage = () => {
  const [tab, setTab] = useState("account_information");
  const [isClickMore, setIsClickMore] = useState(false);
  const handleCopyNumber = (id) => {
    navigator.clipboard.writeText(id);
    cogoToast.success(`${id} copied successfully`);
  };
  const { employees, is_updated } = useSelector((state) => state.employee);
  //get id from url
  const { id } = useParams();

  let employee;

  if (employees.some((val) => val.id === parseInt(id))) {
    employee = employees.find((val) => val.id === parseInt(id));
    // console.log(employee);
  } else {
    employee = employees.find((val) => val.paytimeuserprofile.id === parseInt(id));
    // console.log(employee);
  }


  // const getEmployeeStatusBg = (val) => {
  //   let result;
  //   switch (val) {
  //     case "ACTIVE":
  //       result = "success-badge";
  //       break;
  //     case "PENDING":
  //       result = "pending-badge";
  //       break;
  //     case "INACTIVE":
  //       result = "done-badge";
  //       break;
  //     default:
  //       result = "pending-badge";
  //       break;
  //   }
  //   return result;
  // };

  // const getEmployeeStatusText = (val) => {
  //   let result;
  //   switch (val) {
  //     case "INACTIVE":
  //       result = "Inactive";
  //       break;
  //     case "PENDING":
  //       result = "Pending";
  //       break;
  //     case "ACTIVE":
  //       result = "Active";
  //       break;
  //     default:
  //       result = "Pending";
  //       break;
  //   }
  //   return result;
  // };

  //toggle modal to diable employee
  const [isDisableEmployee, setIsDisableEmployee] = useState(false);
  const toggleModal = (val) => {
    setIsDisableEmployee(!isDisableEmployee);
  }

  //function to disable an employee
  const dispatch = useDispatch();
  const handleDisableEmployee = (val) => {
    //console.log(val)
    dispatch(updateEmployeeStatus({ id: val }))
  }

  if (is_updated) {
    employee = employees.find((val) => val.id === parseInt(id));
    //console.log(employee)
  }



  return (
    <>
      <GoBack />
      <Header
        text="Employee Profile"
        tabs={
          <div className="flex gap-[16px] mt-[16px]">
            <p
              className={
                tab === "account_information"
                  ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                  : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
              }
              onClick={() => setTab("account_information")}
            >
              Account Information
            </p>
            <p
              className={
                tab === "payslip_history"
                  ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                  : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
              }
              onClick={() => setTab("payslip_history")}
            >
              Payslip History
            </p>
            <p
              className={
                tab === "allowance"
                  ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                  : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
              }
              onClick={() => setTab("allowance")}
            >
              Allowance
            </p>
          </div>
        }
      >
        <div className="">
          <div className="">
            <div className="relative">
              <MoreVert
                onClick={() => setIsClickMore(!isClickMore)}
                className="text-primary cursor-pointer"
                style={{ fontSize: "20px" }}
              />

              {isClickMore && (
                <>
                  <div
                    className="z-10 absolute top-10 right-0 w-[213px] rounded-[8px] bg-white p-[8px] hover:rounded-[6px]"
                    style={{
                      boxShadow:
                        "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)",
                    }}
                  >
                    <div className="mb-[4px] hover:bg-[#EDF7EE] transition-all cursor-pointer">
                      <Link to={`/edit-employee-profile/${id}`}>
                        <p className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]">
                          Edit Profile
                        </p>
                      </Link>
                    </div>

                    <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                      <Link to="/payroll/payslip-calculator">
                        <p className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]">
                          Generate Payslip
                        </p>
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Header>
      <ModalComponent title="Disable Employee" showModal={toggleModal} show={isDisableEmployee} subtitle="Are you sure you want to disable this employee?">
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <button
            className="text-sm text-medium leading-5 text-[#5C715E]"
            onClick={() => toggleModal(!isDisableEmployee)}>No</button>
          <button
            // onClick={handleApprovePayslip}
            // disabled={loading}
            className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white"
            onClick={() => {
              toggleModal(!isDisableEmployee);
              handleDisableEmployee(employee.id)
            }}>
            Yes
          </button>
        </div>
      </ModalComponent>
      {tab === "account_information" && (
        <>
          <div className="flex flex-col bg-white rounded-[8px] p-4 mt-4">
            <div className="flex flex-row justify-between gap-[8px] mb-[24px]">
              {/* FARMER"S INITIALS */}
              <div className="flex flex-row gap-[8px]">
                <div className="bg-primary rounded-full w-[48px] h-[48px] text-white flex items-center justify-center">
                  <p className="font-medium text-base leading-6 ">
                    {employee ? employee.first_name.charAt(0) : "N/A"}
                    {employee ? employee.last_name.charAt(0) : "N/A"}
                  </p>
                </div>
                <div>
                  <p className="text-[#344335] font-semibold leading-[18px] text-sm">
                    {employee ? employee.first_name : "N/A"}{" "}
                    {employee ? employee.last_name : "N/A"}
                  </p>
                  <div
                    className="flex gap-[6px] mt-[4px] cursor-pointer"
                    onClick={() =>
                      handleCopyNumber(
                        `${employee.paytimeuserprofile
                          ? employee.paytimeuserprofile.employee_id
                          : "N/A"
                        }`
                      )
                    }
                  >
                    <p className="text-primary font-semibold leading-[18px] text-sm cursor-pointer">
                      {employee.paytimeuserprofile ? employee.paytimeuserprofile.employee_id
                        : "-"}
                    </p>
                    <ContentCopy
                      className="text-primary cursor-copy"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <div
                  className={
                    employee.is_active ? "success-badge" : "error-badge"
                  }
                >
                  <p
                    className={
                      employee.is_active
                        ? "text-primary font-semibold text-medium"
                        : "text-white font-semibold text-medium"
                    }
                  >
                    {employee.is_active ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex  justify-between">
              <div className="mb-[24px]">
                <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                  Department
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344335]">
                  {employee.paytimeuserprofile !== null &&
                    employee.paytimeuserprofile.department ? employee.paytimeuserprofile.department
                    : "-"}
                </p>
              </div>
              <div className="mb-[24px]">
                <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                  Role
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344335]">
                  {employee.paytimeuserprofile !== null &&
                    employee.paytimeuserprofile.role
                    ? employee.paytimeuserprofile.role
                    : "-"}
                </p>
              </div>
              <div className="mb-[24px]">
                <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                  Account Name
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344335]">
                  {employee.paytimeuserprofile !== null &&
                    employee.paytimeuserprofile.account_name
                    ? employee.paytimeuserprofile.account_name
                    : "-"}
                </p>
              </div>
              <div className="mb-[24px]">
                <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                  Account Number
                </p>
                <p
                  className="font-semibold text-sm leading-5 text-[#344335]"
                  onClick={() =>
                    handleCopyNumber(
                      `${employee
                        ? employee.paytimeuserprofile?.account_number
                        : "-"
                      }`
                    )
                  }
                >
                  {" "}
                  {employee && employee.paytimeuserprofile !== null
                    ? employee.paytimeuserprofile?.account_number
                    : "-"}
                  <ContentCopy
                    className="text-[#4B4E68] cursor-copy"
                    style={{ fontSize: "18px" }}
                  />
                </p>
              </div>
              <div className="mb-[24px]">
                <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                  Bank
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344335]">
                  {employee && employee.paytimeuserprofile !== null
                    ? employee.paytimeuserprofile.bank
                    : "-"}
                </p>
              </div>
              <div className="mb-[24px]">
                <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                  Pension ID
                </p>
                <p
                  className="font-semibold text-sm leading-5 text-[#344335]"
                  onClick={() =>
                    handleCopyNumber(
                      `${employee.paytimeuserprofile.pension_id !== null
                        ? employee.paytimeuserprofile.pension_id
                        : "-"
                      }`
                    )
                  }
                >
                  {employee.paytimeuserprofile !== null &&
                    employee.paytimeuserprofile.pension_id !== null
                    ? employee.paytimeuserprofile.pension_id
                    : "-"}{" "}
                  <ContentCopy
                    className="text-[#4B4E68] cursor-copy"
                    style={{ fontSize: "18px" }}
                  />
                </p>
              </div>
              <div className="mb-[24px]">
                <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
                  Email Address
                </p>
                <p className="font-semibold text-sm leading-5 text-[#344335]">
                  {employee ? employee.email : "N/A"}
                </p>
              </div>
            </div>
            <div className="flex justify-end mt-[10%]">
              <button className="bg-transparent justify-end text-[#B92020] rounded-lg border-[#B92020] border font-medium transition-all py-2.5 px-3" onClick={() => toggleModal(employee.id)}>
                Disable Employee
              </button>
            </div>
          </div>
        </>
      )}

      {tab === "payslip_history" && <PayslipHistoryTable />}

      {tab === "allowance" && <AllowanceHistoryTable />}
    </>
  );
};

export default EmployeeProfilePage;
