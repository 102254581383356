import { createSlice } from '@reduxjs/toolkit'
import { allowanceTable } from './allowanceAction'


const initialState = {
    value: 0,
    total: 0,
    loading: false,
    loader: false,
    allowance: [],

}

export const allowanceSlice = createSlice({
    name: 'allowance',
    initialState,
    reducers: {
        increment: (state) => {
            state.value +=1
        },
        decrement: (state) => {
            state.value -= 1
          },
          incrementByAmount: (state, action) => {
            state.value += action.payload
          },
    },
    extraReducers: {
    [allowanceTable.pending]: (state)=>{
        state.loading = true
        state.loader = true
    },
    [allowanceTable.fulfilled]: (state,{payload})=>{
        state.loading = false
        state.loader = false
        state.total = payload.count
        state.allowance = payload.results
    },
    [allowanceTable.rejected]: (state) =>{
      state.loading = false
      state.loader = false
    },
    }
})

 // Action creators are generated for each case reducer function
 export const { increment, decrement, incrementByAmount } = allowanceSlice.actions
  
 export default allowanceSlice.reducer
