import React, { useState, useEffect} from "react";
import logo from "../../assets/c2c-logo.svg";
import eye from "../../assets/eye.svg";
import eye_off from "../../assets/eye-off.svg";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { validateOtp } from "../../redux/Auth/authAction";

const SetPasswordPage = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [onPasswordFocus, setOnpasswordFocus] = useState(false);
  //check state of password
  const [isValid, setIsValid] = useState(false);

  const { loading, isValidOtp } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const id = params.get('id');
  const otp = params.get('otp');

  // Now you can use id and otp
  console.log(id);
  console.log(otp)


  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = (data) => {
    console.log(data);
    let payload = {
      user_id: parseInt(id, 10), 
      password: password,
      otp: otp
    }
    dispatch(validateOtp(payload))
  };

  //validation regex
  const hasMinimumLength = (password) => password.length >= 8;
  const hasNumericCharacter = (password) => /\d/.test(password);
  const hasLowercaseCharacter = (password) => /[a-z]/.test(password);
  const hasSpecialCharacter = (password) => /[!@#$%^&*()_+\-={}|;:'",<.>/?`~]/.test(password);

  const handleChange = (e) => {
    setPassword(e.target.value);
    setIsValid(
      hasMinimumLength(e.target.value) &&
      hasNumericCharacter(e.target.value) &&
      hasLowercaseCharacter(e.target.value) &&
      hasSpecialCharacter(e.target.value)
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isValidOtp) {
      navigate('/reset-success');
    }
  }, [isValidOtp, navigate]);





  return (
    <>
      <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>
        <div className="flex justify-between">
          <div>
            <img src={logo} alt="logo" className="md:w-[129px] md:h-[40px]" />
          </div>
          <div className="z-10"></div>
        </div>
        <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-20">
          <div>
            <h6 className="text-primary font-semibold text-2xl">
              Set New Password
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1">
              Looks like second chances do exist. Type in your new password
            </p>
          </div>
          <form className="mt-8" onSubmit="">
            <div className="mt-5">
              <label
                htmlFor="password"
                className="block mb-2 text-secondary text-sm font-medium"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={(e) => handleChange(e)}
                  onFocus={() => setOnpasswordFocus(true)}
                  onBlur={() => setOnpasswordFocus(false)}
                  // onChange={(e) => SetMealOutlined()
                  className="w-full border rounded-lg px-4 py-3  appearance-none focus:border-[#2B8C34] focus:outline-none focus:shadow-input border-[#96A397]"
                />
                <img
                  onClick={togglePasswordVisiblity}
                  src={passwordShown ? eye : eye_off}
                  alt="eye"
                  className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                />

              </div>
              <div className="mt-1">
                {onPasswordFocus && (
                  <div className="">
                    {isValid ? (
                      <p className="text-green-500 font-semibold text-xs leading-[16px]">
                        Password meets all requirements!
                      </p>
                    ) : (
                      <div>
                        <p className="font-semibold text-xs leading-[16px] text-[#5C715E]">
                          Your Password Must Contain At Least:
                        </p>

                        <div className="flex flex-wrap gap-2 mt-1">
                          <div
                            className={`bg-white border flex gap-2 border-[#96A397] rounded-[4px] py-1 px-2 text-xs text-[#96A397] font-medium leading-[16px]`}
                          >
                            <p>Eight Characters</p>
                          </div>

                          <div className="bg-white border border-[#96A397] rounded-[4px] py-1 px-2 text-xs text-[#96A397] font-medium leading-[16px]">
                            <p>One Numeric Character</p>
                          </div>

                          <div className="bg-white border border-[#96A397] rounded-[4px] py-1 px-2 text-xs text-[#96A397] font-medium leading-[16px]">
                            <p>One Lowercase Character</p>
                          </div>

                          <div className="bg-white border border-[#96A397] rounded-[4px] py-1 px-2 text-xs text-[#96A397] font-medium leading-[16px]">
                            <p>One Special Character</p>
                          </div>
                        </div>
                      </div>
                    )}
                    </div>
                )}
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading || !isValid}
                  //hover color is #24752B
                  className="bg-primary disabled:bg-[#ABB6AC] rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all text-sm"
                >
                  {loading ? "loading..." : "Set Password"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SetPasswordPage;
