import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import { useNavigate } from 'react-router-dom'


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  }

//get overview balance
export const overviewBalance = createAsyncThunk(
    'overviewBalance',
    async(arg, {rejectWithValue, dispatch}) => {
        try {
            const res = await axios.get(`${apiUrl}payroll/overview/balance`,
            {
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}` 
                }
            }
            )
        if(res.status === 200){
            return res.data
        }
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
          const navigate = useNavigate()
          navigate('/login')
        }
        else{
          return rejectWithValue(error.response)
        }
      }
        }
    )

//get payroll overview list
export const payrollOverviewTable = createAsyncThunk(
    'payrollOverview',
    async({page, page_size}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}payroll/overview?page_size=${page_size}&page=${page}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
      const navigate = useNavigate()
      navigate('/login')
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)

