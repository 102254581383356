import React, { useEffect, useState
  //useState, useCallback, useMemo 
} from "react";
import Header from "../../components/header";
//import SearchComponent from "../../components/search";
import searchIcon from "../../assets/search-green.svg";
//import filterIcon from "../../assets/filter_list.svg";
//import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  overviewBalance,
  payrollOverviewTable,
} from "../../redux/Overview/overviewAction";
import eye_open from "../../assets/eye.svg";
import eye_close from "../../assets/eye-off.svg";
import moment from "moment";
import PaginationComponent from "../../components/Pagination/Pagination"
import LoadingStateComponent from "../../components/Empty State/loadingstate";

const OverviewPage = () => {
  
  const dispatch = useDispatch();

  const { loading, balance, allPayrolls, total } = useSelector(
    (state) => state.overview
  );

  //visbility of the overview amount
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  }
  
   //pagination
   const [currentPage, setCurrentPage] = useState(1)
   const [pageSize, setPageSize] = useState(10);

    const onPageSizeChange = (page) => {
        setPageSize(page)
    }

   const onPageChange = (val) => {
     const { page } = val
     setCurrentPage(page)
     dispatch(payrollOverviewTable({
             page: page,
             page_size: pageSize
           })
     )
   }
  

  useEffect(() => {
    dispatch(overviewBalance());
    dispatch(payrollOverviewTable(
      {
        page: 1,
        page_size: pageSize
    }
    ));
  }, [dispatch, currentPage, pageSize]);

  // { Badge Definition using switch statements}
  const getPaymentStatusBg = (val) => {
    let result;
    switch (val) {
      case "Failed":
        result = "error-badge";
        break;
      case "Processing":
        result = "dispatched-badge";
        break;
      case "Completed":
        result = "success-badge";
        break;
      case "Pending":
        result = "pending-badge";
        break;
      case "Done":
        result = "done-badge";
        break;
      case "Pay":
        result = "pay-badge";
        break;
      default:
        result = "dispatched-badge";
        break;
    }
    return result;
  };

  const getPaymentStatusText = (val) => {
    let result;
    switch (val) {
      case "Processing":
        result = "Processing";
        break;
      case "Failed":
        result = "Failed";
        break;
      case "Pending":
        result = "Pending";
        break;
      case "Completed":
        result = "Completed";
        break;
      default:
        result = "Processing";
        break;
    }
    return result;
  };

 


  return (
    <>
      <Header text="Overview"></Header>
      <section className="mb-5 flex gap-3 mt-6">
        <div
          className={`bg-white rounded-lg py-4 px-4 border-l-[3px] border-l-primary w-[240px] h-[106px]`}
        >
          <h6 className="text-[#96A397] font-semibold text-sm">
            Wallet Balance
          </h6>
          <div className="flex flex-row gap-2 jusity-center  items-center">
            <p className="text-secondary font-semibold text-[24px] leading-7.5 mt-3 ">
                {/* { loader?  (
              <div className="animate-pulse w-full mt-4 px-4 mb-4">
                  <div className="h-6 bg-gray-200 rounded-lg  w-full mb-2"></div>
              </div>
            ) : `${balance? `₦${balance}`: 0 }` */}
   
              {/* Handle null balance and conditional rendering */}
              {isVisible ? (balance ? `₦${balance.toLocaleString()}` : 0) : "*******"}
 
              {/* {balance? `₦${balance?.toLocaleString()}`: 0  } */}
            </p>
            <div className= "flex flex-row items-center">
              <img className="cursor-pointer item-center center-center" src={isVisible? eye_open : eye_close} alt="show or hide balance icon" onClick={toggleVisibility}/>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-8 bg-white rounded-[8px]">
        <div className="p-4">
          <h5 className="text-primary font-semibold text-base leading-5">
            Monthly Payment
          </h5>
          <p className="text-[#7C8D7E] font-medium text-sm">
            This table shows a summary of payments made monthly.
          </p>
          {/* search */}
          {/* <div className="flex mt-4 gap-4">
            <div className="w-full">
              <SearchComponent
                placeholder="Search"
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
            <div>
              <button className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer">
                <p>Filters</p>
                <img src={filterIcon} alt="filtericon" />
              </button>
            </div>
          </div> */}
        </div>
        {/* Overview Table */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Month
                </th>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Year
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  No. of Employees
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Net Payment
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Allowance Payment
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Pension Remitted
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Taxable Income
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Payment Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {allPayrolls.length > 0 &&
                  allPayrolls.map((item) => (
                    <tr
                      key={item.id}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E]"
                      >
                        {moment(item.date_of_entry).format("MMMM")}
                      </th>

                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(item.date_of_entry).format("YYYY")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_payment.number_of_employee}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_payment.total_net_payment
                          ? `₦${item.net_payment.total_net_payment?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_payment.total_allowance
                          ? `₦${item.net_payment.total_allowance?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_payment.total_pension_remitted
                          ? `₦${item.net_payment.total_pension_remitted?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_payment.total_tax
                          ? `₦${item.net_payment.total_tax?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        <div className="flex">
                          <div
                            className={getPaymentStatusBg(
                              item.net_payment.payment_status
                            )}
                          >
                            <p>
                              {getPaymentStatusText(
                                item.net_payment.payment_status
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>

          {loading && (
            <LoadingStateComponent/>
          )}
         
        </div>
      </div>
      {!loading && allPayrolls.length < 1 && (
        <div className="bg-transparent h-full pt-70 px-4 mt-32 w-full flex justify-center items-center flex-col gap-4">
          <div className="rounded-full bg-[#DBFFDF] p-3">
            <img src={searchIcon} alt="search" />
          </div>
          <p className="text-base font-medium text-secondary1">
            {" "}
            Oops! No monthly payroll available.
          </p>
        </div>
      )}
        {!loading && allPayrolls.length > 0 ? (
        <div>
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            totalItems={total}
            pageSize={pageSize}
            pageSizeShow
            onPageSizeChange={(page) => onPageSizeChange(page)}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default OverviewPage;
