import { createSlice } from '@reduxjs/toolkit'
import { downloadPayslip, handleMakeComplaint, retrieveMonthlyPayslips, retrievePayslip } from './payslipAction'


const initialState = {
    value: 0,
    loading: false,
    total: 0,
    loader: false,
    payslips: [],
    payslip: {}
}

export const payslipSlice = createSlice({
    name: 'payslip',
    initialState,
    reducers: {
      increment: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.value += 1
      },
      decrement: (state) => {
        state.value -= 1
      },
      incrementByAmount: (state, action) => {
        state.value += action.payload
      },
    },
    extraReducers: {
      // monthly payslip -------------------- ---------------------------------------------------------------
      [retrieveMonthlyPayslips.pending]: (state)=>{
          state.loading = true
      },
      [retrieveMonthlyPayslips.fulfilled]: (state,{payload})=>{
          state.loading = false
          state.total = payload.count
          state.payslips = payload.results
      },
      [retrieveMonthlyPayslips.rejected]: (state) =>{
        state.loading = false
      },
      // get an employee payslip
      [retrievePayslip.pending]: (state) =>{
          state.loader = true
      },
      [retrievePayslip.fulfilled]: (state,{payload}) =>{
          state.loader = false
          state.payslip = payload.data
      },
      [retrievePayslip.rejected]: (state) =>{
        state.loader = false
      },
       // download an employee payslip
       [downloadPayslip.pending]: (state) =>{
         state.loader = true
      },
      [downloadPayslip.fulfilled]: (state,{payload}) =>{
          state.loader = false
      },
      [downloadPayslip.rejected]: (state) =>{
         state.loader = false
      },
       // make a complaint 
       [handleMakeComplaint.pending]: (state) =>{
        state.loader = true
     },
     [handleMakeComplaint.fulfilled]: (state,{payload}) =>{
         state.loader = false

     },
     [handleMakeComplaint.rejected]: (state) =>{
        state.loader = false
     },
    }
    
  })
  
  // Action creators are generated for each case reducer function
  export const { increment, decrement, incrementByAmount } = payslipSlice.actions
  
  export default payslipSlice.reducer

