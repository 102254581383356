import React, { useState, useEffect } from 'react'
import DrawerComponent from '../../components/Drawer/drawer'
import { useDispatch, useSelector } from 'react-redux'
import { listEmployeePayslip, retrieveEmployeePayslip, downloadEmployeePayslip, sendEmployeePayslip } from '../../redux/Employee/employeeAction'
import moment from "moment";
import { useParams } from "react-router-dom";
import EmptyStateComponent from '../../components/Empty State/emptystate';
import PaginationComponent from '../../components/Pagination/Pagination';

const PayslipHistoryTable = () => {

  const dispatch = useDispatch();

  const { loading, employeePayslips, employeePayslip, total } = useSelector(
    (state) => state.employee
  );

  //get id from url
  const { id } = useParams()

  const userPk = parseInt(id, 10) // Convert the id to an integer

  //pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10);

  const onPageSizeChange = (page) => {
    setPageSize(page)
  }


  const onPageChange = (val) => {
    const { page } = val
    setCurrentPage(page)
    dispatch(listEmployeePayslip({
      page: page,
      page_size: pageSize
    })
    )
  }


  useEffect(() => {
    dispatch(listEmployeePayslip({ id: userPk, page: 1, page_size: pageSize }));
  }, [dispatch, userPk, pageSize])

  const getPaymentStatusBg = (val) => {
    let result;
    switch (val) {
      case 'FAILED':
        result = 'error-badge'
        break
      case 'PROCESSING':
        result = 'dispatched-badge'
        break
      case 'COMPLETED':
        result = 'success-badge'
        break
      case 'PENDING':
        result = 'pending-badge'
        break
      default:
        result = 'dispatched-badge'
        break
    }
    return result
  }

  const getPaymentStatusText = (val) => {
    let result
    switch (val) {
      case 'PROCESSING':
        result = 'Processing'
        break
      case 'FAILED':
        result = 'Failed'
        break
      case 'PENDING':
        result = 'Pending'
        break
      case 'COMPLETED':
        result = 'Completed'
        break
      default:
        result = 'Processing'
        break
    }
    return result
  }

  //open drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  //close drawer
  const closePayslip = () => {
    setIsDrawerOpen(!isDrawerOpen)
  };

  const toggleRequest = (payslipId) => {
    //get payslip id

    const pk = parseInt(payslipId, 10)
    dispatch(
      retrieveEmployeePayslip({
        employee_id: userPk,
        payslip_id: pk
      })
    )
    setIsDrawerOpen(!isDrawerOpen)

  }

  const handleEmployeePayslipDownload = (id) => {
    setIsDrawerOpen(false);
    dispatch(downloadEmployeePayslip({
      id: id,
      date: moment(employeePayslip.date_of_entry).format('YYYY-MM-DD'),
      payslip: `${employeePayslip.employee.first_name} ${employeePayslip.employee.last_name} -  ${moment(employeePayslip.date_of_entry).format("MMMM")}  ${moment(employeePayslip.date_of_entry).format("YYYY")}`
    }))
  }

  const handleSendEmployeePayslip = (id) => {
    setIsDrawerOpen(false);
    dispatch(sendEmployeePayslip({
      id: id,
      date: moment(employeePayslip.date_of_entry).format('YYYY-MM-DD')
    }))
  }


  return (
    <>
      <DrawerComponent
        title="Payslip Info"
        show={isDrawerOpen}
        showDrawer={closePayslip}
      >
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Name</h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {employeePayslip && employeePayslip.employee ? employeePayslip.employee.first_name : "N/A"} {" "} {employeePayslip && employeePayslip.employee ? employeePayslip.employee.last_name : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Position</h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {employeePayslip && employeePayslip.employee ? employeePayslip.employee.position : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Payslip Period</h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {employeePayslip && employeePayslip.date_of_entry
                  ? moment(employeePayslip.date_of_entry).format("MMMM")
                  : "N/A"}
                ,{" "}
                {employeePayslip && employeePayslip.date_of_entry
                  ? moment(employeePayslip.date_of_entry).format("YYYY")
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[16px] leading-5 text-primary font-semibold'>
                Description
              </p>
            </div>
            <div>
              <p className='text-[18px] leading-5 text-primary font-semibold'>
                Amount
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Basic Allowance
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.basic_salary?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Housing Allowance
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.housing_allowance?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Entertainment Allowance
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.entertainment_allowance?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Transport Allowance
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.transport_allowance?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Leave Allowance
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.leave_allowance?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Utility Allowance
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.utility_allowance?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Training Allowance
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.training_allowance?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-base leading-5 text-primary font-semibold'>
                Gross Payable
              </p>
            </div>
            <div>
              <p className='text-base leading-5 text-primary font-semibold'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.gross_payable?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Taxable Pay
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.gross_salary
                  ? `₦${employeePayslip.gross_salary.taxable_income?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        {/* Deductions */}
        <div className='mt-6'>
          <div>
            <p className='text-base leading-5 text-[#B92043] font-semibold'>
              Deductions
            </p>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                PAYE
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.deductions
                  ? `₦${employeePayslip.deductions.paye?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Employee Pension
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.deductions
                  ? `₦${employeePayslip.deductions.employee_pension?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                HMO
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip && employeePayslip.deductions
                  ? `₦${employeePayslip.deductions.hmo?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        {employeePayslip.deductions && employeePayslip.deductions.more_deductions && Object.keys(employeePayslip.deductions.more_deductions).length !== 0 && (
          <div className='mt-6'>
            <div className='flex justify-between items-center'>
              <div>
                <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                  Salary Advance
                </p>
              </div>
              <div>
                <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                  {employeePayslip.deductions.more_deductions && Object.keys(employeePayslip.deductions.more_deductions).length !== 0
                    ? `₦${employeePayslip.deductions.more_deductions.salary_advance?.toLocaleString()}`
                    : 0}
                </p>
              </div>
            </div>
          </div>
        )}
        {employeePayslip.deductions && employeePayslip.deductions.more_deductions && Object.keys(employeePayslip.deductions.more_deductions).length !== 0 && (
          <div className='mt-6'>
            <div className='flex justify-between items-center'>
              <div>
                <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                  Loan Repayment
                </p>
              </div>
              <div>
                <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                  {Object.keys(employeePayslip.deductions.more_deductions).length !== 0
                    ? `₦${employeePayslip.deductions.more_deductions.loan_repayment?.toLocaleString()}`
                    : 0}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-base leading-5 text-[#B92043] font-semibold'>
                Total Deductions
              </p>
            </div>
            <div>
              <p className='text-base leading-5 text-[#B92043] font-semibold'>
                {employeePayslip && employeePayslip.deductions
                  ? `₦${employeePayslip.deductions.total_deductions?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Overtime
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip.deductions && employeePayslip.deductions.others && Object.keys(employeePayslip.deductions.others).length !== 0 && employeePayslip.deductions.others.overtime ?
                  `₦${employeePayslip.deductions.others.overtime?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                Arrears
              </p>
            </div>
            <div>
              <p className='text-[14px] leading-[18px] text-secondary1 font-medium'>
                {employeePayslip.deductions && employeePayslip.deductions.others && Object.keys(employeePayslip.deductions.others).length !== 0 && employeePayslip.deductions.others.arrears ?
                  `₦${employeePayslip.deductions.others.arrears?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        {employeePayslip.deductions && employeePayslip.deductions.others && Object.keys(employeePayslip.deductions.others).length !== 0 && (
          <div className="mt-6">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                  Bonus
                </p>
              </div>
              <div>
                <p className="text-[14px] leading-[18px] text-secondary1 font-medium">
                  {employeePayslip.deductions && employeePayslip.deductions.others && Object.keys(employeePayslip.deductions.others).length !== 0 ?
                    `₦${employeePayslip.deductions.others.bonus?.toLocaleString()}`
                    : 0}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div>
              <p className='text-[16px] leading-5 text-primary font-semibold'>
                Net Salary
              </p>
            </div>
            <div>
              <p className='text-[18px] leading-5 text-primary font-semibold'>
                {employeePayslip && employeePayslip.net_salary
                  ? `₦${employeePayslip.net_salary?.toLocaleString()}`
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex justify-between items-center'>
            <div
              onClick={() =>
                handleEmployeePayslipDownload(employeePayslip && employeePayslip.id ? employeePayslip.id : "")
              }
            >
              <button className='outline-none bg-primary border border-primary text-white font-medium text-sm py-[10px] px-[20px] rounded-[8px] hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100'>
                Download Payslip
              </button>
            </div>
            <div
              onClick={() =>
                handleSendEmployeePayslip(employeePayslip && employeePayslip.id ? employeePayslip.id : "")
              }>
              <button className='outline-none bg-white border border-primary text-primary font-medium text-sm py-[10px] px-[20px] rounded-[8px] hover:bg-primary hover:border hover:border-primary hover:text-white hover:text-bold transition ease-in-out delay-100'>
                Send Payslip
              </button>
            </div>
          </div>
        </div>
      </DrawerComponent>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <p className="text-primary font-semibold text-base leading-5">
            Payroll History
          </p>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Date
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Net Amount
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Payment Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {employeePayslips.length > 0 &&
                  employeePayslips.map((item) => (
                    <tr
                      key={item.id}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      onClick={() => toggleRequest(item.id)}
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {moment(item.date_of_entry).format("MMMM Do, YYYY")}
                      </th>

                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(item.date_of_entry).format("h:mm a")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_salary ? `₦${item.net_salary?.toLocaleString()}` : 'N/A'}
                      </td>
                      <td className="py-4 px-4">
                        <div className="flex">
                          <div className={getPaymentStatusBg(item.payment_status)}>
                            <p>{getPaymentStatusText(item.payment_status)}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>

          {loading && (
            <div className="animate-pulse w-full mt-4 px-4 mb-4">
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
              <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
            </div>
          )}

        </div>
      </div>
      {!loading && employeePayslips.length < 1 && (
        <EmptyStateComponent
          textContent="Oops! No payslip history available"></EmptyStateComponent>
      )
      }
      {!loading && employeePayslips.length > 0 ? (
        <div className="">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            totalItems={total}
            pageSize={pageSize}
            pageSizeShow
            onPageSizeChange={(page) => onPageSizeChange(page)}
          />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default PayslipHistoryTable;