import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'

const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };

//get allowance table
export const allowanceTable = createAsyncThunk(
    'allowanceTable',
    async({page}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}employee/allowance?page_size=10&page=${page}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)