import { createSlice } from '@reduxjs/toolkit'
import { adminComplaints, editComplaint, employeeComplaints, retrieveAdminComplaint, retrieveEmployeeComplaint } from './complaintsAction'

const initialState = {
    value: 0,
    total: 0,
    loading: false,
    loader: false,
    complaints: [],
    singleComplaint: []
}

export const complaintSlice = createSlice({
    name: 'complaints',
    initialState,
    reducers: {
        increment: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.value += 1
          },
          decrement: (state) => {
            state.value -= 1
          },
          incrementByAmount: (state, action) => {
            state.value += action.payload
          },
    },
    extraReducers: {
        //list admin complaints
        [adminComplaints.pending]: (state)=>{
            state.loading = true
            state.loader = true
        },
        [adminComplaints.fulfilled]: (state,{payload})=>{
            state.loading = false
            state.loader = false
            state.total = payload.count
            state.complaints = payload.results
        },
        [adminComplaints.rejected]: (state) =>{
          state.loading = false
          state.loader = false
        },
         //list employee complaints
         [employeeComplaints.pending]: (state)=>{
            state.loading = true
            state.loader = true
        },
        [employeeComplaints.fulfilled]: (state,{payload})=>{
            state.loading = false
            state.loader = false
            state.total = payload.count
            state.complaints = payload.results
        },
        [employeeComplaints.rejected]: (state) =>{
          state.loading = false
          state.loader = false
        },
        //retrieve complaint on the admin dashboard
        [retrieveAdminComplaint.pending]: (state)=>{
          state.loading = true
        },
        [retrieveAdminComplaint.fulfilled]: (state,{payload})=>{
            state.loading = false
            state.singleComplaint = payload
        },
        [retrieveAdminComplaint.rejected]: (state) =>{
          state.loading = false
        },
        //retrieve comlaint on the employee dashboard
        [retrieveEmployeeComplaint.pending]: (state)=>{
          state.loading = true
        },
        [retrieveEmployeeComplaint.fulfilled]: (state,{payload})=>{
            state.loading = false
            state.singleComplaint = payload
        },
        [retrieveEmployeeComplaint.rejected]: (state) =>{
          state.loading = false
        },
        //edit complaint
        [editComplaint.pending]: (state) => {
          state.loading = true
        },
        [editComplaint.fulfilled]: (state) => {
          state.loading = false
        },
        [editComplaint.rejected]: (state) => {
          state.loading = true
        }

    }
})

export const { increment, decrement, incrementByAmount} = complaintSlice.actions
  
export default complaintSlice.reducer