import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useReducer,
  useEffect,
} from "react";
import moment from "moment";
import SearchComponent from "../../components/search";
import { debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import PaginationComponent from "../../components/Pagination/Pagination";
import LoadingStateComponent from "../../components/Empty State/loadingstate";
import { pensionSchedule } from "../../redux/Pension/pensionAction";
import { useOutsideAlerter } from "../../helpers/OutsideClick";
import filterIcon from "../../assets/filter_list.svg";
import { KeyboardArrowRight } from "@mui/icons-material";
import ModalComponent from "../../components/Modals/modal";
import EmptyStateComponent from "../../components/Empty State/emptystate";
import cogoToast from "cogo-toast";
import { updatePensionSchedule } from "../../redux/Pension/pensionAction";

const PensionScheduleTable = () => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const dispatch = useDispatch();

  const { loading, schedules, total, remittance_updated } = useSelector((state) => state.pension);

  const [searchValue, setSearchValue] = useState("");
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const onPageSizeChange = (page) => {
    setPageSize(page)
  }

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    if (selectedMonth !== "" && selectedYear === "") {
      dispatch(pensionSchedule({ page: page, month: selectedMonth, year: "", page_size: pageSize }));
    } else if (selectedYear !== "" && selectedMonth === "") {
      dispatch(pensionSchedule({ page: page, year: selectedYear, month: "", page_size: pageSize }));
    } else if (selectedMonth !== "" && selectedYear !== "") {
      dispatch(
        pensionSchedule({
          page: page,
          month: selectedMonth,
          year: selectedYear,
          page_size: pageSize
        })
      );
    } else {
      dispatch(
        pensionSchedule({
          page: page,
          year: selectedYear,
          month: selectedMonth,
          page_size: pageSize
        })
      );
    }
  };
  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
        //console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  //filter
  const [isFilter, setIsFilter] = useState(false);

  const wrapperFilterRef = useRef(null);
  useOutsideAlerter(wrapperFilterRef, setIsFilter);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [clickState, updateClickState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      clickedFilter: "",
    }
  );

  const [allData] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      monthData: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      yearData: [
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
        "2041",
        "2042",
        "2043",
        "2044",
        "2045",
        "2046",
        "2047",
        "2048",
        "2049",
        "2050",
      ],
      duplicatedMonthData: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      duplicatedYearData: [
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
        "2041",
        "2042",
        "2043",
        "2044",
        "2045",
        "2046",
        "2047",
        "2048",
        "2049",
        "2050",
      ],
    }
  );

  const handleSelectedMonth = (data) => {
    setSelectedMonth(data);
  };

  const handleSelectedYear = (data) => {
    setSelectedYear(data);
  };

  const handleFilter = useCallback(() => {
    const monthNumber = moment().month(selectedMonth).format("M");
    if (selectedMonth !== "" && selectedYear !== "") {
      dispatch(
        pensionSchedule({ page: 1, month: monthNumber, year: selectedYear, page_size: pageSize })
      );
    } else if (selectedMonth !== "") {
      dispatch(pensionSchedule({ page: 1, month: monthNumber, year: "", page_size: pageSize }));
    } else if (selectedYear !== "") {
      dispatch(pensionSchedule({ page: 1, year: selectedYear, month: "", page_size: pageSize }));
    } else {
      dispatch(pensionSchedule({ page: 1, month: "", year: "", page_size: pageSize }));
    }
  }, [dispatch, selectedYear, selectedMonth, pageSize]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  //makes an api call to fetch all schedules after a schedule has been updated
  if (remittance_updated) {
    handleFilter();
  }



  //handle modal
  const [voluntaryContribution, setVoluntaryContribution] = useState(false);
  const [insertUpdate, setInsertUpdate] = useState(false);
  const [employeeContribution, setEmployeeContribution] = useState(false);
  const [employerContribution, setEmployerContribution] = useState(false);

  //personalise modal
  //schedule id state

  const [scheduleId, setScheduleId] = useState(0);
  //console.log(schedules)
  let singleSchedule;
  singleSchedule = schedules.find((singleSchedule) => singleSchedule.id === scheduleId);
  const toggleUpdate = (id) => {
    setScheduleId(id);
    //console.log(scheduleId, singleSchedule)
    setInsertUpdate(!insertUpdate);
  };

  const handleUpdateEmployeeSchedule = () => {


    if (!voluntaryContribution || !employerContribution || !employeeContribution) {
      cogoToast.error("Please fill all the fields! If no changes were made, please click the cancel icon");
      return;
    } else {
      const res = {
        id: singleSchedule?.id,
        voluntaryContribution,
        employerContribution,
        employeeContribution,
      };
      dispatch(updatePensionSchedule(res))
      setInsertUpdate(!insertUpdate);
      setEmployeeContribution(0);
      setEmployerContribution(0);
      setVoluntaryContribution(0);
    }
  };



  return (
    <>
      <ModalComponent
        title={`Update ${singleSchedule?.employee.full_name}'s pension schedule`}
        show={insertUpdate}
        showModal={(id) => {
          setInsertUpdate(false);
        }}
        subtitle=""
      >
        <div>
          <div className="flex flex-col  gap-6 mt-4">
            <div className="items-left">
              <p className="text-secondary text-sm font-normal">
                Enter the updated voluntary contribution
              </p>
              <input
                type="text"
                //placeholder="voluntary contribution"
                name="voluntary_contribution"
                defaultValue={singleSchedule?.voluntary_contribution}
                onChange={(e) => setVoluntaryContribution(e.target.value)}
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
            <div className="items-left">
              <p className="text-secondary text-sm font-normal">
                Enter the updated employer contribution
              </p>
              <input
                type="text"
                placeholder="employer contribution"
                name="employerContribution"
                defaultValue={singleSchedule?.employer_contribution}
                onChange={(e) => setEmployerContribution(e.target.value)}
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
            <div className="items-left">
              <p className="text-secondary text-sm font-normal">
                Enter the updated employee contribution
              </p>
              <input
                type="text"
                placeholder="employee contribution"
                defaultValue={singleSchedule?.employee_contribution}
                name="employeeContribution"
                onChange={(e) => setEmployeeContribution(e.target.value)}
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-center align-center items-center ">
            <button
              onClick={handleUpdateEmployeeSchedule}
              disabled={loading}
              className="outline-none rounded-lg disabled:bg-[#ABB6AC]  disabled:text-primary bg-primary text-white py-3 px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
            >
              Generate Updated Schedule
            </button>
          </div>
        </div>
      </ModalComponent>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          <h5 className="text-primary font-semibold text-base leading-5">
            Pension Remittance
          </h5>
          <p className="text-[#7C8D7E] font-medium text-sm">
            This table shows a summary of pension remitted monthly.
          </p>
          {/* search */}
          <div className="mt-4 flex gap-4 ">
            <div className="w-full relative">
              <SearchComponent
                placeholder="Search"
                searchValue={searchValue}
                handleChange={handleChange}
              />
            </div>
            <div className="relative">
              <button
                onClick={() => setIsFilter(!isFilter)}
                className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer"
              >
                <p>Filter</p>
                <img src={filterIcon} alt="filtericon" />
              </button>

              <div className="relative">
                {isFilter && (
                  <div ref={wrapperFilterRef}>
                    <div className="">
                      <div className="bg-white rounded-[8px] p-2 shadow-md w-[211px] absolute right-[15%] cursor-pointer z-20 mt-2 top-11">
                        <div
                          className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                          onClick={() => {
                            updateClickState({ clickedFilter: "Month" });
                          }}
                        >
                          <p>Month</p>
                          <KeyboardArrowRight style={{ fontSize: "20px" }} />
                        </div>
                        <div
                          className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                          onClick={() => {
                            updateClickState({ clickedFilter: "Year" });
                          }}
                        >
                          <p>Year</p>
                          <KeyboardArrowRight style={{ fontSize: "20px" }} />
                        </div>
                      </div>
                    </div>

                    <>
                      {clickState?.clickedFilter === "Year" ? (
                        <div
                          className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-380%] top-[300%] cursor-pointer z-20 mt-2"
                          ref={wrapperFilterRef}
                        >
                          <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
                            <>
                              {allData?.yearData?.map((data, index) => {
                                return (
                                  <div
                                    className="flex gap-4 items-center px-4 py-3"
                                    key={index}
                                  >
                                    <input
                                      name={data}
                                      type="radio"
                                      className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                                      value={data}
                                      onClick={(event) =>
                                        handleSelectedYear(event.target.value)
                                      }
                                      checked={selectedYear === data}
                                    />
                                    <p className="font-medium text-sm leading-5 text-[#344335]">
                                      {data}
                                    </p>
                                  </div>
                                );
                              })}
                            </>
                          </div>
                        </div>
                      ) : clickState?.clickedFilter === "Month" ? (
                        <div
                          className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-380%] top-[300%] cursor-pointer z-20 mt-2"
                          ref={wrapperFilterRef}
                        >
                          <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
                            <div className="mt-2 px-4 py-4 text-sm font-semibold leading-[18px] ">
                              <p>{selectedMonth ? 1 : 0} Selected</p>
                            </div>
                            <>
                              {allData?.monthData?.map((data, index) => {
                                return (
                                  <div
                                    className="flex gap-4 items-center px-4 py-3"
                                    key={index}
                                  >
                                    <input
                                      name={data}
                                      type="radio"
                                      className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                                      value={data}
                                      onClick={(event) =>
                                        handleSelectedMonth(event.target.value)
                                      }
                                      checked={selectedMonth === data}
                                    />
                                    <p className="font-medium text-sm leading-5 text-[#344335]">
                                      {data}
                                    </p>
                                  </div>
                                );
                              })}
                            </>
                          </div>
                        </div>
                      ) : null}
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Schedule Table */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Date
                </th>

                <th scope="col" className="px-4 py-4 font-semibold">
                  Pension No.
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Employee Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Employee ID
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Voluntary
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Employee
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Organisation
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {schedules.length > 0 &&
                  schedules.map((item) => (
                    <tr
                      key={item.id}
                      onClick={() => toggleUpdate(item.id)}
                      className="bg-white text-sm hover:bg-[#EDF7EE] cursor-pointer"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                      >
                        {moment(item.contribution_date).format("MMMM DD, YYYY")}
                      </th>

                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee.pension_number
                          ? item.employee.pension_number
                          : "-"}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee.full_name}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {/* Baba Dudu  */}
                        {item.employee.employee_id}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.voluntary_contribution
                          ? `₦${item.voluntary_contribution?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4">
                        {item.employee_contribution
                          ? `₦${item.employee_contribution?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4">
                        {item.employer_contribution
                          ? `₦${item.employer_contribution?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4">
                        ₦
                        {(
                          parseFloat(item.employee_contribution) +
                          parseFloat(item.employer_contribution) +
                          parseFloat(item.voluntary_contribution)
                        )?.toLocaleString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>

          {loading && <LoadingStateComponent />}
        </div>
      </div>

      {!loading && schedules.length < 1 && (
        <EmptyStateComponent textContent={"Oops! No schedules available"} />
      )}

      {!loading && schedules.length > 0 ? (
        <div className="">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            totalItems={total}
            pageSize={pageSize}
            pageSizeShow
            onPageSizeChange={(page) => onPageSizeChange(page)}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PensionScheduleTable;
