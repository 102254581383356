import React, { useState, useEffect } from 'react'
//import SearchComponent from '../../components/search'
import ModalComponent from '../../components/Modals/modal'
import moment from 'moment'
//import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux'
import { createApprovePayslip, exemptPaye, listPayslipsUnderPayroll, regeneratePayslip } from '../../redux/Payroll/payrollAction'
import LoadingStateComponent from '../../components/Empty State/loadingstate'
import EmptyStateComponent from '../../components/Empty State/emptystate'
import { clearApprovePayslipSuccess, handleApproveAllPayslips, handleCheckedPayslips, handleRegeneratePayslips, clearCheckedPayslips, clearCheckedRegeneratedPayslips } from '../../redux/Payroll/payroll'
import { payslipToEdit } from '../../redux/Calculator/calculator'
import PaginationComponent from '../../components/Pagination/Pagination';

const MonthlyPayrollTable = ({typeID, callTypes, handleType}) => {

    // const [isClickMore, setIsClickMore] = useState(false)
    const [employee, setEmployee] = useState([])
    const [payslipDate, setPayslipDate] = useState(new Date())
    const [oldDate, setOldDate] = useState(new Date())
    const {loading, payslipsUnderPayroll,checkedPayslips, isApproved_success, isRegenerated_success, checkedRegeneratedPayslips, total } = useSelector((state) => state.payroll)
    const dispatch = useDispatch();

    const navigate = useNavigate()

    //get id from url
  const {id} = useParams()

  const payroll_id = parseInt(id, 10) // Convert the id to an integer

    //pagination
    const [currentPage, setCurrentPage] = useState(1)

    const onPageChange = (val) => {
     const { page } = val
     setCurrentPage(page)
     switch (typeID) {
       case 1:
         dispatch(
            listPayslipsUnderPayroll({
             id: payroll_id,
             type: 'all',
             page: page,
           }),
         ) 
         break
       case 2:
         dispatch(
            listPayslipsUnderPayroll({
              id: payroll_id,
             type: 'unapproved',
             page: page,
           })
         ) 
         break
       case 3:
         dispatch(
            listPayslipsUnderPayroll({
             id: payroll_id,
             type: 'approved',
             page: page,
           })
         )
         break
         case 4:
             dispatch(
                listPayslipsUnderPayroll({
                 id: payroll_id,
                 type: 'draft',
                 page: page,
               })
             )
         break
         case 5:
            dispatch(
               listPayslipsUnderPayroll({
                id: payroll_id,
                type: 'exempt',
                page: page,
              })
            )
         break
       default:
         break
     }
   }

  //used to list the payslips under a payroll
    useEffect(() => {
        dispatch(listPayslipsUnderPayroll({id: payroll_id, type: 'all', page: 1}))
    }, [dispatch, payroll_id])

  //used to check if a payslip was appproved successfully, if yes, a refresh of the unapproved 
  //list is initiated
    useEffect(() => {
        if(isApproved_success) {
            dispatch(listPayslipsUnderPayroll({id: payroll_id, type: 'unapproved', page: 1}))

            setTimeout(()=>{
                dispatch(clearApprovePayslipSuccess())
            },500)
        }
    }, [dispatch, isApproved_success, payroll_id]
    )

    //used to check if a payslip was regenerated successfully, if yes, a refresh of the approved 
  //list is initiated
  useEffect(() => {
    if(isRegenerated_success) {
        dispatch(listPayslipsUnderPayroll({id: payroll_id, type: 'approved', page: 1}))

        setTimeout(()=>{
            dispatch(clearApprovePayslipSuccess())
        },500)
    }
}, [dispatch, isRegenerated_success, payroll_id]
)

    // text content of the empty state of all tabs
    const textContent = (id) => {
        let res;
        switch (id) {
            case 1: 
            res= 'Oops! No payslip available'
            break
            case 2: 
            res = 'Oops! No unapproved payslip available.'
            break
            case 3: 
            res = 'Oops! No approved payslip available'
            break
            case 4:
            res = 'Oops! No payslip was saved for later'
            break;
            case 5:
            res = "Oops! No founder's payslip was found"
            break;
            default:
            res = 'Oops! No payslip available.'
            break
        }
        return res;
    }

    //cta on the table for single action for all tabs except "all"
    const actionType = (id) => {
      let action;
        switch (id) {
            case 1: 
            action = `No action`
            break;
            case 2: 
            action = `Approve`
            break;
            case 3: 
            action =  `Regenerate`
            break;
            case 4: 
            action = `Edit Payslip`
            break;
            case 5:
            action = `AddPaye`
            break;
            default: 
            action = 'Approve'
            break;
        }
        return action
    }

    //toggle modal for single action on some tabs
    // const toggleModalType = (id) => {
    //     switch(id) {
    //         case 3: 
    //         handleRegenerateModal()
    //         break;
    //         case 4: 
    //         handleEditPayslip()
    //         default:
    //          handleEditPayslip()
    //         break;
    //     }
    // handleModal

    const handleModalClick = (typeID,item) => {
       let employeeArray = [];
        employeeArray.push(item.employee.id);
        // console.log(item)
        setEmployee(employeeArray)
        setOldDate(item.date_of_entry)
        if (typeID === 2) {
            dispatch(handleCheckedPayslips({
                employee_id: item.employee.id,
                reference: item.payroll_reference
            }))
            handleApproveModal()
        } else if (typeID === 3) {
            handleRegenerateModal(item.date_of_entry)
        } else if (typeID === 4) {
            dispatch(payslipToEdit(item))
            navigate(`edit-payslip/${item.id}`)
        } else if (typeID === 5) {
            dispatch(exemptPaye({
                employee_id: item.employee.id,
                payslip_id: item.id
            }))
            // dispatch(payslipToEdit(item))
            // navigate(`edit-payslip/${item.id}`)
        }   else {
            
        }
    }


    //handle search
    // const [searchValue, setSearchValue] = useState('')


    // // handle search
    // const handleSearch = useMemo(
    //     () =>
    //         debounce((val) => {
    //             //  api to dispatch and make the actions for search
    //             console.log(val);
    //         }, 750),
    //     [],
    // )
    
    // //handle check
    // const handleChange = useCallback(
    //     (e) => {
    //         let val = e.target.value
    //         setSearchValue(val)
    //         handleSearch(val)
    //     },
    //     [handleSearch],
    // )
        //handle the checked boxes and pass information on check from
        // the row info when it is checked - used for Approval
    const handleCheckedBox = (employeeid, ref, val ) =>{
        
        // console.log(ref)
        // console.log(val.)
        let employeeArray = [];
        employeeArray.push(employeeid)
        setEmployee(employeeArray)
        // console.log(typeID)
        if(typeID === 2){
        dispatch(handleCheckedPayslips({
            employee_id: employeeid,
            reference: ref,
        }))
      } else {
         dispatch(handleRegeneratePayslips({
            payroll_id: payroll_id,
            employees: employeeid,
            date_of_entry: moment(val.date_of_entry).format('YYYY-MM-DD'),
            id: val.employee.id
        }))
     }
    }

    //for approving and regenerating all payslips
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    let allPayslips = [];
    const handleSelectAllCheckbox = () => {
        setSelectAllChecked(prevState => !prevState);

        if (!selectAllChecked && typeID === 2){
            allPayslips = payslipsUnderPayroll.map(payslip => ({
                employee_id: payslip.employee.id,
                reference: payslip.payroll_reference, 
            }))
        dispatch(handleApproveAllPayslips(allPayslips))
        }  else if (selectAllChecked && typeID === 2){
            dispatch(clearCheckedPayslips())
        } 
    }

    useEffect(() => {
        // Check if all items are checked, and update selectAllChecked state accordingly
        if(typeID === 2){
            setSelectAllChecked(checkedPayslips.length === payslipsUnderPayroll.length)
        }
        if (checkedPayslips.length === 0 && payslipsUnderPayroll.length === 0){
            setSelectAllChecked(false)
        }
      }, [typeID, checkedPayslips, payslipsUnderPayroll]);


    const handleRegeneratePayslip =  () => {
        //handle single regenerate
        let resp = {
        payroll_id: payroll_id,
        employees: employee,
        date_of_entry: moment(oldDate).format('YYYY-MM-DD'),
        payslip_date_of_entry: moment(payslipDate).format('YYYY-MM-DD'),
        }
        dispatch(regeneratePayslip(resp))
    }
     //functionality for insert date modal
     const [insertDate, setInsertDate] = useState(false);

     //modal for single approval
     const [approvePayslip, setApprovePayslip] = useState(false)

     const handleApproveModal = () => {
        
        setApprovePayslip(true)
     }

     const handleApprovePayslip = () => {
        dispatch(createApprovePayslip({payroll_id}))
        setApprovePayslip(false)
     }

     //regenerate payslip
     
     const handleRegenerateModal = () => {
        setInsertDate(true)
     }

    //cancel approving a payslip
    const cancelCheckedPayslips = () => {
        dispatch(clearCheckedPayslips())
    }
    return (
    <>
        < ModalComponent 
        title= {`Approve Payslip`}
        show={approvePayslip}
        showModal={() => {setApprovePayslip(false);
            cancelCheckedPayslips()}}
        subtitle= {`Are you sure you want to approve this/ these payslips?`}
        >
            <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
                <button
                onClick = {() => {setApprovePayslip(false);
                cancelCheckedPayslips()
            }}
                className="text-sm text-medium leading-5 text-[#5C715E]">No</button>
                <button
                onClick={handleApprovePayslip}
                disabled={loading}
                className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white">
                Yes
                </button>
            </div>
        </ModalComponent>
        <ModalComponent
        title="Regenerate Payslip"
        show={insertDate}
        showModal={() => {setInsertDate(false);
        dispatch(clearCheckedRegeneratedPayslips())}}
        subtitle=""
      >
        <div>
          <div className="flex items-center justify-center gap-6 mt-4">
            <div>
              <p className="text-secondary text-sm font-normal">
                Enter the date of the regenerated payslip
              </p>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                name="payslipDate"
                value= {payslipDate}
                required = {true}
                onChange={(e) => setPayslipDate(e.target.value) }
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-center align-center items-center "
           >
            <button
              className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-3 px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
              onClick={handleRegeneratePayslip}
            >
              Regenerate
            </button>
          </div>
        </div>
      </ModalComponent>
        <div className="mt-4 bg-white rounded-[8px]">
            <div className="p-4">
                {/* search */}
                {/* <div className="mt-4">
                    <SearchComponent
                        placeholder="Search..."
                        searchValue={searchValue}
                        handleChange={handleChange}
                    />
                </div> */}

                <ul className="flex mt-5 gap-5 items-center">
                    {callTypes.map((item) => (
                        <li
                            key={item.id}
                            onClick={() => handleType(item.id, item.tag)}
                            className={
                                typeID === item.id
                                    ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                                    : 'flex gap-2 items-center pb-1 cursor-pointer'
                            }
                        >
                            <p
                                className={
                                    typeID === item.id
                                        ? 'text-primary text-sm font-semibold'
                                        : 'text-[#96A397] text-sm font-semibold'
                                }
                            >
                                {item.title}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>

            {/* tables */}
            <div className="overflow-x-auto relative">
                <table className="w-full text-left">
                    <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                        <tr>
                           { typeID === 2 &&  <th scope="col" className="px-4 py-4 font-semibold ">
                                <input type="checkbox"
                                  checked={selectAllChecked}
                                  onChange={() => handleSelectAllCheckbox()}  />
                            </th> }
                            {typeID === 3 &&  <th scope="col" className="px-4 py-4 font-semibold ">
                                Tick Below</th>}
                            <th scope="col" className="px-4 py-4 font-semibold ">
                                Employee ID
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                                First Name
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                                Last Name
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                                Net Payment
                            </th>
                            <th scope="col" className="px-4 py-4 font-semibold">
                                Payslip Status
                            </th>

                            {typeID !== 1 && <th scope="col" className="px-4 py-4 font-semibold">
                                Action
                            </th> }
                        </tr>
                    </thead>
                    {!loading && (
                        <tbody>
                             {payslipsUnderPayroll.length > 0  && 
                                payslipsUnderPayroll.map((item) => (
                            <tr
                                key={item.id}
                                className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                            >
                               {typeID !== 1 && typeID !== 4  && typeID !== 5 && <th
                                    scope="row"
                                    className="py-4 px-4 font-normal text-[#5C715E]"
                                >
                                    <input type="checkbox"
                                    checked={typeID === 2? checkedPayslips.some(val => val.reference === item.payroll_reference) :  checkedRegeneratedPayslips.some(val => val === item.employee.id)} 
                                     onChange={()=> handleCheckedBox(item.employee.id, item.payroll_reference, item)}
                                     name="approved_payslip_child" />
                                </th>
                                }

                                <td className="py-4 px-4 text-secondary1">
                                    {item.employee? item.employee.employee_id : "N/A" }
                                </td>
                                <td className="py-4 px-4 text-secondary1">
                                    {item.employee? item.employee.first_name : "N/A" }
                                </td>
                                <td className="py-4 px-4 text-secondary1">
                                {item.employee? item.employee.last_name : "N/A" }
                                </td>
                                <td className="py-4 px-4 text-secondary1">
                                    ₦ {item.net_salary? item.net_salary.amount?.toLocaleString() : "N/A" }
                                </td>
                                <td className="py-4 px-4 text-secondary1">
                                    <div className="flex">
                                        <div className= {item.approved? 'success-badge' : 'pending-badge'}>
                                            <p>{item.approved? 'Approved' : 'Unapproved'}</p>
                                        </div>
                                    </div>
                                </td>
                                {typeID !== 1 && 
                                <td className="py-4 px-4 text-secondary1 ">
                                    {/* <div className="flex">
                                        < MoreVert
                                            onClick={() => setIsClickMore(!isClickMore)}
                                            className="text-primary cursor-pointer"
                                            style={{ fontSize: '20px' }}
                                        />
                                    </div> */}
                                    {/* isClick event on MoreVert */}
                                    {/* {isClickMore && (
                                        <>
                                            <div
                                                className="z-10 absolute top-10 right-0 w-[213px] rounded-[8px] bg-white p-[8px] hover:rounded-[6px]"
                                                style={{
                                                    boxShadow:
                                                        '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08)',
                                                }}
                                            >
                                                <div className="mb-[4px] hover:bg-[#EDF7EE] transition-all cursor-pointer">
                                                    <p
                                                        className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]"
                                                    >
                                                        Edit Payslip
                                                    </p>
                                                </div>

                                                <div className=" hover:bg-[#EDF7EE] transition-all cursor-pointer mb-[4px]">
                                                    <p
                                                        className="py-[12px] px-[16px] text-sm font-medium leading-5 text-[#344335]"
                                                    >
                                                        Regenerate Payslip
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    ) */}
                                    <button 
                                    onClick = {() => handleModalClick(typeID,item)}
                                    disabled={typeID === 2? checkedPayslips.length > 1 : checkedRegeneratedPayslips.length > 1}
                                    className = {typeID !== 3 && typeID !== 5? "bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg py-2.5 px-3 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm" : "bg-primary disabled:bg-[#ABB6AC] rounded-lg py-2.5 px-3 text-white border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm" }>
                                        <p>{actionType(typeID)}</p>
                                    </button> 
                                </td>
                                }   
                            </tr>
                            ))
                        }
                        </tbody>
                    )}
                </table>

                {loading && (
                    <LoadingStateComponent/>
                )}
            </div>
        </div>
        {!loading && payslipsUnderPayroll.length < 1 && 
        <EmptyStateComponent textContent= {textContent(typeID)} />
        }
        {!loading && payslipsUnderPayroll.length > 0 ? (
                <div className="">
                    <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange({page})}
                        pageSize={10}
                        totalItems={total}
                    />
                </div>
            ) : (
                ''
            )}
    </>
    );
}

export default MonthlyPayrollTable;