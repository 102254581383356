import React, {
  useState,
  useEffect,
  useCallback,
  // useCallback, useMemo }
} from "react";
import Header from "../components/header";
// import SearchComponent from '../components/search';
// import { debounce } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DrawerComponent from "../components/Drawer/drawer";
import LoadingStateComponent from "../components/Empty State/loadingstate";
import EmptyStateComponent from "../components/Empty State/emptystate";
import { ExpandMore } from "@mui/icons-material";
// import { Form, Formik, Field, ErrorMessage,  useFormikContext  } from "formik";
import {
  adminComplaints,
  employeeComplaints,
  retrieveAdminComplaint,
  retrieveEmployeeComplaint,
  editComplaint,
} from "../redux/Complaints/complaintsAction";
//import { complaintValidator} from '../validationSchema/validator';
import PaginationComponent from "../components/Pagination/Pagination";
import cogoToast from "cogo-toast";

const ComplaintsPage = () => {
  //loading effect on page when calling from api
  const dispatch = useDispatch();

  const { loading, complaints, singleComplaint, total } = useSelector(
    (state) => state.complaints
  );

  const { is_admin } = useSelector((state) => state.auth);
  const [complaintValue, setComplaintValue] = useState(
    singleComplaint.complaint
  );
  const [complaintTitle, setComplaintTitle] = useState(
    singleComplaint.complaint_title
  );

  const complaint_id = singleComplaint.id;

  useEffect(() => {
    if (is_admin) {
      dispatch(
        adminComplaints({
          page: 1,
          type: "all",
        })
      );
    } else {
      dispatch(
        employeeComplaints({
          page: 1,
          type: "all",
        })
      );
    }
  }, [dispatch, is_admin]);

  // handle search
  //  const [searchValue, setSearchValue] = useState("");
  //  const handleSearch = useMemo(
  //     () =>
  //       debounce((val) => {
  //         //  api to dispatch and make the actions for search
  //         console.log(val);
  //       }, 750),
  //     []
  //   );

  // const handleChange = useCallback(
  //   (e) => {
  //     let val = e.target.value;
  //     setSearchValue(val);
  //     handleSearch(val);
  //   },
  //   [handleSearch]
  // );

  const [typeID, setTypeID] = useState(1);

  const complaintTypes = [
    {
      id: 1,
      title: "All",
      tag: "all",
    },
    {
      id: 2,
      title: "Resolved",
      tag: "resolved",
    },
    {
      id: 3,
      title: "Unresolved",
      tag: "unresolved",
    },
  ];

  const toggleType = (id, tag) => {
    setTypeID(id);
    switch (id) {
      case 1:
        is_admin
          ? dispatch(adminComplaints({ page: 1, type: tag }))
          : dispatch(employeeComplaints({ page: 1, type: tag }));
        break;
      case 2:
        is_admin
          ? dispatch(adminComplaints({ page: 1, type: tag }))
          : dispatch(employeeComplaints({ page: 1, type: tag }));
        break;
      case 3:
        is_admin
          ? dispatch(adminComplaints({ page: 1, type: tag }))
          : dispatch(employeeComplaints({ page: 1, type: tag }));
        break;
      default:
        is_admin
          ? dispatch(adminComplaints({ page: 1, type: "all" }))
          : dispatch(employeeComplaints({ page: 1, type: "all" }));
        break;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);
    switch (typeID) {
      case 1:
        is_admin
          ? dispatch(
              adminComplaints({
                page: page,
                tag: "all",
              })
            )
          : dispatch(employeeComplaints({ page: page, type: "all" }));

        break;
      case 2:
        is_admin
          ? dispatch(
              adminComplaints({
                page: page,
                type: "resolved",
              })
            )
          : dispatch(employeeComplaints({ page: page, type: "resolved" }));
        break;
      case 3:
        is_admin
          ? dispatch(
              adminComplaints({
                page: page,
                tag: "unresolved",
              })
            )
          : dispatch(employeeComplaints({ page: page, type: "unresolved" }));
        break;
      default:
        break;
    }
  };

  const textContent = (id) => {
    let res;
    switch (id) {
      case 1:
        res = "Oops! No complaint(s) available";
        break;
      case 2:
        res = "Oops! No resolved complaint(s) available.";
        break;
      case 3:
        res = "Oops! No unresolved complaint(s) available.";
        break;
      default:
    }
    return res;
  };

  //open drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  //close drawer
  const closeComplaint = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  //complaint drawer
  const toggleDrawer = (id) => {
    is_admin
      ? dispatch(retrieveAdminComplaint(id))
      : dispatch(retrieveEmployeeComplaint(id));
    setIsDrawerOpen(!isDrawerOpen);
    let complaint = complaints.find((val) => val.id === id);
    setSelectedStatus(singleComplaint.resolved ? "RESOLVED" : "UNRESOLVED");
    setComplaintValue(complaint.complaint);
    setComplaintTitle(complaint.complaint_title);
  };

  const [selectedStatus, setSelectedStatus] = useState(
    singleComplaint.resolved ? "RESOLVED" : "UNRESOLVED"
  );
  const [clickStatus, setClickStatus] = useState(false);
  const complaintStatus = ["RESOLVED", "UNRESOLVED"];

  const handleComplaintChange = (e) => {
    setComplaintValue(e.target.value);
  };

  const handleSave = useCallback(() => {
    if (complaintValue !== "") {
      dispatch(
        editComplaint({
          id: complaint_id,
          resolved: selectedStatus === "RESOLVED" ? true : false,
          complaint: complaintValue,
        })
      );
    } else {
      cogoToast.error("Please ensure that the complaint field is filled");
    }
  }, [selectedStatus, dispatch, complaint_id, complaintValue]);

  // Update the selectedStatus whenever the singleComplaint changes
  useEffect(() => {
    setSelectedStatus(singleComplaint.resolved ? "RESOLVED" : "UNRESOLVED");
  }, [singleComplaint]);

  return (
    <>
      <DrawerComponent
        title="Complaint Info"
        show={isDrawerOpen}
        showDrawer={closeComplaint}
      >
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Name</h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {singleComplaint && singleComplaint.employee
                  ? singleComplaint.employee.first_name
                  : "N/A"}{" "}
                {singleComplaint && singleComplaint.employee
                  ? singleComplaint.employee.last_name
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Payslip </h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {singleComplaint && singleComplaint.payslip
                  ? moment(singleComplaint.payslip.date_of_entry).format(
                      "MMMM, YYYY"
                    )
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Date Created </h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {singleComplaint.date_created
                  ? moment(singleComplaint.date_created).format("MMMM, YYYY")
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between items-center">
            <div>
              <h6 className="text-xs text-secondary1">Time </h6>
              <p className="font-medium text-secondary1 text-sm mt-1">
                {singleComplaint.date_created
                  ? moment(singleComplaint.date_created)
                      .utcOffset("+01:00")
                      .format("h:mm A")
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>
        {is_admin && (
          <div className="mt-6">
            <h5 className="text-xs text-[#5C715E]">Complaint Status</h5>
            <div className="flex mt-2">
              <div
                className={
                  selectedStatus === "RESOLVED"
                    ? "px-4 success-badge flex gap-5 cursor-pointer items-center"
                    : selectedStatus === "UNRESOLVED"
                    ? "px-4 error-badge flex  gap-5 cursor-pointer items-center"
                    : null
                }
                onClick={() => setClickStatus(!clickStatus)}
              >
                <p className="capitalize">
                  {selectedStatus === "RESOLVED"
                    ? "Resolved"
                    : selectedStatus === "UNRESOLVED"
                    ? "Unresolved"
                    : null}
                </p>
                <ExpandMore
                  className=" cursor-pointer"
                  style={{ fontSize: "18px" }}
                />
              </div>
              {clickStatus && (
                <div
                  className="absolute rounded-[8px] p-[8px] w-auto mt-[7%] bg-white z-10"
                  style={{
                    boxShadow:
                      "0px 0px 1px rgba(0, 0, 0, 0.25), 0px 16px 32px rgba(0, 0, 0, 0.08",
                  }}
                >
                  {complaintStatus.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="px-[16px] py-[12px] hover:bg-[#EDF7EE] cursor-pointer"
                        onClick={() => {
                          setSelectedStatus(data);
                          setClickStatus(false);
                        }}
                      >
                        <p className="font-medium text-sm leading-5 text-[#344335] capitalize">
                          {data}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {!is_admin && (
          <div className="mt-6">
            <div className="flex justify-between items-center">
              <div>
                <h6 className=" text-xs text-secondary1">Complaint Status</h6>
                <div
                  className={
                    singleComplaint.resolved ? "success-badge" : "error-badge"
                  }
                >
                  <p className="font-medium text-secondary1 text-sm mt-1">
                    {singleComplaint.resolved ? "Resolved" : "Unresolved"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-8">
          <label
            htmlFor="title"
            className="block mb-2 text-xs font-medium text-secondary1"
          >
            Complaint Title
          </label>
          <textarea
            id="title"
            rows="1"
            disabled
            value={complaintTitle}
            className="appearance-none w-full
              placeholder:text-[#96A397] placeholder:text-sm
                  text-[#5C715E]
                  text-sm resize-none
                  focus:border-[#2B8C34] focus:outline-none  
                  focus:shadow-input rounded-lg border border-[#96A397]
                  bg-white py-3 px-4"
            placeholder="Complaint TItle"
          ></textarea>
        </div>

        <div className="mt-8">
          <label
            htmlFor="message"
            className="block mb-2 text-xs font-medium text-secondary1"
          >
            Complaint
          </label>
          <textarea
            id="message"
            rows="8"
            disabled={!is_admin}
            value={complaintValue}
            onChange={handleComplaintChange}
            className="appearance-none w-full
              placeholder:text-[#96A397] placeholder:text-sm
                  text-[#5C715E]
                  text-sm resize-none
                  focus:border-[#2B8C34] focus:outline-none  
                  focus:shadow-input rounded-lg border border-[#96A397]
                  bg-white py-3 px-4"
            placeholder="Leave a summary..."
          ></textarea>
        </div>
        {is_admin && (
          <div className="flex justify-between mt-4 items-center">
            <div>
              <button
                onClick={handleSave}
                disabled={loading}
                type="button"
                className="text-white bg-primary py-3 px-6
                          hover:bg-[#24752B] transition-all
                          disabled:bg-[#ABB6AC] font-medium
                         rounded-lg text-sm flex "
              >
                {" "}
                Save
              </button>
            </div>
          </div>
        )}
      </DrawerComponent>
      <Header text="Complaints"></Header>
      <div className="mt-4 bg-white rounded-[8px]">
        <div className="p-4">
          {/* search */}
          {/* <div className="flex gap-4 mt-4">
                        <div className="w-full">
                            <SearchComponent
                                placeholder="Search..."
                                searchValue={searchValue}
                                handleChange={handleChange}   
                            />
                        </div>
                    </div> */}

          <ul className="flex mt-5 gap-5 items-center">
            {complaintTypes.map((item) => (
              <li
                key={item.id}
                onClick={() => toggleType(item.id, item.tag)}
                className={
                  typeID === item.id
                    ? "flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer"
                    : "flex gap-2 items-center pb-1 cursor-pointer"
                }
              >
                <p
                  className={
                    typeID === item.id
                      ? "text-primary text-sm font-semibold"
                      : "text-[#96A397] text-sm font-semibold"
                  }
                >
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>

        {/* tables */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  Date
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Time
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Full Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Payslip Date
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Title of Complaint
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Complaint Status
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {complaints.length > 0 &&
                  complaints.map((item) => (
                    <tr
                      key={item.id}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                      onClick={() => toggleDrawer(item.id)}
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E]"
                      >
                        {moment(item.date_created).format("YYYY-MM-DD")}
                      </th>

                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(item.date_created)
                          .utcOffset("+01:00")
                          .format("h:mm A")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee.first_name} {item.employee.last_name}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {moment(item.payslip.date_of_entry).format("MMMM YYYY")}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.complaint_title}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        <div className="flex">
                          <div
                            className={
                              item.resolved ? "success-badge" : "error-badge"
                            }
                          >
                            <p>{item.resolved ? "resolved" : "unresolved"}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>

          {loading && <LoadingStateComponent />}
        </div>
      </div>
      {!loading && complaints.length < 1 && (
        <EmptyStateComponent textContent={textContent(typeID)} />
      )}
      {!loading && complaints.length > 0 ? (
        <div className="">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={total}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ComplaintsPage;
