import { createSlice } from "@reduxjs/toolkit";
import {
  listPayrolls,
  listPayslipsUnderPayroll,
  listApprovedPayslips,
  createApprovePayslip,
  listApprovedAllowance,
  regeneratePayslip,
  listAllowanceUnderPayroll,
  createApproveAllowance,
  makeAllowancePayment,
  makePayslipPayment,
  exemptPaye
} from "./payrollAction";

// Action types
// const RESET_CHECKED_APPROVED_ALLOWANCE = 'RESET_CHECKED_APPROVED_ALLOWANCE';
// const HANDLE_CHECKED_APPROVED_ALLOWANCE = 'HANDLE_CHECKED_APPROVED_ALLOWANCE';

// Action creators

// export const handleCheckedApprovedAllowanceW = (payload) => ({
//   type: HANDLE_CHECKED_APPROVED_ALLOWANCE,
//   payload,
// });

const initialState = {
  value: 0,
  total: 0,
  loading: false,
  loader: false,
  payrolls: [],
  payslipsUnderPayroll: [],
  approvedPayslips: [],
  approvedAllowance: [],
  checkedPayslips: [],
  checkedAllowance: [],
  checkedApprovedPayslips: [],
  checkedApprovedAllowance: [],
  checkedRegeneratedPayslips: [],
  isApproved_success: false,
  isRegenerated_success: false,
  isProcessing_success: false,
  allowanceUnderPayroll: [],
  allowance_payment_success: false,
  payslip_payment_success: false,
  payslip_payment_processing: false,
  allowance_payment_processing: false,
  isExempt_success: false
};

export const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    //for approving single and multiple payslips
    handleCheckedPayslips: (state, { payload }) => {
      // console.log(payload);
      let res = {
        employee_id: payload.employee_id,
        reference: payload.reference,
      };
      let inSlip = state.checkedPayslips.find(
        (item) => item.reference === payload.reference
      );
      if (inSlip) {
        let resp = state.checkedPayslips.filter(
          (item) => item.reference !== payload.reference
        );
        state.checkedPayslips = resp;
      } else {
        state.checkedPayslips = [...state.checkedPayslips, res];
      }
    },
    //for approving all payslips
    handleApproveAllPayslips: (state, {payload}) => {
      // console.log(payload);
      const payslipsToAdd = Array.isArray(payload) ? payload : [payload];
      const existingPayslipIds = state.checkedPayslips.map(
      (item) => item.reference
    );
    const newPayslips = payslipsToAdd.filter(
      (payslip) => !existingPayslipIds.includes(payslip.reference)
    );
    state.checkedPayslips = [
      ...state.checkedPayslips,
      ...newPayslips,
    ];
        // console.log(state.checkedPayslips);
    },
    //to clear all checked payslips in the checkedPayslips state which would be used for approving payslips
    clearCheckedPayslips: (state) => {
      state.checkedPayslips = [];
    },
    // for approving allowance
    handleCheckedAllowance: (state, { payload }) => {
      // console.log(payload);
      let res = {
        employee_id: payload.employee_id,
        reference: payload.reference,
      };
      let inSlip = state.checkedAllowance.find(
        (item) => item.reference === payload.reference
      );
      if (inSlip) {
        let resp = state.checkedAllowance.filter(
          (item) => item.reference !== payload.reference
        );
        state.checkedAllowance = resp;
      } else {
        state.checkedAllowance = [...state.checkedAllowance, res];
      }
    },
    //for approving all allowance
    handleApproveAllAllowance: (state, {payload}) => {
      // console.log(payload);
      const payslipsToAdd = Array.isArray(payload) ? payload : [payload];
      const existingPayslipIds = state.checkedAllowance.map(
      (item) => item.reference
    );
    const newPayslips = payslipsToAdd.filter(
      (payslip) => !existingPayslipIds.includes(payslip.reference)
    );
    state.checkedAllowance = [
      ...state.checkedAllowance,
      ...newPayslips,
    ];
        // console.log(state.checkedAllowance);
    },
    //to clear all checked payslips in the checkedPayslips state which would be used for approving payslips
    clearCheckedAllowance: (state) => {
      state.checkedAllowance = [];
    },
    //to clear checkedRegeneratedPayslips
    clearCheckedRegeneratedPayslips: (state) => {
      state.checkedRegeneratedPayslips = [];
    },
    //for approved allowance
    //to clear the approve payslip success and ensure the page is refreshed after success
    clearApprovePayslipSuccess: (state) => {
      state.isApproved_success = false;
    },
    //to  clear payslip payment processing once the page is refreshed
    clearPayslipPaymentSuccess: (state) => {
      state.payslip_payment_success = false;
    },
    // clear allowance processing or paid success
    clearAllowancePaymentSuccess: (state) => {
      state.allowance_payment_success = false
    },
    //to clesr all items that were inserted into the state when all checked is unclicked
    clearApprovedAllowance: (state) => {
      state.checkedApprovedAllowance = [];
    },
    //to regenerate payslips on check
    handleRegeneratePayslips: (state, { payload }) => {
      let inSlip = state.checkedRegeneratedPayslips.find(
        (item) => item === payload.id
      );
      if (inSlip) {
        let resp = state.checkedRegeneratedPayslips.filter(
          (item) => item !== payload.id
        );
        state.checkedRegeneratedPayslips = resp;
      } else {
        state.checkedRegeneratedPayslips = [
          ...state.checkedRegeneratedPayslips,
          payload.id,
        ];
      }
    },
    //to refresh page after payslip is regenerated
    clearRegeneratePayslipSuccess: (state) => {
      state.isRegenerate_success = false;
    },
    //for paying all approved payslips
    handlePayAllApprovedPayslips: (state, {payload}) => {
      //console.log(payload);
      const payslipsToAdd = Array.isArray(payload) ? payload : [payload];
      const existingPayslipIds = state.checkedApprovedPayslips.map(
      (item) => item.payslip_id
    );
    const newPayslips = payslipsToAdd.filter(
      (payslip) => !existingPayslipIds.includes(payslip.payslip_id)
    );
    state.checkedApprovedPayslips = [
      ...state.checkedApprovedPayslips,
      ...newPayslips,
    ];
     // console.log(state.checkedApprovedPayslips);
    },
    //to clear all items when the checkbox to pay all payslips is unchecked
    clearApprovedPayslips: (state) => {
      state.checkedApprovedPayslips = [];
    },
    //for making payments both multiple and single payslips
    handleCheckedApprovedPayslips: (state, { payload }) => {
      // console.log(payload)
      let inSlip = state.checkedApprovedPayslips.find(
        (item) => item.payslip_id === payload.payslip_id
      );
      if (inSlip) {
        let resp = state.checkedApprovedPayslips.filter(
          (item) => item.payslip_id !== payload.payslip_id
        );
        state.checkedApprovedPayslips = resp;
      } else {
        state.checkedApprovedPayslips = [...state.checkedApprovedPayslips, payload];
    }
    },
    //for paying all approved allowance on 
    handleCheckedApprovedAllowance: (state, { payload }) => {
      // console.log(payload);
    const payslipsToAdd = Array.isArray(payload) ? payload : [payload];
    const existingPayslipIds = state.checkedApprovedAllowance.map(
    (item) => item.payslip_id
  );
  const newPayslips = payslipsToAdd.filter(
    (payslip) => !existingPayslipIds.includes(payslip.payslip_id)
  );
  state.checkedApprovedAllowance = [
    ...state.checkedApprovedAllowance,
    ...newPayslips,
  ];
      // console.log(state.checkedApprovedAllowance);
    },
    //single and multiple payment of allowance
    handleSingleMultipleAllowancePayment : (state, {payload}) => {
      // console.log(payload)
      let inSlip = state.checkedApprovedAllowance.find(
        (item) => item.payslip_id === payload.payslip_id
      );
      if (inSlip) {
        let resp = state.checkedApprovedAllowance.filter(
          (item) => item.payslip_id !== payload.payslip_id
        );
        state.checkedApprovedAllowance = resp;
      } else {
        state.checkedApprovedAllowance = [...state.checkedApprovedAllowance, payload];

    }
    }
  },
  extraReducers: {
    //  list payroll-------------------- ---------------------------------------------------------------
    [listPayrolls.pending]: (state) => {
      state.loading = true;
    },
    [listPayrolls.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count;
      state.payrolls = payload.results;
     
    },
    [listPayrolls.rejected]: (state) => {
      state.loading = false;
    },
    //list payslips under payroll-------------------- ---------------------------------------------------------------
    [listPayslipsUnderPayroll.pending]: (state) => {
      state.loading = true;
    },
    [listPayslipsUnderPayroll.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count
      state.payslipsUnderPayroll = payload.results;
    },
    [listPayslipsUnderPayroll.rejected]: (state) => {
      state.loading = false;
    },
    //list approved payslips -------------------- ---------------------------------------------------------------
    [listApprovedPayslips.pending]: (state) => {
      state.loading = true;
    },
    [listApprovedPayslips.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count;
      state.approvedPayslips = payload.results;
    },
    [listApprovedPayslips.rejected]: (state) => {
      state.loading = false;
    },
     //list approved allowance -------------------- ---------------------------------------------------------------
     [listApprovedAllowance.pending]: (state) => {
      state.loading = true;
    },
    [listApprovedAllowance.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count
      state.approvedAllowance = payload.results;
    },
    [listApprovedAllowance.rejected]: (state) => {
      state.loading = false;
    },
    // create approved payslip
    [createApprovePayslip.pending]: (state) => {
      state.loading = false;
    },
    [createApprovePayslip.fulfilled]: (state) => {
      state.checkedPayslips = [];
      state.isApproved_success = true;
    },
    [createApprovePayslip.rejected]: (state) => {
      state.loading = false;
    },
    // regenerate an approved payslip
    [regeneratePayslip.pending]: (state) => {
      state.loading = false;
      state.isRegenerated_success = false;
    },
    [regeneratePayslip.fulfilled]: (state) => {
      state.checkedRegeneratedPayslips = [];
      state.isRegenerated_success = true;
    },
    [regeneratePayslip.rejected]: (state) => {
      state.loading = false;
      state.isRegenerated_success = false;
      state.checkedRegeneratedPayslips = [];
    },
    //list allowance under payroll
    [listAllowanceUnderPayroll.pending]: (state) => {
      state.loading = true;
    },
    [listAllowanceUnderPayroll.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count
      state.allowanceUnderPayroll = payload.results;
    },
    [listAllowanceUnderPayroll.rejected]: (state) => {
      state.loading = false;
    },
     // create approved payslip
     [createApproveAllowance.pending]: (state) => {
      state.loading = false;
    },
    [createApproveAllowance.fulfilled]: (state) => {
      state.checkedAllowance = [];
      state.isApproved_success = true;
    },
    [createApproveAllowance.rejected]: (state) => {
      state.loading = false;
    },
    //make payment of allowance
    [makeAllowancePayment.pending]: (state) => {
      state.loading = true;
      state.allowance_payment_success = false
    },
    [makeAllowancePayment.fulfilled]: (state) => {
      state.checkedApprovedAllowance = [];
      state.loading = false;
      state.allowance_payment_success = true;
    },
    [makeAllowancePayment.rejected]: (state) => {
      state.loading = true
      state.allowance_payment_success = false;
    },
    //make payslip payment
    [makePayslipPayment.pending]: (state) => {
      state.loading = true;
      state.payslip_payment_success = false;
    },
    [makePayslipPayment.fulfilled]: (state) => {
      state.checkedApprovedAllowance = [];
      state.loading = false
      state.payslip_payment_success = true;
    },
    [makeAllowancePayment.rejected]: (state) => {
      state.loading = true
      state.payslip_payment_success = false;
    },
    //exempt paye
    [exemptPaye.pending]: (state) => {
      state.loading = false;
    },
    [exemptPaye.fulfilled]: (state) => {
      state.checkedPayslips = [];
      state.isExempt_success = true;
    },
    [exemptPaye.rejected]: (state) => {
      state.loading = false;
    },


  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  handleCheckedPayslips,
  handleApproveAllPayslips,
  clearCheckedPayslips,
  handleApproveAllAllowance,
  clearCheckedAllowance,
  handleCheckedAllowance,
  handleSingleMultipleAllowancePayment,
  handlePayAllApprovedPayslips,
  clearApprovedPayslips,
  handleCheckedApprovedPayslips,
   handleCheckedApprovedAllowance,
  clearApprovePayslipSuccess,
  clearAllowancePaymentSuccess,
  clearPayslipPaymentSuccess,
  clearApprovedAllowance,
  handleRegeneratePayslips,
  // handleRegenerateAllPayslips,
  clearCheckedRegeneratedPayslips,
  clearRegeneratePayslipSuccess,
} = payrollSlice.actions;

export default payrollSlice.reducer;
