import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; //BroswerRouter is used for wrapping diff routes in react-dom
import LoginPage from "./pages/Auth/login";
import ForgotPasswordPage from "./pages/Auth/forgot";
import SetPasswordPage from "./pages/Auth/setpassword";
import SignUpPage from "./pages/Auth/signup";
import CheckMailPage from "./pages/Auth/checkmail";
import EmployeeProtectedRoute from './components/PrivateRoutes/userRoute'
import AdminProtectedRoute from './components/PrivateRoutes/adminRoute'
import SetPasswordSuccessPage from "./pages/Auth/setSuccess";
import PensionPage from "./pages/Admin/pension";
import SideBar from './components/sidebar';
import OverviewPage from './pages/Admin/overview';
import PayrollPage from "./pages/Admin/PayrollPages/payroll";
import EmployeesPage from "./pages/Admin/EmployeePages/employees";
import NotificationsPage from "./pages/Admin/notifications";
import EmployeeProfilePage from "./pages/Admin/EmployeePages/employeeprofile";
import MonthlyPayroll from "./pages/Admin/PayrollPages/monthlypayroll";
import EditEmployeeProfilePage from "./pages/Admin/EmployeePages/editprofile";
import EmployeeDBPayslipPage from "./pages/Employees/Payslips/payslip";
import PayslipCalculatorPage from "./pages/Admin/PayrollPages/payslipcalculator";
import EmployeeDBNotificationsPage from "./pages/Employees/notification";
import EditPayslipPage from "./pages/Admin/PayrollPages/editpayslip";
import ComplaintsPage from "./pages/complaints";
import RecordsPage from "./pages/Admin/records";
import ProfilePage from "./pages/profile";
import AllowancePage from "./pages/Employees/allowance";
import EditAllowancePage from "./pages/Admin/PayrollPages/editallowance";
import AddNewRecordPage from "./pages/Admin/EmployeePages/addNewRecord";
import SetUpAccountPage from "./pages/Auth/setupaccount";
import OverviewUpdatePage from "./pages/Admin/overviewNew";
import AddNewRecord from "./pages/Admin/EmployeePages/newRecordFlow";
//import PensionPayoutPage from "./pages/Admin/PensionPayoutPages";
import PayPartner from "./pages/Admin/PensionPayoutPages/payPartner";
import PageNotFound from "./pages/pagenotfound";

//Router makes it easy to write


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage/>} />
        <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
        <Route path="/resetpassword/" element={<SetPasswordPage />} />
        <Route path="/signup" element={<SignUpPage/>} />
        <Route path="/setup-account" element={<SetUpAccountPage/>} />
        <Route path="/check-email" element={<CheckMailPage/>} />
        <Route path="/reset-success" element={<SetPasswordSuccessPage/>} />
         {/*Add page not found  */}
         <Route path="*" element={<PageNotFound />} />
        {/* Admin main pages */}

        <Route path="/pension" 
        element={<AdminProtectedRoute>
        <SideBar 
        children={<PensionPage/>}
        title="Pension" />
        </AdminProtectedRoute>}

         />
        <Route path="/records" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<RecordsPage/>}
         title="Records" />
        </AdminProtectedRoute>}
         />
         <Route path="/overview" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<OverviewPage/>}
         title="Overview" />
         </AdminProtectedRoute>}
         />
           <Route path="/overview-new" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<OverviewUpdatePage/>}
         title="Overview" />
         </AdminProtectedRoute>}
         />
          <Route path="/view-profile" 
         element={<SideBar
         children={<ProfilePage/>}
         title="Profile" />}
         />
          <Route path="/employees" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<EmployeesPage/>}
         title="Employees" />
         </AdminProtectedRoute>}
         />
         <Route path="/payroll" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<PayrollPage/>}
         title="Payroll" />
        </AdminProtectedRoute>}
         />
         {/*Payroll subpages */}
         <Route path="/monthly-payroll/:id"
         element={<AdminProtectedRoute>
         <SideBar
         children={<MonthlyPayroll/>}
         title="Payslips Under Monthly Payroll" />
         </AdminProtectedRoute>}
         />
          <Route path="/payroll/payslip-calculator" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<PayslipCalculatorPage/>}
         title="Payslip" />
         </AdminProtectedRoute>}
         />

         <Route path="/monthly-payroll/:id/edit-payslip/:id" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<EditPayslipPage/>}
         title="Edit Payslip Page" />
        </AdminProtectedRoute>}/>
          <Route path="/monthly-payroll/:id/edit-allowance/:id" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<EditAllowancePage/>}
         title="Edit Allowance Page" />
        </AdminProtectedRoute>}/>
          <Route path="/notifications" 
         element={
         <SideBar
         children={<NotificationsPage/>}
         title="Notifications" />
        }/>

        {/* Employee subpages */}
        <Route path="/employee/:id" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<EmployeeProfilePage/>}
         title="Employee Profile" />
         </AdminProtectedRoute>}
         />
        <Route path="/edit-employee-profile/:id"
        element={<AdminProtectedRoute>
        <SideBar
        children={<EditEmployeeProfilePage/>}
        title="Edit Employee Profile"/>
        </AdminProtectedRoute>}
        />
        <Route path='/add-employee-record'
        element={
        <SideBar
        children={<AddNewRecordPage/>}
        title="New Employee Record"/>}
        />
        <Route path='/add-new-employee-record'
        element={
        <SideBar
        children={<AddNewRecord/>}
        title="New Employee Record"/>}
        />
        {/* Pension Pay */}
        <Route path="/pension-payout/pay-partner" 
         element={<AdminProtectedRoute>
         <SideBar
         children={<PayPartner/>}
         title="Pay Partner" />
         </AdminProtectedRoute>}
         />
        {/* Employee Dashboard Page */}
        <Route path="/payslip"
        element={<EmployeeProtectedRoute>
        <SideBar
        children={<EmployeeDBPayslipPage/>}
        title="Payslip"/>
        </EmployeeProtectedRoute>
      }
      />
      <Route path="/allowance"
      element={<EmployeeProtectedRoute>
      <SideBar
      children={<AllowancePage/>}
      title="Allowance"/>
      </EmployeeProtectedRoute>
      }
      />

        <Route path="/complaints"
        element={
        <SideBar
        children={<ComplaintsPage/>}
        title="Complaints"/>}
        />
        <Route path="/notification"
        element={
        <SideBar
        children={<EmployeeDBNotificationsPage/>}
        title="Notification"/>}
        />
      </Routes>
    </Router>
  )
}

export default App;
