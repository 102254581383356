import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../config";
import cogoToast from "cogo-toast";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

//get employee account information
export const employeeDropDown = createAsyncThunk(
  "getEmployees",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}auth/employees`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else {
        return rejectWithValue(error.response);
      }
    }
  }
);

//create payslip
export const createPayslip = createAsyncThunk(
  "createPayslip",
//   async ({employees, date_of_entry, gross_payable, taxable_income, basic_salary, housing_allowance, transport_allowance, leave_allowance, utility_allowance, training_allowance,entertainment_allowance, taxable, paye,
//     employee_pension, hmo, total_deductions, salary_advance, loan_repayment, arrears, bonus, net_salary}, { rejectWithValue, dispatch }) => {
    async (response, { rejectWithValue, dispatch }) => {
        //console.log(response )
    try {
      const res = await axios.post(
        `${apiUrl}payslip/create_payslip/`,
        response
        ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (res.status === 201) {
        cogoToast.success(`Payslip created successfully!`);
        return res.data;
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: "auth/Logout" });
      } else if (error.response.status === 400){
        cogoToast.error(`${error.response.data.detail}` || `${error.response.data.error}`)
      } else{
        cogoToast.error(`${error.response.data.detail}`);
        return rejectWithValue(error.response);
      }
    }
  }
);

//create allowance 
export const createAllowance = createAsyncThunk(
    "createAllowance",
  //   async ({employees, date_of_entry, gross_payable, taxable_income, basic_salary, housing_allowance, transport_allowance, leave_allowance, utility_allowance, training_allowance,entertainment_allowance, taxable, paye,
  //     employee_pension, hmo, total_deductions, salary_advance, loan_repayment, arrears, bonus, net_salary}, { rejectWithValue, dispatch }) => {
      async (response, { rejectWithValue, dispatch }) => {
          // console.log(response )
      try {
        const res = await axios.post(
          `${apiUrl}payslip/create_allowance/`,
          response
          ,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        if (res.status === 201) {
          cogoToast.success(`Allowance has been saved successfully!`);
          return res.data;
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch({ type: "auth/Logout" });
        } else if (error.response.status === 400){
          cogoToast.error(`${error.response.data.detail}` || `${error.response.data.error}` || `${error.response.detail}`)
          cogoToast.error(`${error}`);
          return rejectWithValue(error.response.data.error);
        }
      }
    }
  );

  //make allowance payment
  export const makeAllowancePayment = createAsyncThunk (
    'makeAllowancePayment',
  async (paymentRes, { rejectWithValue, dispatch }) => {
    // console.log(paymentRes )
try {
  const res = await axios.post(
    `${apiUrl}payslip/allowance/pay/`,
    paymentRes
    ,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  if (res.status === 201) {
    cogoToast.success(`Allowance Payment is processing!`);
    return res.data;
  }
} catch (error) {
  if (error.response.status === 401) {
    dispatch({ type: "auth/Logout" });
  } else if (error.response.status === 400){
    cogoToast.error(`${error.response.data.detail}` || `${error.response.data.error}` || `${error.response.detail}`)
  } else {
    cogoToast.error(`${error}`);
    return rejectWithValue(error.response);
  }
}
}
  );


//edit payslip
export const editPayslip = createAsyncThunk (
    'editPayslip',
  async ({response, ref}, { rejectWithValue, dispatch }) => {
    // console.log(response )
try {
  const res = await axios.patch(
    `${apiUrl}payslip/${ref}/edit/`,
    response
    ,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  if (res.status === 200) {
    cogoToast.success(res.data.message);
    return res.data;
  }
} catch (error) {
  if (error.response.status === 401) {
    dispatch({ type: "auth/Logout" });
  } else if (error.response.status === 400){
    cogoToast.error(`${error.response.data.detail}` || `${error.response.data.error}` || `${error.response.detail}`)
  } else {
    cogoToast.error(`${error}`);
    return rejectWithValue(error.response);
  }
}
}
  );



