import React, { useState, useEffect } from 'react'
import { getDepartments, getRoles,
//getEmploymentType
} from '../../../redux/Auth/authAction'
import { addNewRecord } from '../../../redux/Employee/employeeAction'
import GoBack from '../../../components/goBack'
import Header from '../../../components/header'
import Select from "react-select"
import { useSelector, useDispatch } from 'react-redux'


const AddNewRecordPage = () => {
    const dispatch = useDispatch();

    useEffect(()=>{
      dispatch(getRoles())
      //dispatch(getEmploymentType())
    }, [dispatch])
    
    useEffect(()=>{
      dispatch(getDepartments())
    }, [dispatch])

    

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        middleName: "",
        employeeID: "",
        employeeType: "",
        pensionID: "",
        email: "",
    })
    const { roles, departments, loading } = useSelector(state => state.auth)
    const { record_success } = useSelector(state => state.employee)
    const [role, setRole] = useState("");
    const [roleSlug, setRoleSlug] = useState("")
    const [department, setDepartment] = useState("")

   
     //working with select for roles
 const handleRole = (val) => {
    const { role_slug, role_name} = val;
    setRoleSlug(role_slug)
    setRole(role_name)
    console.log(role)
  };
  
  //working select for departments
  const handleDepartment = (val) => {
      let dept;
    if (typeof val === 'object' && val !== null) {
        const { department_name} = val;
        dept = `${department_name}`;
      }
    setDepartment(dept)
  }
  function changeHandler(e) {
    //the form data variable is updated
    //it accepts a shallow clone of the previous state of the formData object
    //the bracket notation lets you se the value of e dynamically
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

      const handleSaveChanges = () => {
        let data;
        data = {
            staff_id: formData.employeeID,
            last_name: formData.lastName,
            first_name: formData.firstName,
            middle_name: formData.middleName,
            email: formData.email,
            role: roleSlug,
            team: department,
            employee_type: formData.employeeType,
            pension_id: formData.pensionID
        }
        dispatch(addNewRecord({data}))
      }

      useEffect(() => {
          if (record_success) {
            setFormData({
                firstName: "",
                lastName: "",
                middleName: "",
                employeeID: "",
                employeeType: "",
                pensionID: "",
                email: "",
          })
          setRoleSlug("")
          setDepartment("")
          setRole("")
        }
        
    }, [record_success])

  return (
    <div>
        <GoBack/>
        <Header text={'New Employee Record'} />
        <div className="w-full h-full px-4 mt-4 bg-white rounded-[8px] py-8">
        <h5 className="text-[#ABB6AC] font-semibold text-sm leading-[18px] pt-3 pb-4">
          Employee Details
        </h5>
        <div className="flex gap-6">
          <div className="w-1/3">
            <div>
              <p className="font-[400] text-[14px] leading-[18px] text-secondary">
                Employee ID
              </p>
              <input
                name="employeeID"
                type="text"
                // value={employeeID}
                value={formData.employeeID}
                // onChange={(event) => setEmployeeID(event.target.value)}
                onChange={changeHandler}
                className="outline-0 h-[44px] bg-[#F7F7F7]  rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Employee ID"
              />
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                Middle Name
              </h6>
              <input
                name="middleName"
                type="text"
                // value={midName}
                value = {formData.middleName}
                onChange={changeHandler}
                className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Middle Name"
              />
            </div>
            <div className="mt-1 mb-1">
              <h6 className="font-normal text-sm leading-[18px] text-secondary ">
                Department
              </h6>
                    <Select
                      onChange={handleDepartment}
                      options={departments}
                      name="department"
                      placeholder="Select Department"
                      className="select_style"
                      classNamePrefix="select_control"
                      getOptionLabel={options => options.department_name}
                      getOptionValue={options => options.id}
                    ></Select>
            </div>
          </div>
          <div className="w-1/3">
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                First Name
              </h6>
              <input
                type="text"
                name='firstName'
                value={formData.firstName}
                onChange={changeHandler}
                // onChange={(e) => {
                //     setValue(e.target.value)
                // }}
                className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="First Name"
              />
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                Email Address
              </h6>
              <input
                type="text"
                name='email'
                value={formData.email}
                onChange = {changeHandler}
                className="outline-0 h-[44px] bg-[#F7F7F7]  rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Email Address"
                //onChange={(event) => {setEmail= event.target.value}}
              />
            </div>
            <div>
              <p className="font-[400] text-[14px] leading-[18px] text-secondary">
                Employee Type
              </p>
              <input
                type="text"
                name='employeeType'
                value={formData.employeeType}
                onChange={changeHandler}
                className="outline-0 h-[44px] bg-[#F7F7F7] rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Employee Type"
              />
            </div>
          </div>
          <div className="w-1/3">
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary">
                Last Name
              </h6>
              <input
                type="text"
                name='lastName'
                value={formData.lastName}
                onChange = {changeHandler}
                className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Last Name"
              />
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary mb-1">
                Role
              </h6>
                    <Select
                      onChange={handleRole}
                      options={roles}
                      name="role"
                      placeholder="Select Role"
                      className="select_style"
                      classNamePrefix="select_control"
                      getOptionLabel={options => options.role_name}
                      getOptionValue={options => options.id}
                    ></Select>
            </div>
            <div>
              <h6 className="font-normal text-sm leading-[18px] text-secondary mt-5">
                Pension ID
              </h6>
              <input
                type="text"
                name='pensionID'
                value= {formData.pensionID}
                onChange={changeHandler}
                className="outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] py-[12px] px-[14px] w-full font-medium text-sm text-secondary mb-[24px]"
                placeholder="Pension ID"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-6 mt-6">
        <div>
          <button 
          onClick={handleSaveChanges}
          disabled={loading}
          className="outline-none disabled:bg-[#ABB6AC]  bg-primary border border-primary text-white font-medium text-sm leading-5 py-[10px] px-[20px] rounded-[8px] hover:bg-primaryhover hover:border hover:border-primary hover:text-white transition ease-in-out delay-100">
            Send Invite
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddNewRecordPage;
