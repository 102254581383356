import React, { useState } from 'react'
import Header from '../../components/header';
import { useDispatch, useSelector } from "react-redux";
import {  downloadPensionSchedule} from '../../redux/Pension/pensionAction';
import PensionScheduleTable from '../../Tables/Pension Tables/schedule';
import PensionPayoutTable from '../../Tables/Pension Tables/payout';
import ModalComponent from '../../components/Modals/modal';
import moment from 'moment';
const PensionPage = () => {
    const { loading } = useSelector(
        (state) => state.pension
      );

    const [tab, setTab] = useState("Schedule"); 
    const dispatch = useDispatch();
    


  //logic for the modal component to download a schedule
  const [insertDate, setInsertDate] = useState(false);
  const [contributionDate, setContributionDate] = useState(false);

  const toggleInsertDate = () => {
    setInsertDate(!insertDate);
  }; 

  const handleDownloadSchedule = () => {
    setInsertDate(false);
    dispatch(downloadPensionSchedule({
      contribution_date: moment(contributionDate).format("YYYY-MM-DD"),

    }))
  
  };

    return (
        <>
            <ModalComponent
        title="Download Pension Schedule"
        show={insertDate}
        showModal={() => {setInsertDate(false);
        }}
        subtitle=""
      >
        <div>
          <div className="flex flex-col  gap-6 mt-4">
            <div className='items-left'>
              <p className="text-secondary text-sm font-normal">
                Enter the date of schedule to be downloaded
              </p>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                name="payslipDate"
                value= {contributionDate}
                onChange={((e) => setContributionDate(e.target.value))}
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-center align-center items-center "
           >
            <button
             onClick={handleDownloadSchedule}
              className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-3 px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
            >
              Download
            </button>
          </div>
        </div>
      </ModalComponent>
            <Header
                text="Pension"
                tabs={
                  <div className="flex gap-[16px] mt-[16px]">
                    <p
                      className={
                        tab === "Schedule"
                          ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                          : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                      }
                      onClick={() => setTab("Schedule")}
                    >
                      Schedule
                    </p>
                    <p
                      className={
                        tab === "Payout"
                          ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                          : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
                      }
                      onClick={() => setTab("Payout")}
                    >
                      Payout
                    </p>
                  </div>
                }
            >
                  <div className="flex flex-row gap-4">
               <button
                      type="submit"
                      disabled={loading}//hover color is #24752B
                      className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-2 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                    onClick={() => {toggleInsertDate()}}
                  >
                    Download Schedule         
                  </button>
            </div>  
            </Header>
            {tab === "Schedule" && 
            (
            // <div className="mt-4 bg-white rounded-[8px]">
            //     <div className="p-4">
            //         <h5 className="text-primary font-semibold text-base leading-5">
            //             Pension Remittance
            //         </h5>
            //         <p className="text-[#7C8D7E] font-medium text-sm">
            //             This table shows a summary of pension remitted monthly.
            //         </p>
            //         {/* search */}
            //         <div className="mt-4 flex gap-4 ">
            //             <div className="w-full relative">
            //                 <SearchComponent
            //                     placeholder="Search"
            //                     searchValue={searchValue}
            //                     handleChange={handleChange}
            //                 />
            //             </div>
            //             <div className="relative">
            //     <button
            //       onClick={() => setIsFilter(!isFilter)}
            //       className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer"
            //     >
            //       <p>Filter</p>
            //       <img src={filterIcon} alt="filtericon" />
            //     </button>


            //     <div className="relative">
            //     {isFilter && (
            //       <div ref={wrapperFilterRef}>
            //         <div className="">
            //           <div className="bg-white rounded-[8px] p-2 shadow-md w-[211px] absolute right-[15%] cursor-pointer z-20 mt-2 top-11">
            //             <div
            //               className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
            //               onClick={() => {
            //                 updateClickState({ clickedFilter: "Month" });
            //               }}
            //             >
            //               <p>Month</p>
            //               <KeyboardArrowRight style={{ fontSize: "20px" }} />
            //             </div>
            //             <div
            //               className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
            //               onClick={() => {
            //                 updateClickState({ clickedFilter: "Year" });
            //               }}
            //             >
            //               <p>Year</p>
            //               <KeyboardArrowRight style={{ fontSize: "20px" }} />
            //             </div>
            //           </div>
            //         </div>

            //         <>
            //           {clickState?.clickedFilter === "Year" ? (
            //             <div
            //               className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-380%] top-[300%] cursor-pointer z-20 mt-2"
            //               ref={wrapperFilterRef}
            //             >
            //               <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
            //                 <>
            //                   {allData?.yearData?.map((data, index) => {
            //                     return (
            //                       <div
            //                         className="flex gap-4 items-center px-4 py-3"
            //                         key={index}
            //                       >
            //                         <input
            //                           name={data}
            //                           type="radio"
            //                           className="accent-primary h-[14px] w-[14px] rounded-[2px]"
            //                           value={data}
            //                           onClick={(event) =>
            //                             handleSelectedYear(event.target.value)
            //                           }
            //                           checked={selectedYear === data}
            //                         />
            //                         <p className="font-medium text-sm leading-5 text-[#344335]">
            //                           {data}
            //                         </p>
            //                       </div>
            //                     );
            //                   })}
            //                 </>
            //               </div>
            //             </div>
            //           ) : clickState?.clickedFilter === "Month" ? (
            //             <div
            //               className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-380%] top-[300%] cursor-pointer z-20 mt-2"
            //               ref={wrapperFilterRef}
            //             >
            //               <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
            //                 <div className="mt-2 px-4 py-4 text-sm font-semibold leading-[18px] ">
            //                   <p>{selectedMonth ? 1 : 0} Selected</p>
            //                 </div>
            //                 <>
            //                   {allData?.monthData?.map((data, index) => {
            //                     return (
            //                       <div
            //                         className="flex gap-4 items-center px-4 py-3"
            //                         key={index}
            //                       >
            //                         <input
            //                           name={data}
            //                           type="radio"
            //                           className="accent-primary h-[14px] w-[14px] rounded-[2px]"
            //                           value={data}
            //                           onClick={(event) =>
            //                             handleSelectedMonth(event.target.value)
            //                           }
            //                           checked={selectedMonth === data}
            //                         />
            //                         <p className="font-medium text-sm leading-5 text-[#344335]">
            //                           {data}
            //                         </p>
            //                       </div>
            //                     );
            //                   })}
            //                 </>
            //               </div>
            //             </div>
            //           ) : null}
            //         </>
            //       </div>
            //     )}
            //   </div>
            //   </div>
            //         </div>
                    
            //     </div>

            //     {/* Pension Table */}
            //     <div className="overflow-x-auto relative">
            //         <table className="w-full text-left">
            //             <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
            //                 <tr>
            //                     <th scope="col" className="px-4 py-4 font-semibold ">
            //                         Date
            //                     </th>

            //                     <th scope="col" className="px-4 py-4 font-semibold">
            //                         Pension No.
            //                     </th>
            //                     <th scope="col" className="px-4 py-4 font-semibold">
            //                         Employee Name
            //                     </th>
            //                     <th scope="col" className="px-4 py-4 font-semibold">
            //                         Voluntary
            //                     </th>
            //                     <th scope="col" className="px-4 py-4 font-semibold">
            //                         Employee
            //                     </th>
            //                     <th scope="col" className="px-4 py-4 font-semibold">
            //                         Organisation
            //                     </th>
            //                     <th scope="col" className="px-4 py-4 font-semibold">
            //                         Total
            //                     </th>
            //                 </tr>
            //             </thead>
            //             {!loading && (
            //                 <tbody>
            //                     {pension.length > 0 && pension.map((item) => (
            //                     <tr
            //                         key = {item.id}
            //                         className="bg-white text-sm hover:bg-[#EDF7EE]"
            //                     >
            //                         <th
            //                             scope="row"
            //                             className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
            //                         >
            //                             {moment(item.date_of_entry).format("MMMM DD, YYYY")}
            //                         </th>

            //                         <td className="py-4 px-4 text-[#5C715E]">
            //                             {/* PEN123456789 */}
            //                              {item.employee.pension_number? item.employee.pension_number : "-"}
            //                         </td>
            //                         <td className="py-4 px-4 text-[#5C715E]">
            //                            {/* Baba Dudu  */}
            //                             {item.employee.employee_name}
            //                         </td>
            //                         <td className="py-4 px-4 text-[#5C715E]">
            //                             {item.employee_contribution? `₦${item.employee_contribution?.toLocaleString()}`: 0}
            //                         </td>
            //                         <td className="py-4 px-4">
            //                             {item.employee_pension? `₦${item.employee_pension?.toLocaleString()}` : 0}
            //                         </td>
            //                         <td className="py-4 px-4">
            //                             {item.organization_pension? `₦${item.organization_pension?.toLocaleString()}` : 0}
            //                         </td>
            //                         <td className="py-4 px-4">
            //                         ₦{(parseFloat(item.employee_pension) + parseFloat(item.organization_pension))?.toLocaleString()}

            //                         </td>
            //                     </tr>
            //                       ))}
            //                 </tbody>
            //             )}
            //         </table>

            //         {loading && ( <LoadingStateComponent/>)}
            //     </div>
            // </div> 
            <PensionScheduleTable/>
           )} 
           
           {tab === 'Payout' && (
             <PensionPayoutTable/>
           )}
        </>
    );
}

export default PensionPage;