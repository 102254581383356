import { createSlice } from '@reduxjs/toolkit'
import { employeeDropDown, createPayslip, editPayslip } from './calculatorAction'

const initialState = {
    value: 0,
    loading: false,
    loader: false,
    employees: [],
    payslipEdit: {},
    create_payslip_success: false,
    edit_payslip_success: false,
    edit_allowance_success: false
}

export const payslipCalculatorSlice = createSlice({
    name: 'payslipCalculator',
    initialState,
    reducers: {
      increment: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.value += 1
      },
      decrement: (state) => {
        state.value -= 1
      },
      incrementByAmount: (state, action) => {
        state.value += action.payload
      },
      payslipToEdit: (state, payload) => {
         state.payslipEdit = payload.payload
      },
      allowanceToEdit: (state, payload) => {
        state.allowanceEdit = payload.payload
      },

    },
    extraReducers: {
      //GET ACTIVE EMPLOYEES
      [employeeDropDown.pending]: (state)=>{
        state.loading = true
      },
      [employeeDropDown.fulfilled]: (state,{payload})=>{
          state.loading = false
          state.employees = payload.data
      },
      [employeeDropDown.rejected]: (state) =>{
        state.loading = false
      },
      //create payslip
      [createPayslip.pending]: (state)=> {
        state.loading = true
      },
      [createPayslip.fulfilled]: (state) => {
        state.loading = false
        state.create_payslip_success = true
     
      },
      [createPayslip.rejected]: (state) => {
        state.loading = false
        state.create_payslip_success = false
        state.isPayslipSuccessful = false
      },
      //edit payslip
      [editPayslip.pending]: (state) => {
        state.loading = true
        state.edit_payslip_success = false
        state.edit_alllowance_succcess = false
      },
      [editPayslip.success]: (state) => {
        state.loading = false
        state.edit_payslip_success = true
        state.edit_allowance_success = true
      },
      [editPayslip.rejected]: (state) => {
        state.loading = true
        state.edit_payslip_success = false
        state.edit_allowance_success = false
      },
    } 
  })
  
  // Action creators are generated for each case reducer function
  export const { increment, decrement, incrementByAmount,  payslipToEdit, allowanceToEdit } = payslipCalculatorSlice.actions
  
  export default payslipCalculatorSlice.reducer