import React, {
    useState, useMemo, useCallback
    , useEffect
} from 'react'
import SearchComponent from '../../components/search'
import moment from 'moment'
import { debounce } from 'lodash'
import ModalComponent from '../../components/Modals/modal'
import LoadingStateComponent from '../../components/Empty State/loadingstate'
import EmptyStateComponent from '../../components/Empty State/emptystate'
import {
    useSelector,
    useDispatch
} from 'react-redux'
import { listApprovedAllowance, makeAllowancePayment } from '../../redux/Payroll/payrollAction'
import { clearAllowancePaymentSuccess, handleCheckedApprovedAllowance, clearApprovedAllowance, handleSingleMultipleAllowancePayment } from '../../redux/Payroll/payroll'
import PaginationComponent from '../../components/Pagination/Pagination'
import cogoToast from 'cogo-toast'


const ApprovedAllowanceTable = () => {
    const [typeID, setTypeID] = useState(1)

    //keep track of checked state of checkedboxees
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const dispatch = useDispatch();
    const { loading, approvedAllowance, checkedApprovedAllowance, allowance_payment_succcess, total } = useSelector((state) => state.payroll)

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);

    const onPageSizeChange = (page) => {
        setPageSize(page)
    }

    const onPageChange = (val) => {
        const { page } = val
        setCurrentPage(page)
        switch (typeID) {
            case 1:
                dispatch(
                    listApprovedAllowance({
                        type: 'all',
                        page: page,
                        page_size: pageSize
                    }),
                )
                break
            case 2:
                dispatch(
                    listApprovedAllowance({
                        type: 'pending',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            case 3:
                dispatch(
                    listApprovedAllowance({
                        type: 'processing',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            case 4:
                dispatch(
                    listApprovedAllowance({
                        type: 'completed',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            case 5:
                dispatch(
                    listApprovedAllowance({
                        type: 'failed',
                        page: page,
                        page_size: pageSize
                    })
                )
                break
            default:
                break
        }
    }

    useEffect(() => {
        dispatch(listApprovedAllowance({ type: 'all', page: 1,  page_size: pageSize }))
    }, [dispatch, pageSize])

    const callTypes = [
        {
            id: 1,
            title: 'All',
            tag: 'all'
        },
        {
            id: 2,
            title: 'Pending',
            tag: 'pending'
        },
        {
            id: 3,
            title: 'Processing',
            tag: 'processing'
        },
        {
            id: 4,
            title: 'Completed',
            tag: 'completed'
        },
        {
            id: 5,
            title: 'Failed',
            tag: 'failed'
        }

    ]

    const toggleType = (id, tag) => {
        setTypeID(id)
        switch (id) {
            case 1:
                dispatch(listApprovedAllowance({ type: tag, page: 1,   page_size: pageSize }))
                break;
            case 2:
                dispatch(listApprovedAllowance({ type: tag, page: 1,   page_size: pageSize }))
                break;
            case 3:
                dispatch(listApprovedAllowance({ type: tag, page: 1,   page_size: pageSize }))
                break;
            case 4:
                dispatch(listApprovedAllowance({ type: tag, page: 1,   page_size: pageSize }))
                break;
            case 5:
                dispatch(listApprovedAllowance({ type: tag, page: 1,   page_size: pageSize }))
                break;
            default:
                dispatch(listApprovedAllowance({ type: 'all', page: 1,   page_size: pageSize }))
                break;
        }
    }

    const getPaymentStatusBg = (val) => {
        let result;
        switch (val) {
            case 'FAILED':
                result = 'error-badge'
                break
            case 'PROCESSING':
                result = 'dispatched-badge'
                break
            case 'COMPLETED':
                result = 'success-badge'
                break
            case 'PENDING':
                result = 'pending-badge'
                break
            case 'DONE':
                result = 'done-badge'
                break
            case 'PAY':
                result = 'pay-badge'
                break
            default:
                result = 'dispatched-badge'
                break
        }
        return result
    }

    const getPaymentStatusText = (val) => {
        let result
        switch (val) {
            case 'PROCESSING':
                result = 'Processing'
                break
            case 'FAILED':
                result = 'Failed'
                break
            case 'PENDING':
                result = 'Pending'
                break
            case 'COMPLETED':
                result = 'Completed'
                break
            default:
                result = 'Processing'
                break
        }
        return result
    }

    const getApprovedAllowanceActionText = (val) => {
        let result
        switch (val) {
            case 'PAY':
                result = 'Pay'
                break
            case 'DONE':
                result = 'Paid'
                break
            default:
                result = 'Pay'
                break
        }
        return result
    }

    // handle search
    const [searchValue, setSearchValue] = useState('')
    const handleSearch = useMemo(
        () =>
            debounce((val) => {
                //  api to dispatch and make the actions for search
                //console.log(val);
            }, 750),
        [],
    )

    const handleChange = useCallback(
        (e) => {
            let val = e.target.value
            setSearchValue(val)
            handleSearch(val)
        },
        [handleSearch],
    )
    const [isProcessPayment, setIsProcessPayment] = useState(
        false
    )

    const toggleModal = () => {
        // if (checkedApprovedPayslips.length > 0) {
        //     setIsProcessPayment(!isProcessPayment);
        //   }
        // setIsProcessPayment(!isProcessPayment)
        if (checkedApprovedAllowance.length === 0) {
            // Bulk payment scenario
            setIsProcessPayment(prevState => !prevState);
            // Make the bulk payment API call here

        } else {
            // Single or multiple payment scenario
            setIsProcessPayment(prevState => !prevState);
            // Make the payment API call for the selected payslips here

        }

    }

    const textContent = (id) => {
        let res;
        switch (id) {
            case 1:
                res = 'Oops! No allowance available'
                break;
            case 2:
                res = 'Oops! No pending allowance(s) available.'
                break;
            case 3:
                res = 'Oops! No processing allowance(s) available.'
                break;
            case 4:
                res = 'Oops! No completed allowance(s) available.'
                break;
            case 5:
                res = 'Oops! No failed allowance(s) available.'
                break;
            default:
        }
        return res;
    }

    const handleCheckedBox = useCallback((employeeID, payslipID, dateOfEntry) => {
        dispatch(
            handleSingleMultipleAllowancePayment({
                id: employeeID,
                payslip_id: payslipID,
                date_of_entry: moment(dateOfEntry).format('YYYY-MM-DD'),
            })
        );
    }, [dispatch]);

    let allPayslips = [];
    const handleSelectAllCheckbox = () => {
        setSelectAllChecked(prevState => !prevState);

        if (!selectAllChecked) {
            allPayslips = approvedAllowance.map(payslip => ({
                id: payslip.employee.id,
                payslip_id: payslip.id,
                date_of_entry: moment(payslip.date_of_entry).format('YYYY-MM-DD')
            }));
            dispatch(handleCheckedApprovedAllowance(allPayslips));
        } else {
            dispatch(clearApprovedAllowance())
        }
    }

    //function to handle modal and approved checked box
    const handleModalClick = (employee, id, entryDate) => {
        if (checkedApprovedAllowance.length > 0) {
            cogoToast.error("Kindly use the 'Pay All' button to handle multiple payments simultaneously!")
        } else {
            dispatch(handleCheckedApprovedAllowance({
                id: employee,
                payslip_id: id,
                date_of_entry: moment(entryDate).format("YYYY-MM-DD")
            }));
            toggleModal()
        }
    }

    //ensure checked box is unchecked when payment modal is cta(no) or cancelled
    const cancelCheckedApprovedAllowance = () => {
        dispatch(clearApprovedAllowance())
    }

    useEffect(() => {
        if (typeID === 2 && allowance_payment_succcess) {
            dispatch(listApprovedAllowance({ type: 'pending', page: 1 }))
            setTimeout(() => {
                dispatch(clearAllowancePaymentSuccess())
                dispatch(clearApprovedAllowance())
            }, 500)
        }
        else if (typeID === 4 && allowance_payment_succcess) {
            dispatch(listApprovedAllowance({ type: 'failed', page: 1 }))
            setTimeout(() => {
                dispatch(clearAllowancePaymentSuccess())
                dispatch(clearApprovedAllowance())
            }, 500)
        }
    }, [dispatch, typeID, allowance_payment_succcess]
    )

    useEffect(() => {
        // Check if all items are checked, and update selectAllChecked state accordingly
        setSelectAllChecked(checkedApprovedAllowance.length === approvedAllowance.length);


        if (checkedApprovedAllowance.length === 0 && approvedAllowance.length === 0) {
            setSelectAllChecked(false)
        }
    }, [checkedApprovedAllowance, approvedAllowance]);



    return (
        <>
            < ModalComponent
                title="Process Payment"
                show={isProcessPayment}
                showModal={() => {
                    setIsProcessPayment(false);
                    cancelCheckedApprovedAllowance()
                }}
                subtitle="Are you sure you sure you want to pay?"
            >
                <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
                    <button
                        onClick={() => {
                            setIsProcessPayment(false);
                            cancelCheckedApprovedAllowance()
                        }}
                        className="text-sm text-medium leading-5 text-[#5C715E]">No</button>
                    <button
                        className="outline-0 bg-primary rounded-[8px] w-[62px] h-[40px] text-sm text-medium leading-5 text-white"
                        onClick={() => {
                            dispatch(makeAllowancePayment());
                            setIsProcessPayment(false);
                        }}>
                        Yes
                    </button>
                </div>
            </ModalComponent>
            <div className="mt-4 bg-white rounded-[8px]">
                <div className="p-4">
                    {/* search */}
                    <div className="flex gap-4 mt-4">
                        <div className="w-full">
                            <SearchComponent
                                placeholder="Search..."
                                searchValue={searchValue}
                                handleChange={handleChange}
                            />
                        </div>
                        {typeID !== 1 && typeID !== 3 && typeID !== 4 && <div className="">
                            <button
                                className="bg-primary py-3 px-3 text-sm rounded-lg min-w-[80px] min-h-[50px] text-white"
                                onClick={toggleModal}>
                                <p>Pay All</p>
                            </button>
                        </div>}

                    </div>

                    <ul className="flex mt-5 gap-5 items-center">
                        {callTypes.map((item) => (
                            <li
                                key={item.id}
                                onClick={() => toggleType(item.id, item.tag)}
                                className={
                                    typeID === item.id
                                        ? 'flex gap-1 items-center border-b-2 border-primary pb-1 cursor-pointer'
                                        : 'flex gap-2 items-center pb-1 cursor-pointer'
                                }
                            >
                                <p
                                    className={
                                        typeID === item.id
                                            ? 'text-primary text-sm font-semibold'
                                            : 'text-[#96A397] text-sm font-semibold'
                                    }
                                >
                                    {item.title}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* tables */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-left">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                            <tr>
                                {typeID !== 1 && typeID !== 3 && typeID !== 4 && <th scope="col" className="px-4 py-4 font-semibold ">
                                    <input
                                        type="checkbox"
                                        checked={selectAllChecked}
                                        onChange={() => handleSelectAllCheckbox()}
                                    />
                                </th>}
                                <th scope="col" className="px-4 py-4 font-semibold ">
                                    Employee ID
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    First Name
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Last Name
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Allowance
                                </th>
                                <th scope="col" className="px-4 py-4 font-semibold">
                                    Payment Status
                                </th>
                                {typeID !== 1 && typeID !== 3 && typeID !== 4 && <th scope="col" className="px-4 py-4 font-semibold">
                                    Action
                                </th>}
                            </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                                {approvedAllowance.length > 0 && approvedAllowance.map((item) => (
                                    <tr
                                        key={item.id}
                                        className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                                    >
                                        {typeID !== 1 && typeID !== 3 && typeID !== 4 && <th
                                            scope="row"
                                            className="py-4 px-4 font-normal text-[#5C715E]"
                                        >
                                            <input
                                                type="checkbox"
                                                name="approved_payslip_child"
                                                checked={checkedApprovedAllowance.some(val => val.payslip_id === item.id)}
                                                onChange={() => handleCheckedBox(item.employee.id, item.id, item.date_of_entry,)}
                                            />
                                        </th>}

                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.employee ? item.employee.employee_id : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.employee ? item.employee.first_name : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.employee ? item.employee.last_name : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            {item.net_allowance ? item.net_allowance?.toLocaleString() : "N/A"}
                                        </td>
                                        <td className="py-4 px-4 text-[#5C715E]">
                                            <div className="flex">
                                                <div className={getPaymentStatusBg(item.processed)}>
                                                    <p>{getPaymentStatusText(item.processed)}</p>
                                                </div>
                                            </div>
                                        </td>
                                        {typeID !== 1 && typeID !== 3 && typeID !== 4 && <td className="py-4 px-4">
                                            <div className="flex">
                                                <div
                                                    className={`${checkedApprovedAllowance.length > 1 ? "pointer-events-none" : ""
                                                        } ${getPaymentStatusBg(
                                                            item.payment_status === "COMPLETED" ? "DONE" : "PAY"
                                                        )}`}
                                                    onClick={() => handleModalClick(item.employee.id, item.id, item.date_of_entry,)}
                                                >
                                                    <p>{getApprovedAllowanceActionText(item.payment_status === "COMPLETED" ? "DONE" : "PAY")}</p>
                                                </div>
                                            </div>
                                        </td>}
                                    </tr>
                                ))
                                }
                            </tbody>
                        )}
                    </table>

                    {loading &&
                        <LoadingStateComponent />
                    }
                </div>
            </div>
            {!loading

                && approvedAllowance.length < 1
                &&
                <EmptyStateComponent textContent={textContent(typeID)} />
            }
            {!loading && approvedAllowance.length > 0 ? (
                <div className="">
                    <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange({ page })}
                        totalItems={total}
                        pageSize={pageSize}
                        pageSizeShow
                        onPageSizeChange={(page) => onPageSizeChange(page)}
                    />
                </div>
            ) : (
                ''
            )}

        </>
    );
}

export default ApprovedAllowanceTable;