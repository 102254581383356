import React from 'react'
import { useNavigate } from 'react-router-dom'
import go_back from '../assets/goBack.svg'

const GoBack = () => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1)
  }
  return (
    <div>
      {' '}
      <div className="flex items-center cursor-pointer mb-6" onClick={handleGoBack}>
        <img src={go_back} alt="go back" className="w-[20px] h-[20px]" />
        <p className="text-sm font-semibold text-[#5C715E] ml-[4px] ">
          Go Back
        </p>
      </div>
    </div>
  )
}

export default GoBack