import React, {useState} from "react"
import Header from "../../components/header"
import eye_open from "../../assets/eye.svg"
import eye_close from "../../assets/eye-off.svg";
import { useSelector } from "react-redux";
import { Bar } from "recharts";

const OverviewUpdatePage= () => {

    const {  balance } = useSelector(
      (state) => state.overview
    );
  //visbility of the overview amount
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  }

  const BarData = [
    {
        name: "January",
        ft: 40,
        pt: 6,
        ct: 6,
        in: 10,
      },
      {
        name: "February",
        ft: 34,
        pt: 7,
        ct: 10,
        in: 6,
      },
      {
        name: "March",
        ft: 35,
        pt: 5,
        ct: 9,
        in: 5,
      },
      {
        name: "April",
        ft: 35,
        pt: 13,
        ct: 6,
        in: 7,
      },
      {
        name: "May",
        ft: 25,
        pt: 9,
        ct: 6,
        in: 8,
      },
      {
        name: "June",
        ft: 30,
        pt: 5,
        ct: 2,
        in: 6,
      },
      {
        name: "July",
        ft: 34,
        pt: 4,
        ct: 5,
        in: 5,
      },
      {
        name: "August",
        ft: 30,
        pt: 5,
        ct: 2,
        in: 7,
      },
      {
        name: "September",
        ft: 35,
        pt: 3,
        ct: 2,
        in: 9,
      },
      {
        name: "October",
        ft: 35,
        pt: 4,
        ct: 2,
        in: 9,
      },
      {
        name: "November",
        ft: 27,
        pt: 3,
        ct: 2,
        in: 9,
      },
      {
        name: "December",
        ft: 27,
        pt: 3,
        ct: 2,
        in: 9,
      } 
  ];



    return(
        <>
            <Header text="Overview"></Header>
            
            <div className="flex row">
            <div className="flex col w-[70%]">
                <section className="mb-5 flex row gap-3 mt-6">
                    <div
                    className={`bg-white rounded-lg py-4 px-4 w-[240px] h-[106px]`}
                    >
                    <div className="flex row justify-between">
                        <h6 className="text-[#96A397] font-semibold text-sm">
                            Wallet Balance
                        </h6>
                        <div className= "flex flex-row items-center">
                            <img className="cursor-pointer item-center center-center" src={isVisible? eye_open : eye_close} alt="show or hide balance icon" onClick={toggleVisibility}/>
                        </div>
                    </div>
                    
                    <div className="flex flex-row gap-2 jusity-center  items-center">
                        <p className="text-secondary font-semibold text-[24px] leading-7.5 mt-3 ">
                            {/* { loader?  (
                        <div className="animate-pulse w-full mt-4 px-4 mb-4">
                            <div className="h-6 bg-gray-200 rounded-lg  w-full mb-2"></div>
                        </div>
                        ) : `${balance? `₦${balance}`: 0 }` */}
            
                        {/* Handle null balance and conditional rendering */}
                        {isVisible ? (balance ? `₦${balance.toLocaleString()}` : 0) : "*******"}
            
                        {/* {balance? `₦${balance?.toLocaleString()}`: 0  } */}
                        </p>
                    </div>
                    </div>
                    <div
                    className={`bg-white rounded-lg py-4 px-4 w-[240px] h-[106px]`}
                >
                    <h6 className="text-[#96A397] font-semibold text-sm">
                    Number of Employees
                    </h6>
                    <div className="flex flex-row gap-2 jusity-center  items-center">
                    <p className="text-secondary font-semibold text-[24px] leading-7.5 mt-3 ">
                        {/* { loader?  (
                        <div className="animate-pulse w-full mt-4 px-4 mb-4">
                            <div className="h-6 bg-gray-200 rounded-lg  w-full mb-2"></div>
                        </div>
                    ) : `${balance? `₦${balance}`: 0 }` */}
            
                        {/* Handle null balance and conditional rendering */}
                        {/* {isVisible ? (balance ? `₦${balance.toLocaleString()}` : 0) : "*******"} */}
                        40 people
                        {/* {balance? `₦${balance?.toLocaleString()}`: 0  } */}
                    </p>
                    </div>
                    </div>
                </section>
                <section className="mt-6 flex col bg-white">
                    <div className="w-full h-[400px]">
                     <Bar data={BarData} height={300} />
                    </div>
                    <div className="">
                        
                    </div>
                </section>
            </div>
            <div className="">
                    {/* Two pie charts section */}
            </div>
           

            </div>
        </>
    )

}

export default OverviewUpdatePage;