import React, { useState } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Header from "../../../components/header";
import MonthlyPayrollTable from "../../../Tables/PayrollTables/monthlypayrolltable";
import MonthlyAllowanceTable from "../../../Tables/PayrollTables/monthlyallowancetable";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ModalComponent from "../../../components/Modals/modal";
import { createApprovePayslip, listPayslipsUnderPayroll, listAllowanceUnderPayroll, createApproveAllowance, regeneratePayslip } from "../../../redux/Payroll/payrollAction";
import GoBack from "../../../components/goBack"
import { clearCheckedRegeneratedPayslips } from "../../../redux/Payroll/payroll";

const MonthlyPayroll = () => {
  const { id } = useParams();
  const dispatch = useDispatch()
  const payroll_id = parseInt(id, 10) // Convert the id to an integer

  const { payrolls } = useSelector((state) => state.payroll);

  //to get an payroll
  let payroll = payrolls.find((val) => val.id === parseInt(id));

  let month = moment(payroll.date_of_entry).format("MMMM");
  const [payslipDate, setPayslipDate] = useState(new Date())

  // const [] = useState(false)
  const handleRegeneratePayslip = (id) => {
    setInsertDate(false);
    dispatch(regeneratePayslip({
      payroll_id: payroll_id,
      date_of_entry: moment(payroll.date_of_entry).format("YYYY-MM-DD"),
      payslip_date_of_entry: payslipDate
    }))
  
  };

  //functionality for insert date modal
  const [insertDate, setInsertDate] = useState(false);

  const [tab, setTab] = useState("Payslips"); 

  const toggleInsertDate = () => {
    setInsertDate(!insertDate);
  };
  const [typeID, setTypeID] = useState(1)
  const callTypes = [
    {
        id: 1,
        title: 'All',
        tag: 'all'
    },
    {
        id: 2,
        title: 'Unapproved',
        tag: 'unapproved'
    },
    {
        id: 3,
        title: 'Approved',
        tag: 'approved'
    },
    {
      id: 4,
      title: 'Saved for Later',
      tag: 'draft'
    },
    {
      id: 5,
      title: 'Founders',
      tag: 'exempt'
    },

]

  const toggleType = (id, tag) => {
    setTypeID(id)
    switch (id) {
        case 1:
            dispatch(listPayslipsUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        case 2:
            dispatch(listPayslipsUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        case 3:
            dispatch(listPayslipsUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        case 4:
            dispatch(listPayslipsUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        case 5:
            dispatch(listPayslipsUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break
        default:
            dispatch(listPayslipsUnderPayroll({id:payroll_id, type: 'all', page: 1}))
            break;
    } 
} 

  const toggleTabs = (id, tag) => {
    setTypeID(id)
    switch (id) {
        case 1:
            dispatch(listAllowanceUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        case 2:
            dispatch(listAllowanceUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        case 3:
            dispatch(listAllowanceUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        case 4:
            dispatch(listAllowanceUnderPayroll({id:payroll_id, type: tag, page: 1}))
            break;
        default:
            dispatch(listAllowanceUnderPayroll({id:payroll_id, type: 'all', page: 1}))
            break;
    } 
  }

const handleApprovePayslip = () =>{
    dispatch(createApprovePayslip({payroll_id}))
}

const handleApproveAllowance = () =>{
  dispatch(createApproveAllowance({payroll_id}))
}




  return (
    <>
      <ModalComponent
        title="Regenerate Payslip"
        show={insertDate}
        showModal={() => {setInsertDate(false);
        dispatch(clearCheckedRegeneratedPayslips())}}
        subtitle=""
      >
        <div>
          <div className="flex items-center justify-center gap-6 mt-4">
            <div>
              <p className="text-secondary text-sm font-normal">
                Enter the date of the regenerated payslip
              </p>
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                name="payslipDate"
                value= {payslipDate}
                onChange={(e) => setPayslipDate(e.target.value) }
                className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-full font-medium text-sm"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-center align-center items-center "
           >
            <button
             onClick={handleRegeneratePayslip}
              className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-3 px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
            >
              Regenerate
            </button>
          </div>
        </div>
      </ModalComponent>
      <GoBack/>
      <Header  text={`Payroll for ${month}`} 
        tabs={
          <div className="flex gap-[16px] mt-[16px]">
            <p
              className={
                tab === "Payslips"
                  ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                  : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
              }
              onClick={() => setTab("Payslips")}
            >
              Payslip
            </p>
            <p
              className={
                tab === "Allowance"
                  ? "font-semibold text-sm leading-[18px] text-primary underline underline-offset-[4px] decoration-solid decoration-2 cursor-pointer"
                  : "font-semibold text-sm leading-[18px] text-[#96A397] hover:text-primary hover:underline hover:underline-offset-[4px] hover:decoration-solid hover:decoration-2 cursor-pointer"
              }
              onClick={() => setTab("Allowance")}
            >
              Allowance
            </p>
          </div>
        }
        >
        <div className="flex gap-4">
         {typeID === 2 && tab === "Payslips" &&
          <div className="">
            <button
              type="submit"
              onClick={handleApprovePayslip}
              disabled="" //hover color is #24752B
              className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
            >
              Approve Payslip
            </button>
          </div>
            }
           {typeID === 2 && tab !== "Payslips" &&
          <div className="">
            <button
              type="submit"
              onClick={handleApproveAllowance}
              disabled="" //hover color is #24752B
              className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
            >
              Approve Allowance
            </button>
          </div>
            }

          {
            typeID === 3  && tab === "Payslips" &&
          <div className="">
            <button
              type="submit"
              onClick={toggleInsertDate}
              disabled="" //hover color is #24752B
              className="bg-primary disabled:bg-[#ABB6AC] rounded-lg w-full py-2.5 px-5 text-white border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
            >
              <div className="flex gap-2.5 items-center justify-center ">
                <p>Regenerate Payslip</p>
                <AutorenewIcon style={{ width: 20, height: 20 }} />
              </div>
            </button>
          </div>
          }

        </div>
      </Header>
      {tab === "Payslips" && <MonthlyPayrollTable typeID={typeID} callTypes={callTypes} handleType={toggleType} /> }
      {tab === "Allowance" && <MonthlyAllowanceTable typeID={typeID} callTypes={callTypes} handleType={toggleTabs} />}
    </>
  );
};

export default MonthlyPayroll;
