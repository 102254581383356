import React from 'react'
import searchIcon from '../../assets/search-green.svg'

const EmptyStateComponent = ({textContent}) => {
    return ( 
        <>
        <div className="bg-transparent h-full pt-70 px-4 mt-32 w-full flex justify-center items-center flex-col gap-4">
          <div className="rounded-full bg-[#DBFFDF] p-3">
            <img src={searchIcon} alt="search" />
          </div>
          <p className="text-base font-medium text-secondary1">
            {" "}
            {textContent}
          </p>
        </div>
        </>
     );
}
 
export default EmptyStateComponent;