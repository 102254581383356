import React, {useState, useEffect} from "react";
import logo from "../../assets/c2c-logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../redux/Auth/authAction";
import { useNavigate } from "react-router-dom";
import { clearGetResetLinkSuccess } from "../../redux/Auth/auth";

const ForgotPasswordPage = () => {
    const { loading, isSuccess} = useSelector((state) => state.auth);
    const [email, setEmail] = useState('');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
    e.preventDefault(); 


    if (email === "") {
      // Handle empty email case (optional)
      return;
    }

    try {
      await dispatch(resetPassword({ email }));
      
    } catch (error) {
      // Handle API call errors (optional)
      console.error("Error resetting password:", error);
    }
  };
   
  useEffect(() => {
    if (isSuccess) {
      const timeoutId = setTimeout(() => {
        navigate('/check-email');
        // Dispatch the Redux action to change the state value
        dispatch(clearGetResetLinkSuccess()); // Replace newStateValue with the actual value you want to set
      }, 5000);

      // Clean up the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [isSuccess, navigate, dispatch]);




    return (
        <>
            <div className="px-6 md:px-10 py-8 relative overflow-hidden">
                <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>
                <div className="flex justify-between">
                    <div>
                        <img src={logo} alt="logo" className="md:w-[129px] md:h-[40px]" />
                    </div>
                    <div className="z-10"></div>
                </div>

                <div className="md:max-w-lg lg:max-w-md mx-auto md:py-28 py-20">
                    <div>
                        <h6 className="text-2xl text-primary font-semibold">
                            Forgotten your password?
                        </h6>
                        <p className="text-secondary1 font-medium text-sm mt-1">
                            Don’t feel bad, it happens to the best of us. Enter your email
                            address and we'll send you a link to reset your password.
                        </p>
                    </div>
                    <div className="mt-8">
                        <label className="block mb-2 text-secondary text-sm font-normal">
                            Email Address
                        </label>
                        <input
                            type="email"
                            name="email"
                            onChange= {(e) => setEmail(e.target.value)}
                            placeholder="kilimanjaro@crop2cash.com"
                            className="'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#344335] text-base focus:border-[#2B8C34] focus:outline-none focus:shadow-input rounded-lg border border-[#96A397] bg-white py-3 px-4"
                        />
                    </div>
                    <div className="mt-8">
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading || email === ''} //disabled:bg-[#ABB6AC] bg-primary
                            className="bg-primary
                            disabled:bg-[#ABB6AC] 
                            rounded-lg w-full py-4 text-white font-medium hover:bg-[#24752B] transition-all"
                        >
                            {loading ? "Loading..." : "Send Reset Link"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordPage;
