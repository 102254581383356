import { createSlice } from '@reduxjs/toolkit'
import { overviewBalance, payrollOverviewTable } from './overviewAction'

const initialState = {
    value: 0,
    loading: false,
    loader: false,
    balance: '',
    total: 0,
    allPayrolls: []
}

export const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        increment: (state) => {
          // Redux Toolkit allows us to write "mutating" logic in reducers. It
          // doesn't actually mutate the state because it uses the Immer library,
          // which detects changes to a "draft state" and produces a brand new
          // immutable state based off those changes
          state.value += 1
        },
        decrement: (state) => {
          state.value -= 1
        },
        incrementByAmount: (state, action) => {
          state.value += action.payload
        },
      },
      extraReducers: {
        // overview balance -------------------- ---------------------------------------------------------------
        [overviewBalance.pending]: (state)=>{
            state.loading = true
        },
        [overviewBalance.fulfilled]: (state,{payload})=>{
            state.loading = false
            state.balance = payload.data
        },
        [overviewBalance.rejected]: (state) =>{
          state.loading = false
        },
        // get payroll overview table
        [payrollOverviewTable.pending]: (state)=>{
            state.loading = true
            state.loader = true
        },
        [payrollOverviewTable.fulfilled]: (state,{payload})=>{
            state.loading = false
            state.loader = false
            state.total = payload.count
            state.allPayrolls = payload.results
        },
        [payrollOverviewTable.rejected]: (state) =>{
          state.loading = false
          state.loader = false
        },


  
      }
      
    })
    
    // Action creators are generated for each case reducer function
    export const { increment, decrement, incrementByAmount } = overviewSlice.actions
    
    export default overviewSlice.reducer