import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from "cogo-toast"


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}


// get employess payslip
export const retrieveMonthlyPayslips = createAsyncThunk(
    'retrieveMonthlyPayslips',
    async({page}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}employee/payslips/?page_size=10&page=${page}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)

// get an employee payslip
export const retrievePayslip = createAsyncThunk(
    'retrievePayslip',
    async({id}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.get(`${apiUrl}employee/payslips/${id}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 200){
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
    }
    else{
      return rejectWithValue(error.response)
    }
  }
    }
)

// download payslip
export const downloadPayslip = createAsyncThunk(
    'downloadPayslip',
    async({id,date, payslip}, {rejectWithValue, dispatch}) => {
     try {
        let url = `${apiUrl}employee/payslips/${id}/download/?date_of_entry=${date}`
        const res = await axios.get(`${apiUrl}employee/payslips/${id}/download/?date_of_entry=${date}`,
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            },
            responseType: 'blob'
        }
        )
        .then((response) => {
           url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${payslip}.pdf`); // Replace with the desired filename to save the PDF
          document.body.appendChild(link);
          link.click();
          link.remove();
          cogoToast.success(`Payslip download successfull!`);
        })
    if(res.status === 200){
        cogoToast.success(`Payslip download successfull!`);
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
    }
    else{
        cogoToast.error(`Error while downloading payslip!`);
      return rejectWithValue(error.response)
    }
    }
  }
)

// make complaint
export const handleMakeComplaint = createAsyncThunk(
    'handleMakeComplaint',
    async({id,  complaint_title, complaint,}, {rejectWithValue, dispatch}) => {
     try {
        const res = await axios.post(`${apiUrl}complaints/`,
        {  
            payslip: id,
            complaint_title,
            complaint: complaint,
        },
        {
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}` 
            }
        }
        )
    if(res.status === 201){
        cogoToast.success(`Complaint sent successfully!`);
        return res.data
    }
    
  } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
    }
    else{
        cogoToast.error(`${error.response.data.error || error.response.data.detail}!`);
      return rejectWithValue(error.response)
    }
    }
  }
)

