import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useReducer,
  useRef,
} from "react";
import Header from "../../components/header";
import SearchComponent from "../../components/search";
import filterIcon from "../../assets/filter_list.svg";
import { KeyboardArrowRight } from "@mui/icons-material";
import {
  downloadRecords,
  recordsTable,
} from "../../redux/Records/recordsAction";
import { useOutsideAlerter } from "../../helpers/OutsideClick";
import { debounce } from "lodash";
import LoadingStateComponent from "../../components/Empty State/loadingstate";
import { useDispatch, useSelector } from "react-redux";
import EmptyStateComponent from "../../components/Empty State/emptystate";
import PaginationComponent from "../../components/Pagination/Pagination";

const RecordsPage = () => {
  const dispatch = useDispatch();

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const { loading, records, total } = useSelector((state) => state.record);

  const [searchValue, setSearchValue] = useState("");

  //pagination
  const [currentPage, setCurrentPage] = useState(1);

  // const onPageChange = (val) => {
  //   const { page } = val;
  //   setCurrentPage(page);

  //   dispatch(
  //     recordsTable({
  //       page: page,
  //     })
  //   );
  // };

  const onPageChange = (val) => {
    const { page } = val;
    setCurrentPage(page);

    if(selectedMonth !== "" && selectedYear === ""){
      dispatch(recordsTable({ page: page, month: selectedMonth, year: "" }));
    } else if (selectedYear !== "" && selectedMonth === ""){
      dispatch(recordsTable({ page: page, year: selectedYear, month: "" }));
    } else if (selectedMonth !== "" && selectedYear !== ""){
      dispatch(recordsTable({ page: page, month: selectedMonth, year: selectedYear }))
    } else {
      dispatch(recordsTable({ page: page, year: "", month: "" }));
    }
  }

  // handle search
  const handleSearch = useMemo(
    () =>
      debounce((val) => {
        //  api to dispatch and make the actions for search
       // console.log(val);
      }, 750),
    []
  );

  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;
      setSearchValue(val);
      handleSearch(val);
    },
    [handleSearch]
  );

  //filter
  const [isFilter, setIsFilter] = useState(false);
  const wrapperFilterRef = useRef(null);
  useOutsideAlerter(wrapperFilterRef, setIsFilter);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [clickState, updateClickState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      clickedFilter: "",
    }
  );
  const [allData] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      monthData: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      yearData: [
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
        "2041",
        "2042",
        "2043",
        "2044",
        "2045",
        "2046",
        "2047",
        "2048",
        "2049",
        "2050",
      ],
      duplicatedMonthData: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      duplicatedYearData: [
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
        "2041",
        "2042",
        "2043",
        "2044",
        "2045",
        "2046",
        "2047",
        "2048",
        "2049",
        "2050",
      ],
    }
  );

  const handleSelectedMonth = (data) => {
    setSelectedMonth(data);
  };

  const handleSelectedYear = (data) => {
    setSelectedYear(data);
  };

  const handleFilter = useCallback(() => {
    if (selectedMonth !== "" && selectedYear !== "") {
      dispatch(recordsTable({ page: 1, month: selectedMonth, year: selectedYear }));
    } else if (selectedMonth !== "") {
      dispatch(recordsTable({ page: 1, month: selectedMonth, year: "" }));
    } else if (selectedYear !== "") {
      dispatch(recordsTable({ page: 1, year: selectedYear, month: "" }));
    } else {
      dispatch(recordsTable({ page: 1, month: "", year: "" }));
    }
  }, [dispatch, selectedYear, selectedMonth]);

  
useEffect(() => {
  handleFilter();
}, [handleFilter])


const handleDownloadRecords = ()=> {
  if (selectedMonth !== "" && selectedYear !== "") {
    dispatch(downloadRecords({ month: selectedMonth, year: selectedYear }));
  } else if (selectedMonth !== "") {
    dispatch(downloadRecords({ month: selectedMonth, year:"" }));
  } else if (selectedYear !== "") {
    dispatch(downloadRecords({  year: selectedYear, month: "" }));
  } else {
    dispatch(downloadRecords({month: "", year: "" }));
  }
}

  // const handleFilter = useCallback(() => {
  //   if (selectedMonth !== ""  && selectedYear === "") {
  //     dispatch((recordsTable({page: 1, month: selectedMonth })))
  //   } else if (selectedYear !== ""  && selectedMonth === "") {
  //     dispatch((recordsTable({page: 1, year: selectedYear })))
  //   } else if(selectedMonth !== ""  && selectedYear !== ""){
  //   dispatch
  //    recordsTable({
  //       page: page,
  //       month: selectedMonth,
  //       year: selectedYear,
  //     })
  //   } else {
  //     dispatch(recordsTable({page: 1}))
  //   }
  // }, [dispatch, selectedYear, selectedMonth])

  return (
    <>
      <Header text="Payroll Record for March 2023">
       {records.length > 0 &&
         <div className="">
          <button
            type="submit"
            disabled={loading} //hover color is #24752B
            className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
            onClick={handleDownloadRecords}
          >
            Download Record
          </button>
        </div> 
         } 
      </Header>
      <div className="mt-4 bg-white rounded-[8px] ">
        <div className=" bg-white rounded-[8px] relative">
          <div className="p-4">
            {/* search */}
            <div className="mt-4 flex gap-4 ">
              <div className="w-full relative">
                <SearchComponent
                  placeholder="Search"
                  searchValue={searchValue}
                  handleChange={handleChange}
                />
              </div>
              <div className="relative">
                <button
                  onClick={() => setIsFilter(!isFilter)}
                  className="flex justify-center items-center gap-2 border border-primary rounded-[8px] py-3 px-6 text-primary min-h-[50px] text-sm font-medium leading-5 cursor-pointer"
                >
                  <p>Filter</p>
                  <img src={filterIcon} alt="filtericon" />
                </button>


                <div className="relative">
                {isFilter && (
                  <div ref={wrapperFilterRef}>
                    <div className="">
                      <div className="bg-white rounded-[8px] p-2 shadow-md w-[211px] absolute right-[15%] cursor-pointer z-20 mt-2 top-11">
                        <div
                          className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                          onClick={() => {
                            updateClickState({ clickedFilter: "Month" });
                          }}
                        >
                          <p>Month</p>
                          <KeyboardArrowRight style={{ fontSize: "20px" }} />
                        </div>
                        <div
                          className="flex justify-between items-center text-sm font-medium leading-5 text-[#344335] px-4 py-3 mb-2"
                          onClick={() => {
                            updateClickState({ clickedFilter: "Year" });
                          }}
                        >
                          <p>Year</p>
                          <KeyboardArrowRight style={{ fontSize: "20px" }} />
                        </div>
                      </div>
                    </div>

                    <>
                      {clickState?.clickedFilter === "Year" ? (
                        <div
                          className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-380%] top-[300%] cursor-pointer z-20 mt-2"
                          ref={wrapperFilterRef}
                        >
                          <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
                            <>
                              {allData?.yearData?.map((data, index) => {
                                return (
                                  <div
                                    className="flex gap-4 items-center px-4 py-3"
                                    key={index}
                                  >
                                    <input
                                      name={data}
                                      type="radio"
                                      className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                                      value={data}
                                      onClick={(event) =>
                                        handleSelectedYear(event.target.value)
                                      }
                                      checked={selectedYear === data}
                                    />
                                    <p className="font-medium text-sm leading-5 text-[#344335]">
                                      {data}
                                    </p>
                                  </div>
                                );
                              })}
                            </>
                          </div>
                        </div>
                      ) : clickState?.clickedFilter === "Month" ? (
                        <div
                          className="bg-white rounded-[8px] p-2 shadow-md w-[236px] absolute left-[-380%] top-[300%] cursor-pointer z-20 mt-2"
                          ref={wrapperFilterRef}
                        >
                          <div className="text-sm font-medium leading-5 text-[#344335]  mb-2 max-h-[300px] overflow-y-auto call2orderOverflow">
                            <div className="mt-2 px-4 py-4 text-sm font-semibold leading-[18px] ">
                              <p>{selectedMonth ? 1 : 0} Selected</p>
                            </div>
                            <>
                              {allData?.monthData?.map((data, index) => {
                                return (
                                  <div
                                    className="flex gap-4 items-center px-4 py-3"
                                    key={index}
                                  >
                                    <input
                                      name={data}
                                      type="radio"
                                      className="accent-primary h-[14px] w-[14px] rounded-[2px]"
                                      value={data}
                                      onClick={(event) =>
                                        handleSelectedMonth(event.target.value)
                                      }
                                      checked={selectedMonth === data}
                                    />
                                    <p className="font-medium text-sm leading-5 text-[#344335]">
                                      {data}
                                    </p>
                                  </div>
                                );
                              })}
                            </>
                          </div>
                        </div>
                      ) : null}
                    </>
                  </div>
                )}
              </div>
              </div>
            
            </div>
          </div>
        </div>
        {/* Records Table */}
        <div className="overflow-x-auto relative">
          <table className="w-full text-left">
            <thead className="text-primary text-sm bg-white border-b-[#F7F7F7] border-b-2">
              <tr>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Employee ID
                </th>
                <th scope="col" className="px-4 py-4 font-semibold ">
                  First Name
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Last Name
                </th>
                {/* <th scope="col" className="px-4 py-4 font-semibold">
                  Other Name
                </th> */}
                <th scope="col" className="px-4 py-4 font-semibold">
                  Designation
                </th>
                {/* <th scope="col" className="px-4 py-4 font-semibold">
                  Employee Type
                </th> */}
                <th scope="col" className="px-4 py-4 font-semibold">
                  Gross Salary
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Basic Salary
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Housing Allowance
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Transport Allowance
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Leave Allowance
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Utility Allowance
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Training Allowance
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Entertainment Allowance
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Taxable Income
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Pension Contribution
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Income Tax Liability
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Loan/Salary Advance
                </th>
                {/* <th scope="col" className="px-4 py-4 font-semibold">
                  Gross Salary                                
                </th> */}
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Deductions
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Net Income
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Arrears
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Bonus
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Overtime
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  HMO
                </th>
                <th scope="col" className="px-4 py-4 font-semibold">
                  Total Net Salary
                </th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {records.length > 0 &&
                  records.map((item) => (
                    <tr
                      key={item.id}
                      className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                    >
                      <th
                        scope="row"
                        className="py-4 px-4 font-normal text-[#5C715E]"
                      >
                        {item.employee.employee_id
                          ? item.employee.employee_id
                          : "N/A"}
                      </th>

                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee.first_name
                          ? item.employee.first_name
                          : "N/A"}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee.last_name
                          ? item.employee.last_name
                          : "N/A"}
                      </td>
                      {/* <td className="py-4 px-4 text-[#5C715E]">
                        {/* {item.net_payment.total_net_payment
                          ? `₦${item.net_payment.total_net_payment}`
                          : item.net_payment.total_net_payment}  </td>  */}

                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee.position
                          ? item.employee.position
                          : "N/A"}
                      </td>
                      {/* <td className="py-4 px-4 text-[#5C715E]">
                        {item.employee.employee_type? item.employee.employee_type : "N/A"}
                      </td> */}
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.gross_payable
                          ? `₦${item.gross_salary.gross_payable?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.basic_salary
                          ? `₦${item.gross_salary.basic_salary?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.housing_allowance
                          ? `₦${item.gross_salary.housing_allowance?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.transport_allowance
                          ? `₦${item.gross_salary.transport_allowance?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.leave_allowance
                          ? `₦${item.gross_salary.leave_allowance?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.utility_allowance
                          ? `₦${item.gross_salary.utility_allowance?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.training_allowance
                          ? `₦${item.gross_salary.training_allowance?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.entertainment_allowance
                          ? `₦${item.gross_salary.entertainment_allowance?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.gross_salary.taxable_income
                          ? `₦${item.gross_salary.taxable_income?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions.employee_contribution
                          ? `₦${item.deductions.employee_contribution?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions.paye ? `₦${item.deductions.paye?.toLocaleString()}` : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions.more_deductions &&
                        item.deductions.more_deductions.salary_advance
                          ? `₦${item.deductions.more_deductions.salary_advance?.toLocaleString()}`
                          : 0}
                      </td>
                      {/* <td className="py-4 px-4 text-[#5C715E]">
                      {item.deductions.more_deductions && item.deductions.more_deductions.salary_advance? `₦${item.deductions.more_deductions.salary_advance}` : "N/A"}
                      </td> */}
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions.total_deductions
                          ? `₦${item.deductions.total_deductions?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_salary ? `₦${item.net_salary?.toLocaleString()}` : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions.others &&
                        item.deductions.others.arrears
                          ? `₦${item.deductions.others.arrears?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions.others && item.deductions.others.bonus
                          ? `₦${item.deductions.others.bonus?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions.others &&
                        item.deductions.others.overtime
                          ? `₦${item.deductions.others.overtime?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.deductions && item.deductions.hmo
                          ? `₦${item.deductions.hmo?.toLocaleString()}`
                          : 0}
                      </td>
                      <td className="py-4 px-4 text-[#5C715E]">
                        {item.net_salary ? `₦${item.net_salary?.toLocaleString()}` : 0}
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>

          {loading && <LoadingStateComponent />}
        </div>
      </div>
      {!loading && records.length < 1 && (
        <EmptyStateComponent textContent= {"Oops! No record(s) available."}/>
      )}

      {!loading && records.length > 0 ? (
        <div className="">
          <PaginationComponent
            page={currentPage}
            onPageChange={(page) => onPageChange({ page })}
            pageSize={10}
            totalItems={total}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RecordsPage;
