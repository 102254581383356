import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from 'cogo-toast'
import jwt_decode from "jwt-decode";

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
}


export const refreshToken = createAsyncThunk(
  'refreshToken',
  async (refreshToken, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/token/refresh/`, {
        refresh: refreshToken,
      });

      if (res.status === 200) {
        const decodedHeader = jwt_decode(res.data.access);
        return {
          data: decodedHeader,
          token: res.data.access,
          // refreshToken: res.data.refresh,
        };
      }
    } catch (error) {
      // Handle token refresh error
      return rejectWithValue(error.response.data);
    }
  }
);
export const userLogin = createAsyncThunk(
  'login',
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/login/`, {
        email,
        password,
      })

      if (res.status === 200) {
        const decodedHeader = jwt_decode(res.data.access);
        cogoToast.success("Login Successful!", {
          position: 'bottom-right'
        }
        )
        return {
          data: decodedHeader,
          token: res.data.access,
          refreshToken: res.data.refresh
        }
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.status === 401) {
        cogoToast.error(error.response.data.data.detail)
        dispatch({ type: 'auth/Logout' })
      } else if (error.status === 400) {
        cogoToast.error(`${error.response.data.detail}` || "Can't sign in right now. Try again later!")
      }
      else {  
        cogoToast.error(`${error.response.data.detail}`)
        return rejectWithValue(error.response.data);
      }
    }
  }
)

export const userRegister = createAsyncThunk(
  'register',
  async ({ email, first_name, last_name, employee_id, role, pension_id, account_name, account_number, bank, password, bank_code, department }, { rejectWithValue }) => {
    try {
      let res;
      if (pension_id === "") {
        res = await axios.post(`${apiUrl}auth/register/`, {
          email,
          first_name,
          last_name,
          employee_id,
          role,
          department,
          account_name,
          account_number,
          bank,
          bank_code,
          password,
        })
      } else {
        res = await axios.post(`${apiUrl}auth/register/`, {
          email,
          first_name,
          last_name,
          employee_id,
          role,
          department,
          pension_id,
          account_name,
          account_number,
          bank,
          bank_code,
          password,
        })
      }

      if (res.status === 200) {
        cogoToast.success("Account Creation Successful!"
          //,{ position: 'bottom-right'}
        )
        return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${error.response.data.error}` || `${error.response.data.detail}` || "Can't sign up right now. Try again later!")
        return rejectWithValue(error.response.data)
      } else if (error.response.status === 500) {
        cogoToast.error("Sorry, an error occurred on the server while processing your request. Please try again later.")
      } else {
        cogoToast.error(`${error.response.data.error}` || `${error.response.data.detail}` || "Can't sign up right now. Try again later!")
        return rejectWithValue(error.response.data)
      }
    }
  },
)

export const verifyEmail = createAsyncThunk(
  'verifyEmail',
  async ({ email }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/verify_email/`, {
        email
      })
      if (res.status === 200) {
        cogoToast.success("Email Verification Successful!"
          //,{position: 'bottom-right'}
        )
        return res.data.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${error.response.data.error}` || `${error.response.data.detail}` || '0ops! email address does not exist')
        return rejectWithValue(error.response.data)
      } else {
        cogoToast.error(`${error.response.data.error}` || `${error.response.data.detail}` || "Sorry, an error occurred while processing your request. Please try again later.")
        return rejectWithValue(error.response.data)
      }
    }
  }
)

//verify account no.
export const verifyAccountNumber = createAsyncThunk(
  'verifyAccountNumber',
  async ({ account_number, bank }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}auth/verify_account_number/`, {
        account_number,
        bank
      })
      if (res.status === 200) {
        cogoToast.success("Account Details Verified!"
        )
        return res.data.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${error.response.data.error}` || `${error.response.data.detail}` || "Account name not found! Ensure the account details are correct.")
        return rejectWithValue(error.response.data)
      } else if (error.response.status === 500) {
        cogoToast.error("Sorry, an error occurred on the server while processing your request. Please try again later.")
      } else {
        cogoToast.error(`${error.response.data.error}` || `${error.response.data.detail}` || "Sorry, an error occurred while processing your request. Please try again later.")
        return rejectWithValue(error.response.data)
      }
    }
  }
)

//get bank and their codes
export const getBanks = createAsyncThunk(
  'getBank',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}auth/banks/`)
      if (res.status === 200) {
        return res.data
      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)
      }
    }
  }
)

//get roles
export const getRoles = createAsyncThunk(
  'getRole',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}auth/roles/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        })
      if (res.status === 200) {
        return res.data

      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)
      }
    }
  }
)

//get departments
export const getDepartments = createAsyncThunk(
  'getDepartments',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}auth/departments/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        })
      if (res.status === 200) {
        return res.data

      }

    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response)
      }
    }
  }
)

//user profile
export const userProfile = createAsyncThunk(
  "userProfile",
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.get(`${apiUrl}auth/user_profile/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
          }
        }
      )

      if (res.status === 200) {
        return res.data
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({ type: 'auth/Logout' })
      }
      else {
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

//reset password logic
export const resetPassword = createAsyncThunk(
  'resetPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}reset/request/`, {
        email
      })
      if (res.status === 200) {
        cogoToast.success(res.data.message
          //,{position: 'bottom-right'}
        )
        return res.data.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${error.response.data.error}` || '0ops! email address does not exist')
        return rejectWithValue(error.response.data)
      } else {
        cogoToast.error("Sorry, an error occurred while processing your request. Please try again later.")
        return rejectWithValue(error.response.data)
      }
    }
  }
)

//validate otp
export const validateOtp = createAsyncThunk(
  'validateOtp',
  async ({ password, otp, user_id }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${apiUrl}reset/request-complete/
`, { password, otp, user_id })
      if (res.status === 200) {
        cogoToast.success(res.data.message
          //,{position: 'bottom-right'}
        )
        return res.data.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        cogoToast.error(`${error.response.data.error}`)
        return rejectWithValue(error.response.data)
      } else {
        cogoToast.error("Sorry, an error occurred while processing your request. Please try again later.")
        return rejectWithValue(error.response.data)
      }
    }
  }
)