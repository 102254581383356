import React, { useState, useEffect } from "react";
import Header from "../../../components/header";
import ChecklistIcon from "../../../assets/checklist_icon.svg"
import ColouredCheckListIcon from "../../../assets/Vector.svg"
import spinningLoader from "../../../assets/loader.svg"
import ModalComponent from "../../../components/Modals/modal";
import { useSelector, useDispatch } from "react-redux";
// import cogoToast from "cogo-toast";
import GoBack from "../../../components/goBack";
import Select from "react-select";
import { getBanks, verifyAccountNumber } from "../../../redux/Auth/authAction";


const PayPartner = () => {

  const dispatch = useDispatch()

  const { banks, verifyLoader} = useSelector((state) => state.auth)

  const [payoutMonth, setPayoutMonth] = useState(new Date());
  const [payoutAmount, setPayoutAmount] = useState(0);
  const [payoutBank] = useState("")
  const [payoutAccountNumber, setPayoutAccountNumber] = useState("")
  const [payoutAccountName, setPayoutAccountName] = useState("")
  const [bankCode, setBankCode] = useState("")
  const [accountName, setAccountName] = useState("")
  const [bankSlug] = useState('')
  const [setBank] = useState("")

  //functionality for confirm payment modal
  const [showModal, setShowModal] = useState(false)

  const handlePayPartner = () => {
    setShowModal(false)
  }
  const toggleConfirmPaymentModal = () => {
    setShowModal(!showModal)
  };

  //functionality for save payslip
 // const [savePayslip, setSavePayslip] = useState(false);



  //calling of banks api
  useEffect(() => {
    dispatch(getBanks())
  }, [dispatch])
  const handleBank = (val) => {
    let bankArray = [];
    let bankString = '';
    let code = '';
    if (typeof val === 'object' && val !== null) {
      bankArray.push(val.id);
      const { bank_slug, bank_code } = val;
      bankString = `${bank_slug}`;
      code = `${bank_code}`;
    }
    setBank(bankString)
    setBankCode(code)
  };


  //verify account number 

  const handleVerifyAccountNumber = (val) => {
    const regex = /^\d{10}$/

    const isValidAccountNumber = regex.test(val);

    if (isValidAccountNumber) {
      dispatch(verifyAccountNumber({
        account_number: val,
        bank: `${bankSlug}|${bankCode}`
      }),)

      setAccountName(accountName)
    }
  }


  return (
    <>
      {/* Modal to confirm creation of payslip */}
      <ModalComponent
        title={`Confirm Payment`}
        show={showModal}
        showModal={() => setShowModal(false)}
        subtitle={"Are you sure you sure you want to pay out pension? Please review the details below and click on proceed."}
      >
        <div className="">
          <p className="text-[#96A397] text-xs font-medium mt">Please review the details below and click on proceed</p>
          <div classsName="mt-1 flex-col justify-start items-start gap-2 inline-flex">
            <div className="mt-2 items-center justify-between flex">
              <p
                className={
                  payoutMonth === ""
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Payout Month
              </p>
              <img
                src={
                  payoutMonth !== ""
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 flex gap-2 items-center justify-between">
              <p
                className={
                  payoutAmount > 0 &&
                    payoutAmount !== 0 &&
                    payoutAmount !== isNaN
                    ? "text-secondary text-sm font-medium"
                    : "text-[#ABB6AC] text-sm font-medium"
                }
              >
                Payout Amount
              </p>
              <img
                src={
                  payoutAmount > 0 &&
                    payoutAmount !== 0 &&
                    payoutAmount !== isNaN
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
            <div className="mt-2 items-center justify-between flex">
              <p
                className={
                  payoutBank !== "" && payoutAccountNumber !== "" && payoutAccountName === ""
                    ? "text-[#ABB6AC] text-sm font-medium"
                    : "text-secondary text-sm font-medium"
                }
              >
                Payout Bank Details
              </p>
              <img
                src={
                  payoutBank !== "" && payoutAccountNumber !== "" && payoutAccountName === ""
                    ? ColouredCheckListIcon
                    : ChecklistIcon
                }
                alt="check_icon"
              />
            </div>
          </div>
        </div>
        <div className="pt-[32px] flex justify-end gap-[40px] items-center mb-[12px]">
          <button
            onClick={() => setShowModal(false)}
            className="text-sm text-medium leading-5 text-[#5C715E]"
          >
            Cancel
          </button>
          <button
            onClick={handlePayPartner}
            className="outline-0 bg-primary px-5 py-2.5 rounded-[8px] h-[40px] text-sm text-medium leading-5 text-white"
          >
            Proceed
          </button>
        </div>
      </ModalComponent>
      <GoBack />
      <Header text="Pay Partner" />
      <div className="rounded-lg mt-4">
        <div classname="flex gap-6 w-full">
          <div className="w-[68%]">
            <div className="bg-white rounded-lg px-5 py-[27px]">
              <div className="flex justify-between">
                <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                  Payout Payout for Month
                </p>
              </div>
              <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                <p>Payout Month</p>
                <input
                  type="month"
                  required
                  min={"2023-08-01"}
                  name="payout Month"
                  placeholder="month"
                  value={payoutMonth}
                  onChange={(e) => setPayoutMonth(e.target.value)}
                  className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-1/2 font-medium text-sm mb-[24px]"
                />
              </div>
            </div>
            <div className="bg-white rounded-lg px-5 py-[27px]">
              <div className="flex justify-between">
                <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                  Pension Partner's Account Details
                </p>
              </div>
              <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                <label className="block mb-2 text-secondary text-sm font-medium">
                  Bank Name
                </label>
                <Select
                  onChange={handleBank}
                  options={banks}
                  name="bank"
                  placeholder="Select Bank"
                  className="select_style w-[80%]"
                  classNamePrefix="select_control"
                  getOptionLabel={options => options.bank_name}
                  getOptionValue={options => options.id}
                ></Select>
              </div>
              <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                <label className="block mb-2 text-secondary text-sm font-medium">
                  Account Number
                </label>
                <input
                  type="text"
                  required
                  disabled={true}
                  name="accountNumber"
                  placeholder="Account number"
                  value={payoutAccountNumber}
                  onChange={(e) => { setPayoutAccountNumber(e.target.value); handleVerifyAccountNumber(e.currentTarget.value) }}
                  className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-3/4 font-medium text-sm mb-[24px]"
                />
                {verifyLoader &&
                  <div className="absolute top-1/2 transfrom -translate-y-1/2 right-4 cursor-pointer">
                    <img
                      src={spinningLoader}
                      alt="loading"
                      className="animate-spin"
                    />
                  </div>
                }
              </div>
              <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                <label className="block mb-2 text-secondary text-sm font-medium">
                  Account Name
                </label>
                <input
                  type="text"
                  required
                  name="payoutAccountName"
                  placeholder="Account name"
                  value={payoutAccountNumber}
                  onChange={(e) => setPayoutAccountName(e.target.value)}
                  className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-3/4 font-medium text-sm mb-[24px]"
                />
              </div>
            </div>
            <div className="bg-white rounded-lg px-5 py-[27px]">
              <div className="flex justify-between">
                  <p className="text-[14px] leading-[18px] font-semibold text-tertiary">
                    Payout Amount
                  </p>
              </div>
              <div className="mt-4 text-seconday font-normal text-[14px] leading-[18px]">
                <p>Payout Amount</p>
                <input
                  type="text"
                  required
                  name="payout Amount"
                  placeholder="Amount"
                  value={payoutAmount}
                  onChange={(e) => setPayoutAmount(e.target.value)}
                  className="text-[#96A397] p-3 outline-0 h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] w-3/4 font-medium text-sm mb-[24px]"
                />
              </div>

            </div>
            <div className="flex justify-end bg-white rounded-lg px-5 py-[27px]">
                    <button
                      className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-[10px] px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
                      onClick={toggleConfirmPaymentModal}
                    >
                      Pay
                    </button>
                  </div>
                </div>
          </div>
        </div>
    </>
  )

}

export default PayPartner;
