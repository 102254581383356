import React from 'react'
import envelope from '../../assets/mailsvg.svg'
import logo from '../../assets/c2c-logo.svg'
import { useNavigate } from 'react-router-dom'


const SetPasswordSuccessPage = () => {
     const navigate = useNavigate()
    
    const handleReturnToLogin = () =>{
            navigate('/')
        }
        
    return ( 
        <>
        <div className="px-6 md:px-10 py-8 relative overflow-hidden">
        <div className="hidden md:block absolute -top-20 -right-24 bg-[#DBFFDF] w-[450px] h-[450px] rounded-full blur-[150px]"></div>
            <div className="flex justify-between">
                <div>
                    <img src={logo} alt="logo" className="md:w-[129px] md:h-[40px]" />
                </div>
                <div className="z-10">
                    
                </div>
            </div>
            
            <div className="md:max-w-md lg:max-w-md mx-auto md:py-16 py-20 flex flex-col items-center justify-center gap-3 w-[348px]">
                <div className="rounded-full bg-primary1 px-6 py-[26px] w-[68px] h-[68px]">
                    <img src={envelope} alt="left-arrow" className="md:w-[16px] md:h-[20px]" />
                </div>
                <div>
                    <h6 className="text-primary font-semibold text-xl text-center">
                    Your Password Has Been Reset
                    </h6>
                    <p className="text-secondary1 font-medium text-sm mt-1 text-center">
                    Your password has been successufully reset, you can access your account by clicking the button.
                    </p>
                </div>
                <div className='mt-6'>
                    <button
                        type="submit"
                        onClick= {handleReturnToLogin}
                        disabled="" //hover color is #24752B
                        className="bg-primary disabled:bg-[#ABB6AC] rounded-lg w-[348px] py-4 text-white font-medium hover:bg-[#24752B] transition-all text-sm"
                    >
                        Login
                    </button>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default SetPasswordSuccessPage;