import { createSlice } from '@reduxjs/toolkit'
import { employeeDirectory, listEmployeePayslip, listEmployeeAllowance, retrieveEmployeePayslip, retrieveActiveEmployee, downloadEmployeePayslip, addNewRecord, updateEmployeeStatus, paySalaryAdvance } from './employeeAction'


const initialState = {
  value: 0,
  total: 0,
  loading: false,
  loader: false,
  employees: [],
  employeeAllowance: [],
  employeePayslips: [],
  employeePayslip: {},
  employee: {},
  profileEdit: {},
  is_updated: false,
  record_success: false
}

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    profileToEdit: (state, payload) => {
      state.profileEdit = payload.payload
    },
  },
  extraReducers: {
    //get employee directory -------------------- ---------------------------------------------------------------
    [employeeDirectory.pending]: (state) => {
      state.loading = true
    },
    [employeeDirectory.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.total = payload.data.length
      state.employees = payload.data
    },
    [employeeDirectory.rejected]: (state) => {
      state.loading = false
    },
    //list employee payslips -------------------- ---------------------------------------------------------------
    [listEmployeePayslip.pending]: (state) => {
      state.loading = true
    },
    [listEmployeePayslip.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.total = payload.count
      state.employeePayslips = payload.results
    },
    [listEmployeePayslip.rejected]: (state) => {
      state.loading = false
    },
    //list employee allowance -------------------- ---------------------------------------------------------------
    [listEmployeeAllowance.pending]: (state) => {
      state.loading = true
    },
    [listEmployeeAllowance.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.total = payload.count
      state.employeeAllowance = payload.results
    },
    [listEmployeeAllowance.rejected]: (state) => {
      state.loading = false
    },
    //retrieve employee payslip -------------------- ---------------------------------------------------------------
    [retrieveEmployeePayslip.pending]: (state) => {
      state.loading = true
    },
    [retrieveEmployeePayslip.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.employeePayslip = payload.data
    },
    [retrieveEmployeePayslip.rejected]: (state) => {
      state.loading = false
    },
    //download employee payslip-------------------- ---------------------------------------------------------------
    [downloadEmployeePayslip.pending]: (state) => {
      state.loading = true
    },
    [downloadEmployeePayslip.fulfilled]: (state) => {
      state.loading = false
    },
    [downloadEmployeePayslip.rejected]: (state) => {
      state.loading = false
    },
    //GET ACTIVE EMPLOYEES -------------------- ---------------------------------------------------------------
    [retrieveActiveEmployee.pending]: (state) => {
      state.loading = true
    },
    [retrieveActiveEmployee.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.employee = payload.data

    },
    [retrieveActiveEmployee.rejected]: (state) => {
      state.loading = false
    },
    //add new record -------------------- ---------------------------------------------------------------'
    [addNewRecord.pending]: (state) => {
      state.loading = true
    },
    [addNewRecord.fulfilled]: (state) => {
      state.loading = false
      state.record_success = true
    },
    [addNewRecord.rejected]: (state) => {
      state.loading = false
    },
    //update employee status -------------------- ---------------------------------------------------------------'
    [updateEmployeeStatus.pending]: (state) => {
      state.loading = true
      state.is_updated = false
    },
    [updateEmployeeStatus.fulfilled]: (state) => {
      state.loading = false
      state.is_updated = true
    },
    [updateEmployeeStatus.rejected]: (state) => {
      state.loading = false
      state.is_updated = false
    },
    //pay salary advace status ------------------------------------------------------------------------------------
    [paySalaryAdvance.pending]: (state) => {
      state.loading = true
      state.is_pay_advance = false
    },
    [paySalaryAdvance.fulfilled]: (state) => {
      state.loading = false
      state.is_pay_advance = true
    },
    [paySalaryAdvance.rejected]: (state) => {
      state.loading = false
      state.is_updated = false
    }
  }

})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = employeeSlice.actions

export default employeeSlice.reducer

