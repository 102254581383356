import React, { useState } from "react";
import "./Pagination.css";
import {
  ExpandMore,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

const PaginationComponent = ({ 
  onPageChange, totalItems, page,
  pageSize,
  onPageSizeChange,
  pageSizeShow }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isPageSizeOpen, setIsPageSizeOpen] = useState(false);

  const pageSizes = [10, 20, 30, 40, 50]

  const onNext = () => {
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };

  const handlePageClick = (number) => {
    setIsOptionsOpen(false);
    onPageChange(number);
  };

  const handlePageSizeClick = (number) => {
    setIsPageSizeOpen(false)
    if (onPageSizeChange) {
      onPageSizeChange(number)
    }
  }

  return (
    <div className="flex justify-between w-full p-4 font-medium bg-white text-sm leading-4 text-[#5C715E]">
      <div className="flex gap-[37px]">
        <div className="flex gap-2 items-center">
          <div>
            <p>
              Items per page:
            </p>
          </div>
          {
            pageSizeShow
              ?
              <>
                <div className="flex gap-2 items-center">
                  <div>
                    <span>{pageSize}</span>
                  </div>
                  <div className="relative">
                    <ExpandMore
                      style={{ fontSize: "16px", cursor: "pointer" }}
                      onClick={() => setIsPageSizeOpen(!isPageSizeOpen)}
                    />

                    {isPageSizeOpen && (
                      <div className="absolute z-[100] bg-white shadow-md bottom-[150%] rounded-[4px] w-[fit-content] max-h-[200px] overflow-scroll">
                        <>
                          {
                            pageSizes.map((val, index) => (
                              <div
                                key={index}
                                className="hover:bg-[#EDF7EE] hover:text-[#2B8C34] p-2 text-center cursor-pointer"
                                onClick={() => handlePageSizeClick(val)}
                              >
                                <p className="p-1">{val}</p>
                              </div>
                            ))
                          }
                        </>
                      </div>
                    )}


                  </div>
                </div>
              </>
              :
              <>
                <div>
                  <span>{pageSize}</span>
                </div>
              </>
          }

        </div>
        <div>
          <p>
            Showing {totalItems <= pageSize - 1 ? totalItems : pageSize} of{" "}
            {totalItems} items
          </p>
        </div>

      </div>
      <div className="relative">
        <div className="flex gap-3">
          <div className="relative">
            <div className="relative">
              {page}
              <span className="pl-[13px]">
                <ExpandMore
                  style={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => setIsOptionsOpen(!isOptionsOpen)}
                />
              </span>
            </div>
          </div>

          <p>Of {Math.ceil(totalItems / pageSize)} pages</p>

          <p>
            {" "}
            <span className="" onClick={page <= 1 ? () => { } : onPrevious}>
              <KeyboardArrowLeft
                className={`${page <= 1
                    ? "cursor-not-allowed text-[#5C715E]"
                    : "cursor-pointer text-[#3A503A]"
                  } `}
                style={{ fontSize: "16px" }}
              />
            </span>
            <span
              className="pl-[13px]"
              onClick={
                Math.ceil(totalItems / pageSize) === page ? () => { } : onNext
              }
            >
              <KeyboardArrowRight
                className={`${Math.ceil(totalItems / pageSize) === page
                    ? "cursor-not-allowed text-[#5C715E]"
                    : "cursor-pointer text-[#3A503A]"
                  } `}
                style={{ fontSize: "16px" }}
              />
            </span>
          </p>
        </div>

        <div className="h-fit w-full">
          {isOptionsOpen && (
            <div className="absolute z-[100] bg-white shadow-md bottom-[150%] rounded-[4px] w-[fit-content] max-h-[200px] overflow-scroll">
              <>
                {Array.from(
                  { length: Math.ceil(totalItems / pageSize) },
                  (_, index) => (
                    <div
                      key={index}
                      className="hover:bg-[#EDF7EE] hover:text-[#2B8C34] p-2 text-center cursor-pointer"
                      onClick={() => handlePageClick(index + 1)}
                    >
                      <p className="p-1">{index + 1}</p>
                    </div>
                  )
                )}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;