import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/c2c-logo.svg';

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleReturnToHome = () => {
    navigate('/');
  };

  return (
    <>
      <div className="container md:px-10 py-8 relative overflow-hidden">
        
        <div className="md:max-w-md lg:max-w-md m-auto md:py-16 py-20 flex flex-col items-center justify-center gap-3 w-[348px]">
          <div className="flex justify-between">
                <div>
                    <img src={logo} alt="logo" className="md:w-[129px] md:h-[40px]" />
                </div>
          </div>
          <div className='md:max-w-lg lg:max-w-md mx-auto md:py-16 py-auto'>
            <h6 className="text-primary font-semibold text-xl text-center">
              Page Not Found
            </h6>
            <p className="text-secondary1 font-medium text-sm mt-1 text-center">
              The page you are looking for doesn't exist.
            </p>
            <div className="mt-6 flex align-center justify-center">
            <button
              type="submit"
              onClick={handleReturnToHome}
              className="bg-primary disabled:bg-[#ABB6AC] rounded-lg sm:w-[348px] w-[280px] py-4 text-white font-medium hover:bg-[#24752B] transition-all text-sm"
            >
              Go Back Home
            </button>
          </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
