import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiUrl } from '../config'
import cogoToast from "cogo-toast"

const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  }

//get  list of complaings on admin side
export const adminComplaints = createAsyncThunk(
    'adminComplaints',
    async({page,type}, {rejectWithValue, dispatch}) => {
     try {
      if(type === 'all') {
        const res = await axios.get(`${apiUrl}admin/complaints/?page_size=10&page=${page}`, {
          headers:{
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}` 
          }
      });
      if(res.status === 200){
        return res.data;
      }
    } else {
      const res = await axios.get(`${apiUrl}admin/complaints?page_size=10&page=${page}&status=${type}`, 
      {
        headers:{
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}` 
      },
      },
      ); 
    if(res.status === 200){
        return res.data
    }
  }
 } catch (error) {
    if(error.response.status === 401){
      dispatch({type: 'auth/Logout'})
      } else if (error.response.status === 400) {
        cogoToast.error(error.response.data.error)
        return rejectWithValue(error.response);
      }  else {
        return rejectWithValue(error.response)
      }
    }
  }
);                        

//get list of complaint for employee
export const employeeComplaints = createAsyncThunk(
  'employeeComplaints',
  async({page, type}, {rejectWithValue, dispatch}) => {
   try {
    if(type === 'all') {
      const res = await axios.get(`${apiUrl}complaints/?page_size=10&page=${page}`, {
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}` 
        }
    });
    if(res.status === 200){
      return res.data;
    }
  } else {
    const res = await axios.get(`${apiUrl}complaints?page_size=10&page=${page}&status=${type}`, 
    {
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` 
    },
    },
    ); 
  if(res.status === 200){
      return res.data
  }
}
} catch (error) {
  if(error.response.status === 401){
    dispatch({type: 'auth/Logout'})
    } else if (error.response.status === 400) {
      cogoToast.error(error.response.data.error)
      return rejectWithValue(error.response);
    }  else {
      return rejectWithValue(error.response)
    }
  }
}
);  
//retrieve single complaint on admin complaint
export const retrieveAdminComplaint = createAsyncThunk(
  'retrieveAdminComplaint',
  async(id, {rejectWithValue, dispatch}) => {
   try {
      const res = await axios.get(`${apiUrl}admin/complaints/${id}`,
      {
          headers:{
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}` 
          }
      }
      )
  if(res.status === 200){
      return res.data.data
  }
  
} catch (error) {
  if(error.response.status === 401){
    dispatch({type: 'auth/Logout'})
  }
  else{
    return rejectWithValue(error.response)
  }
}
  }
)



//retreive single complaint on employee complaint
export const retrieveEmployeeComplaint = createAsyncThunk(
  'retrieveEmployeeComplaint',
  async(id, {rejectWithValue, dispatch}) => {
   try {
      const res = await axios.get(`${apiUrl}complaints/${id}`,
      {
          headers:{
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}` 
          }
      }
      )
  if(res.status === 200){
      return res.data.data
  }
  
} catch (error) {
  if(error.response.status === 401){
    dispatch({type: 'auth/Logout'})
  }
  else{
    return rejectWithValue(error.response)
  }
}
  }
)

//edit complaint on admin dashboard
export const editComplaint = createAsyncThunk(
  'editComplaint',
  async({id, resolved, complaint}, {rejectWithValue, dispatch}) => {
   try {
      const res = await axios.patch(`${apiUrl}admin/complaints/${id}/`,
      {  
          complaint: complaint,
          resolved: resolved,
      },
      {
          headers:{
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}` 
          }
      }
      )
  if(res.status === 201){
      cogoToast.success(`Complaint sent successfully!`);
      return res.data
  }
  
} catch (error) {
  if(error.response.status === 401){
    dispatch({type: 'auth/Logout'})
  }
  else{
      cogoToast.error(`${error.response.data.detail || error.response.data.error}`)
    return rejectWithValue(error.response)
  }
  }
}
)