import React from 'react'



const EmployeeDBNotificationsPage = () => {
    return ( 
        <>
        <p>Coming soon</p>
        </>
     );
}
 
export default EmployeeDBNotificationsPage;