import React, {useEffect} from "react";
import { ContentCopy } from "@mui/icons-material";
import { toggleView } from "../redux/Auth/auth";
import { userProfile } from "../redux/Auth/authAction";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/header";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import GoBack from "../components/goBack";

const ProfilePage = () => {

    let dispatch = useDispatch()

    let navigate = useNavigate()
    
    const { profile, first_name, last_name, email, is_admin, is_superuser} = useSelector((state) => state.auth) 
    //const [admin, setAdmin] = useState(false)
    useEffect(() => {
        dispatch(userProfile())
    }, [dispatch])
    
    const handleCopyNumber = (id) => {
        navigator.clipboard.writeText(id);
        cogoToast.success(`${id} copied successfully`)
    }

    const handleSwitchView = () => {
      dispatch(toggleView())
      if(is_admin){
        navigate('/payslip')
      } else {
        navigate('/overview')
      }  
    }

  return (
    <>
      <GoBack/>
      <Header text="Profile Information">
             {is_admin && <div className="">
                <button
                    type="submit"
                    disabled="" //hover color is #24752B
                    className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                    onClick={() => (handleSwitchView())}
                >
                  Employee View         
                </button>
              </div>}
              {!is_admin && is_superuser && <div className="">
                <button
                    type="submit"
                    disabled="" //hover color is #24752B
                    className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
                    onClick={() => (handleSwitchView())}
                >
                  Admin View      
                </button>
              </div>}
      </Header>
      <div className="bg-white rounded-[8px] p-4 mt-6">
        <div className="flex items-center gap-[8px] mb-[24px]">
          {/* FARMER"S INITIALS */}
          <div className="bg-green-100 rounded-full w-[48px] h-[48px] flex items-center justify-center">
            <p className="font-medium text-base leading-6 text-center text-green-700 ">
              {first_name ? first_name.charAt(0) : "N/A"}
              {last_name ? last_name.charAt(0) : "N/A"}
            </p>
          </div>

          <div className="">
            <p className="text-secondary font-semibold leading-none text-sm">
              {first_name ? first_name : "N/A"}{" "} 
              {last_name ? last_name : "N/A"}
            </p>
            <div
              className="flex gap-[6px] mt-[4px] cursor-pointer"
              onClick={() =>
                handleCopyNumber(
                  `${
                    profile.employee_id !== null
                      ? profile.employee_id
                      : "N/A"
                  }`
                )
              }
            >
              <p className="text-primary font-semibold leading-tight text-sm cursor-pointer">
                {profile !== null
                  ? profile.employee_id
                  : "N/A"}
              </p>
              <ContentCopy
                className="text-primary cursor-copy"
                style={{ fontSize: "18px" }}
              />
            </div>
          </div>
        </div>

        <div className="flex  flex-wrap gap-17">
        <div className="mb-[24px] w-1/5">
            <p className="font-normal text-sm leading-tight text-tertiary pb-[4px] ">
              First Name
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335] ">
              {first_name ? first_name: 'N/A'} 
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400} text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Last Name
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {last_name ? last_name: 'N/A'} 
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Email Address
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335] break-words">
              {email ? email : "N/A"}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Employee ID
            </p>
            <p
              className="font-semibold text-sm leading-5 text-[#344335]"
              onClick={() =>
                handleCopyNumber(
                  `${
                    profile.employee_id !== null
                      ? profile.employee_id
                      : "N/A"
                  }`
                )
              }
            >
              {profile.employee_id !== null
                ? profile.employee_id
                : "N/A"}{" "}
              <ContentCopy
                className="text-[#4B4E68] cursor-copy"
                style={{ fontSize: "18px" }}
              />
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Department
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile.department ? profile.department: 'N/A'} 
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Role
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile.role ? profile.role: 'N/A'} 
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Account Name
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile.account_name ? profile.account_name: 'N/A' }
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Account Number
            </p>
            <p
              className="font-semibold text-sm leading-5 text-[#344335]"
              onClick={() =>
                handleCopyNumber(
                  `${
                    profile
                      ? profile.account_number
                      : "N/A"
                  }`
                )
              }
            >
               {profile ? profile.account_number: 'N/A'}
              <ContentCopy
                className="text-[#4B4E68] cursor-copy"
                style={{ fontSize: "18px" }}
              />
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Bank
            </p>
            <p className="font-semibold text-sm leading-5 text-[#344335]">
              {profile ? profile.bank: 'N/A'}
            </p>
          </div>
          <div className="mb-[24px]  w-1/5">
            <p className="font-[400] text-sm leading-5 text-[#7C8D7E] pb-[4px]">
              Pension ID
            </p>
            <p
              className="font-semibold text-sm leading-5 text-[#344335]"
              onClick={() =>
                handleCopyNumber(
                  `${
                    profile.pension_id !== null
                      ? profile.pension_id
                      : "N/A"
                  }`
                )
              }
            >
              {profile.pension_id !== ""
                ? profile.pension_id
                : "N/A"}

              {profile.pension_id !== null && 
              <ContentCopy
                className="text-[#4B4E68] cursor-copy"
                style={{ fontSize: "18px" }}
              />}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
