import React, { useEffect } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useSelector,  useDispatch } from "react-redux";
import jwt_decode from 'jwt-decode';
import {refreshToken} from  "../../redux/Auth/authAction"

const AdminRoute = ({ children}) => {

  const {isAuthenticated, is_admin} = useSelector((state) => state.auth)
  const location = useLocation();
  const dispatch = useDispatch();

   // Token refresh logic
   useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      if (token) {
        const { exp } = jwt_decode(token);
        const currentTime = Date.now() / 1000; // Get current time in seconds
        const timeUntilExpiration = exp - currentTime; // Time remaining until token expiration in seconds
        const refreshThreshold = 300; // Refresh the token if it's about to expire within 5 minutes (300 seconds)

        if (timeUntilExpiration < refreshThreshold) {
          // Trigger token refresh
          dispatch(refreshToken(localStorage.getItem('refreshToken')));
        }
      }
    };

    // Set up interval to periodically check token expiration
    const interval = setInterval(checkTokenExpiration, 60000); // Check every minute

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);


  return (
    <>
        {
         !isAuthenticated && is_admin ? (
          <Navigate to ="/"
            replace
            state={{path: location.pathname}}
             /> )
            :
            (children)
        }
    </>
  );
};


export default AdminRoute;
