import * as Yup from 'yup'



export const loginValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email Address is required'),
  password: Yup.string().required('Password is required'),
})

Yup.addMethod(Yup.string, 'password', function () {
    return this.matches(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*./,?<>:;'"{}|`~_()])[a-zA-Z0-9!@#$%^&*./,?<>;:'"{}|`~_()]{8,}$/,
      'Password must contain at least one letter, one number, one special character that is not -=+[], and 8 characters long.'
    );
  });

export const stepOneValidator = Yup.object({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string()
    .email('Enter your Crop2Cash email address')
    .required('Email Address is required')
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Enter the valid email address sent to the admin during onboarding to this new role. '),
    phone_number: Yup.string()
    .min(11, "Phone number cannot be less than 11 digits")
    .max(11, "Exceeded characters for phone number")
    .required("Phone number is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
    password: Yup.string()
    .required('Password is required').password()
    .min(8, 'Password cannot be less than 8 characters'),
    confirm_password: Yup.string()
    .required('Passwords must match')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const stepTwoValidator = Yup.object({
    employee_id: Yup.string()
    .required('Employee Id is required'),
    role: Yup.string().required('Role is required'),
    pension_id: Yup.string(),
    account_number:  Yup.string()
    .min(10, 'Account number cannot be less than 10 digits')
    .max(10, 'Exceeded characters for Account Number')
    .required("Account Number is required").matches(
      /^-?[0-9]+(.[0-9]{1-7})?$/,
      "Enter a valid Account Number"),
})

export const setUpAccountValidator = Yup.object({
  password: Yup.string()
  .required('Password is required').password()
  .min(8, 'Password cannot be less than 8 characters'),
  confirm_password: Yup.string()
  .required('Passwords must match')
  .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  pension_id: Yup.string(),
  account_number:  Yup.string()
  .min(10, 'Account number cannot be less than 10 digits')
  .max(10, 'Exceeded characters for Account Number')
  .required("Account Number is required").matches(
      /^-?[0-9]+(.[0-9]{1-7})?$/,
      "Enter a valid Account Number"),
})

export const complaintValidator = Yup.object({
    complaint: Yup.string().required('Submission of an empty complaint message is not allowed')
  })

export const recordValidator = Yup.object({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string()
  .email('Enter your Crop2Cash email address')
  .required('Email Address is required')
  .matches(/(@crop2cash\.com\.ng$|^oluwakemiakinola00@gmail\.com$)/, 'Email must be from Crop2Cash domain. '),
  employee_id: Yup.string()
  .required('Employee Id is required'),
  role: Yup.string().required('Role is required'),
  department: Yup.string().required('Department is required'),
  employee_type: Yup.string().required('Employee Type is required'),
  pension_id: Yup.string(),
  middle_name: Yup.string(),
})
