import React, { useState } from 'react'

import Header from '../../../components/header';
import EmployeeTable from '../../../Tables/EmployeeTables';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../../components/Modals/modal';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select"
import { paySalaryAdvance } from '../../../redux/Employee/employeeAction';

const EmployeesPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [showAdvanceModal, setShowAdvanceModal] = useState(false)
  const [employeeID, setEmployeeID] = useState(0)

  const { employees, is_paid } = useSelector((state) => state.employee);



  const [amount, setAmount] = useState(0)

  const handleSelectEmployee = (val) => {
    setEmployeeID(val.id)
  }



  const handleAmountInput = (e) => {
    setAmount(e.target.value)
  }




  const handleAddNewRecord = () => {
    navigate('/add-employee-record')
  }

  const handlePaySalaryAdvance = () => {
    setShowAdvanceModal(false)


    dispatch(paySalaryAdvance({
      employee_id: employeeID,
      amount: parseFloat(amount)
    }))
  }

  if (is_paid) {
    setEmployeeID("")
    setAmount(0)
  }

  return (
    <>
      <ModalComponent
        title="Pay Advance"
        show={showAdvanceModal}
        showModal={() => { setShowAdvanceModal(false); }}
        subtitle=""
        size="md"
        className="w-full"
      >
        <div className="w-full">
          <div className="flex items-center justify-center w-full mt-4">
            <div className="flex flex-col gap-2">
              <div className='space-y-2 w-full'>
                <p className="text-secondary text-sm font-normal">
                  Select the employee
                </p>
                <Select
                  onChange={handleSelectEmployee}
                  options={employees}
                  placeholder="Select Employee"
                  className='select_style'
                  classNamePrefix={'select_control'}
                  getOptionLabel={options => `${options?.first_name} ${options?.last_name}`}
                  getOptionValue={options => options?.id}
                />
              </div>
              <div className='flex flex-col space-y-3'>
                <label htmlFor='amount' className="text-green-800 font-normal text-[14px]">
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  value={amount}
                  onChange={handleAmountInput}
                  placeholder="Enter Amount"
                  className="text-[#96A397] p-3 outline-0 w-full h-[44px] bg-white rounded-[8px] border border-[#96A397] mt-[4px] font-medium text-[14px] mb-[24px]"
                />
              </div>
            </div>
          </div>
          <div className="mt-4 flex justify-center align-center items-center "
          >
            <button
              onClick={handlePaySalaryAdvance}
              className="outline-none rounded-lg disabled:text-[#ABB6AC] disabled:bg-[#ABB6AC] bg-primary text-white py-3 px-5  text-sm font-medium border border-primary hover:bg-[#24752B] hover:bg-opacity-[0.9]"
            >
              Pay Salary Advance
            </button>
          </div>
        </div>
      </ModalComponent>
      <Header text='Employee Directory'>
        <div className="flex flex-row gap-4">
          <button
            type="submit"
            onClick={() => setShowAdvanceModal(true)}
            disabled="" //hover color is #24752B
            className="bg-transparent disabled:bg-[#ABB6AC] border-[1px] rounded-lg w-full py-2.5 px-5 text-primary border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
          >
            Pay Salary Advance
          </button>
          <button
            type="submit"
            onClick={handleAddNewRecord}
            disabled="" //hover color is #24752B
            className="bg-primary disabled:bg-[#ABB6AC] rounded-lg w-full py-2.5 px-5 text-white border-primary font-medium hover:bg-[#24752B] hover:text-bold hover:text-white transition-all text-sm"
          >
            <div className="flex gap-2.5 items-center justify-center ">
              <p>Add New Record</p>
            </div>
          </button>
        </div>
      </Header>
      < EmployeeTable />
    </>
  );
}

export default EmployeesPage; 