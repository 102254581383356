import { createSlice } from '@reduxjs/toolkit'
import { recordsTable } from './recordsAction'

const initialState = {
    value: 0,
    total: 0,
    loading: false,
    loader: false,
    records: [],
}

export const recordSlice = createSlice({
    name: 'record',
    initialState,
    reducers: {
      increment: (state) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.value += 1
      },
      decrement: (state) => {
        state.value -= 1
      },
      incrementByAmount: (state, action) => {
        state.value += action.payload
      },
    },
    extraReducers: {
      // get payroll overview table                                                
     [recordsTable.pending]: (state)=>{
        state.loading = true
        state.loader = true
    },
    [recordsTable.fulfilled]: (state,{payload})=>{
        state.loading = false
        state.loader = false
        state.total = payload.count
        state.records = payload.results
    },
    [recordsTable.rejected]: (state) =>{
      state.loading = false
      state.loader = false
    },
    //download records

    }
    
  })
  
  // Action creators are generated for each case reducer function
  export const { increment, decrement, incrementByAmount } = recordSlice.actions
  
  export default recordSlice.reducer
