import React, { useState, useEffect } from "react"
// import SearchComponent from "../../components/search"
// import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { listPayrolls } from "../../redux/Payroll/payrollAction"
import EmptyStateComponent from "../../components/Empty State/emptystate"
import PaginationComponent from "../../components/Pagination/Pagination"

const PayrollHistoryTable = () => {

    const dispatch = useDispatch();
    // const [searchValue, setSearchValue] = useState('')

    const { loading, payrolls, total } = useSelector((state) => state.payroll);

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);

    const onPageSizeChange = (page) => {
        setPageSize(page)
    }


    const onPageChange = (val) => {
        const { page } = val
        setCurrentPage(page)
        dispatch(listPayrolls({
            page: page,
            page_size: pageSize
        })
        )
    }


    useEffect(() => {
        dispatch(listPayrolls({
            page: 1,
            page_size: pageSize
        }
        ));
    }, [dispatch, pageSize]);


    //navigate to individual employees
    const navigate = useNavigate()

    const handleMonthlyPayrollPage = (id) => {
        navigate(`/monthly-payroll/${id}`)
    }





    return (
        <>
            <div className="mt-4 bg-white rounded-[8px]">
                {/* <div className="p-4">
                    {/* search 
                    <div className="">
                        <SearchComponent
                            placeholder="Search..."
                            searchValue={searchValue}
                            handleChange={handleChange}
                        />
                    </div>
                </div> 
                */}


                {/* tables */}
                <div className="overflow-x-auto relative">
                    <table className="w-full text-left">
                        <thead className="text-primary text-sm border-b-[#F7F7F7] border-b-2">
                            <tr>
                                <th scope="col" className="px-4 py-4 w-1/4 font-semibold ">
                                    Year
                                </th>

                                <th scope="col" className="px-4  w-1/4 py-4 font-semibold">
                                    Month
                                </th>
                                <th scope="col" className="px-4 w-1/4  py-4 font-semibold">
                                    Number of Payslips Generated
                                </th>
                                <th scope="col" className="px-4 w-1/4  py-4 font-semibold">
                                    Number of Allowance Generated
                                </th>
                            </tr>
                        </thead>
                        {!loading && (
                            <tbody>
                                {payrolls.length > 0 && (
                                    payrolls.map((item) => (
                                        <tr
                                            key={item.id}
                                            className="bg-white text-sm cursor-pointer hover:bg-[#EDF7EE]"
                                            onClick={() => { handleMonthlyPayrollPage(item.id) }}
                                        >
                                            <th
                                                scope="row"
                                                className="py-4 px-4 font-normal text-[#5C715E] whitespace-nowrap "
                                            >
                                                {" "}
                                                {moment(item.date_of_entry).format("YYYY")}
                                            </th>

                                            <td className="py-4 px-4 text-[#5C715E]">
                                                {moment(item.date_of_entry).format("MMMM")}
                                            </td>
                                            <td className="py-4 px-4 text-[#5C715E]">
                                                {item.payslip_info ? item.payslip_info.payslip_count : 0}
                                            </td>
                                            <td className="py-4 px-4 text-[#5C715E]">
                                                {item.allowance_info && item.allowance_info.allowance_count ? item.allowance_info.allowance_count : 0}
                                            </td>
                                        </tr>
                                    ))
                                )
                                }
                            </tbody>
                        )}
                    </table>

                    {loading && (
                        <div className="animate-pulse w-full mt-4 px-4 mb-4">
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full mb-2"></div>
                            <div className="h-10 bg-gray-200 rounded-lg  w-full"></div>
                        </div>
                    )}
                </div>
            </div>
            {!loading && payrolls.length < 1 &&
                <EmptyStateComponent
                    textContent='Oops! No payroll history available' />
            }
            {!loading && payrolls.length > 0 ? (
                <div className="">
                    <PaginationComponent
                        page={currentPage}
                        onPageChange={(page) => onPageChange({ page })}
                        totalItems={total}
                        pageSize={pageSize}
                        pageSizeShow
                        onPageSizeChange={(page) => onPageSizeChange(page)}
                    />
                </div>
            ) : (
                ''
            )}

        </>
    );
}

export default PayrollHistoryTable